/**
 *
 * UserForm
 *
 */

import React from 'react';
import { Field, Form, Formik } from 'formik';
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  Skeleton,
  Typography,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { CheckboxWithLabel, RadioGroup, TextField } from 'formik-mui';
import { FormBoxContainer } from 'styles/theme/themes';
import { PasswordField } from 'app/components/PasswordField';
import clsx from 'clsx';
import { useParams } from 'react-router';
import { useUsersSlice } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectSaveUser, selectUser } from './slice/selectors';
import Yup from 'utils/yup';
import { MaterialUiFormikSelect } from '../../components/MaterialUiFormikSelect';
import { useSnackbar } from 'notistack';
import { ChevronLeft } from '@mui/icons-material';

const schema = Yup.object().shape({
  first_name: Yup.string().required('Required'),
  last_name: Yup.string().required('Required'),
  email: Yup.string().email('Valid email required').required('Required'),
  role_id: Yup.number()
    .moreThan(0, 'Required value for role')
    .required('Required value for role'),
  passwords_submitted: Yup.bool(),
  new_password: Yup.string().when(
    ['passwords_submitted', 'role_id'],
    // @ts-ignore
    (passwords_submitted, role_id, sch) =>
      passwords_submitted ? sch.checkPassword().required('Required') : sch,
  ),
  password_confirmation: Yup.string().when(
    ['passwords_submitted', 'new_password'],
    // @ts-ignore
    (passwords_submitted, new_password, sch) =>
      passwords_submitted
        ? sch
            .required('Required')
            .oneOf([new_password, null], 'Passwords must match')
        : sch,
  ),
});

export function UserForm() {
  const params = useParams<{
    id: string;
  }>();
  const { actions } = useUsersSlice();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const saveUser = useSelector(selectSaveUser);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  React.useEffect(() => {
    loadUser(params.id === 'new' ? 'new' : parseInt(params.id || ''));
    return () => {
      dispatch(actions.loadUserUnmount());
    };
  }, []);

  React.useEffect(() => {
    if (user.error) {
      navigate('../');
    }
  }, [user]);

  React.useEffect(() => {
    if (saveUser.loading) return;
    if (saveUser.error) {
      enqueueSnackbar(saveUser.error.message, {
        variant: 'error',
      });
    }
    if (saveUser.data) {
      enqueueSnackbar('User saved successfully', {
        variant: 'success',
      });
      if (params.id === 'new') {
        navigate('../');
      }
    }
  }, [saveUser.loading]);

  const loadUser = (id: number | 'new') => {
    dispatch(actions.loadUserRequest({ id }));
  };

  const { loading } = user;
  const data = {
    id: null,
    email: '',
    first_name: '',
    last_name: '',
    role_id: '',
    account_enabled: false,
    passwords_submitted: false,
    new_password: '',
    password_confirmation: '',
    reset_2fa: false,
    ...(user.data || {}),
  };

  if (user.loading && !user.data)
    return (
      <Box sx={{ my: 4, height: 400 }}>
        <Skeleton height={400} variant={'rectangular'} />
      </Box>
    );
  return (
    <FormBoxContainer>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          mt: 4,
          alignItems: 'center',
        }}
      >
        <Typography variant={'h1'} color={'primary'}>
          {user.data ? `Editing user` : 'Add new user'}
        </Typography>
        <Button
          variant="text"
          startIcon={<ChevronLeft />}
          sx={{ marginLeft: 'auto' }}
          component={Link}
          to="/portal/admin/users"
        >
          Back to users
        </Button>
      </Box>

      <Paper sx={{ my: 2, p: 2 }}>
        <Formik
          initialValues={data}
          enableReinitialize
          validationSchema={schema}
          validateOnChange
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            dispatch(actions.saveUserRequest(values));
          }}
        >
          {({ errors, values, submitForm, isSubmitting, setSubmitting }) => {
            if (isSubmitting && !(user.loading || saveUser.loading)) {
              setSubmitting(false);
            }
            if (!isSubmitting && (user.loading || saveUser.loading)) {
              setSubmitting(true);
            }

            return (
              <Form>
                <Box className={clsx('has-actions')}>
                  <Box className={'formContent'}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          id="email"
                          name="email"
                          type="email"
                          label="Email / Username"
                          fullWidth
                          className={'field'}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          id="first_name"
                          name="first_name"
                          type="text"
                          label="First Name"
                          fullWidth
                          className={'field'}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          id="last_name"
                          name="last_name"
                          type="text"
                          label="Last Name"
                          fullWidth
                          className={'field'}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MaterialUiFormikSelect
                          name={'role_id'}
                          label={'Role'}
                          options={[
                            {
                              value: 2,
                              label: 'Administrator',
                            },
                          ]}
                        />
                      </Grid>

                      {!values.id && (
                        <React.Fragment>
                          <Grid item xs={12}>
                            <Field
                              name="passwords_submitted"
                              label="New user password"
                              component={RadioGroup}
                            >
                              <FormControlLabel
                                value=""
                                control={<Radio disabled={isSubmitting} />}
                                label="Email new user with instructions for setting password"
                                disabled={isSubmitting}
                              />
                              <FormControlLabel
                                value="true"
                                control={<Radio disabled={isSubmitting} />}
                                label="Set user password manually"
                                disabled={isSubmitting}
                              />
                            </Field>
                          </Grid>
                        </React.Fragment>
                      )}

                      {!!values.id && (
                        <React.Fragment>
                          <Grid item xs={12} sm={12}>
                            <Field
                              component={CheckboxWithLabel}
                              type="checkbox"
                              name="passwords_submitted"
                              Label={{
                                label: 'Change password for user?',
                              }}
                            />
                          </Grid>
                        </React.Fragment>
                      )}

                      {!!values.passwords_submitted && (
                        <React.Fragment>
                          <Grid item xs={12} sm={6}>
                            <Field
                              component={PasswordField}
                              name="new_password"
                              fullWidth
                              label="Set Password"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Field
                              component={PasswordField}
                              name="password_confirmation"
                              fullWidth
                              label="Confirm Password"
                              variant="outlined"
                            />
                          </Grid>
                        </React.Fragment>
                      )}

                      {user.data?.google2fa_status === 'active' && (
                        <Grid item xs={12} sm={12}>
                          <Typography variant={'body2'}>
                            User has 2FA enabled. Resetting 2FA will require the
                            user to reconfigure 2FA on their next login. Do not
                            check this unless the user is unable to use their
                            two factor application to log in.
                          </Typography>
                          <Field
                            name="reset_2fa"
                            type="checkbox"
                            Label={{ label: 'Reset 2FA' }}
                            component={CheckboxWithLabel}
                          />
                        </Grid>
                      )}

                      <Grid item xs={12} sm={12}>
                        <Field
                          name="account_enabled"
                          type="checkbox"
                          Label={{ label: 'Account enabled' }}
                          component={CheckboxWithLabel}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className={'formActions'} sx={{ mt: 4 }}>
                    <Button
                      variant="text"
                      size="small"
                      component={Link}
                      to="/portal/accounts/users"
                    >
                      Cancel
                    </Button>
                    <Button
                      className="align-right"
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      Save {values.id ? 'Changes' : 'New User'}
                    </Button>
                  </Box>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Paper>
    </FormBoxContainer>
  );
}
