/**
 *
 * Person Sound Files
 *
 */
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { ISoundFile } from './slice/types';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Close, DragHandleOutlined, Edit, Star } from '@mui/icons-material';
import { useConfirm } from '../../components/ConfirmDialog';
import { grey } from '@mui/material/colors';
import { FormBoxContainer } from 'styles/theme/themes';
import { useDrag, useDrop } from 'react-dnd';
import { Identifier, XYCoord } from 'dnd-core';
import { ItemTypes } from '../PreviewPages/slice/types';
import { useTheme } from '@mui/material';

interface SoundFilesProps {
  loading: boolean;
  soundFile: Partial<ISoundFile>;
  onUpdateDetails: (data?: Partial<ISoundFile>) => void;
  onDelete: () => void;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const SoundFile = React.memo((props: SoundFilesProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { loading, soundFile, onUpdateDetails, onDelete, index, moveCard } =
    props;
  const [description, setDescription] = useState<string>(
    soundFile.description || '',
  );
  const [onWebsite, setOnWebsite] = useState<boolean>(
    soundFile.on_website || false,
  );
  const [editing, setEditing] = useState<boolean>(false);
  const confirm = useConfirm();

  useEffect(() => {
    if (
      !!onWebsite !== !!soundFile.on_website ||
      description !== soundFile.description
    ) {
      onUpdateDetails({ description, on_website: onWebsite });
    }
  }, [onWebsite, description]);

  useEffect(() => {
    if (!loading) {
      setEditing(false);
    }
  }, [loading]);

  useEffect(() => {
    setDescription(soundFile.description || '');
    setOnWebsite(soundFile.on_website || false);
  }, [soundFile]);

  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { soundFile, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const theme = useTheme();
  const primary = !isDragging && !index;

  return (
    <FormBoxContainer
      ref={preview}
      sx={{
        p: 4,
        pl: 8,
        borderBottom: 1,
        borderBottomColor: grey['400'],
        opacity,
        position: 'relative',
        backgroundColor: !primary ? grey.A100 : 'white',
        //        color: !primary ? 'black' : 'white',
      }}
    >
      <Box
        ref={ref}
        sx={{
          backgroundColor: grey['100'],
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: 40,
          cursor: isDragging ? 'grabbing' : 'grab',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <DragHandleOutlined sx={{ fontSize: 30 }} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {!!primary && (
            <Tooltip title={'Primary sound file asset'}>
              <Star color={'primary'} fontSize={'large'} sx={{ mr: 2 }} />
            </Tooltip>
          )}
          {editing ? (
            <TextField
              className={'field'}
              value={description}
              onChange={ev => setDescription(ev.target.value)}
              label={'Description'}
              fullWidth
              InputProps={{
                endAdornment: (
                  <IconButton size={'small'} onClick={() => setEditing(false)}>
                    <Close />
                  </IconButton>
                ),
              }}
            />
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h6">{description}</Typography>
              <IconButton size="small" onClick={() => setEditing(true)}>
                <Edit />
              </IconButton>
            </Box>
          )}{' '}
        </Box>

        <IconButton
          color={'error'}
          size={'small'}
          onClick={() => {
            confirm({
              description: (
                <>
                  Please note, this action will be confirmed when you save
                  changes. Once deleted, sound files cannot be restored.
                </>
              ),
              title: 'Remove sound file from list?',
            })
              .then(() => onDelete())
              .catch(() => null);
          }}
        >
          <Close />
        </IconButton>
      </Box>

      <Box sx={{ my: 2 }}>
        <audio src={soundFile.file_url} controls preload="none" />
      </Box>

      <FormControlLabel
        className={'field'}
        control={
          <Checkbox
            checked={onWebsite}
            onChange={ev => setOnWebsite(v => ev.target.checked)}
          />
        }
        label="On website"
      />
    </FormBoxContainer>
  );
});
