import { call, cancelled, put, takeLatest, take } from 'redux-saga/effects';
import { apiRequest } from 'utils/request';
import { previewPagesSliceActions as actions } from '.';
import { createUploadFileChannel } from 'utils/saga/createFileUploadChannel';

function* loadPreviewPages(action) {
  try {
    const payload = yield call(apiRequest, {
      url: `preview-pages`,
      method: 'get',
      data: {
        ...action.payload,
      },
    });

    yield put(actions.loadPreviewPagesSuccess({ ...payload }));
  } catch (error) {
    yield put(actions.loadPreviewPagesFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* loadPreviewPage(action) {
  try {
    let payload;

    if (action.payload.id !== 'new') {
      payload = yield call(apiRequest, {
        url: `preview-pages/${action.payload.id}`,
        method: 'get',
        data: {},
      });
    }

    yield put(actions.loadPreviewPageSuccess({ ...payload }));
  } catch (error) {
    yield put(actions.loadPreviewPageFailed(error.payload));
  } finally {
    if (yield cancelled()) {
      console.log('cancelled');
    }
  }
}

function* savePreviewPage(action) {
  try {
    const channel = yield call(
      createUploadFileChannel,
      action.payload.id
        ? `preview-pages/${action.payload.id}`
        : 'preview-pages',
      {
        ...action.payload,
      },
      action.payload.id ? 'put' : 'post',
    );
    while (true) {
      const { progress = 0, err, success, response } = yield take(channel);
      if (err) {
        yield put(actions.savePreviewPageFailed(err));
        return;
      }
      if (success) {
        const res = JSON.parse(response || {});
        yield put(actions.savePreviewPageSuccess(res));
        return;
      }
      yield put(actions.savePreviewPageProgress({ progress }));
    }
  } catch (error) {
    yield put(actions.savePreviewPageFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* updatePeople(action) {
  try {
    const payload = yield call(apiRequest, {
      url: `preview-pages/${action.payload.id}/update-people`,
      method: 'put',
      data: {
        ...action.payload,
      },
    });

    yield put(actions.updatePeopleSuccess(payload));
  } catch (error) {
    yield put(actions.updatePeopleFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* deletePreviewPage(action) {
  try {
    const payload = yield call(apiRequest, {
      url: `preview-pages/actions`,
      method: 'post',
      data: {
        actions: action.payload.map(id => ({
          type: 'delete',
          id,
        })),
      },
    });

    yield put(actions.deletePreviewPageSuccess(payload));
  } catch (error) {
    yield put(actions.deletePreviewPageFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* changeField(action) {
  try {
    const payload = yield call(apiRequest, {
      url: `preview-pages/actions`,
      method: 'post',
      data: {
        actions: action.payload.map(({ id, field_name, field_value }) => ({
          type: 'set-field-value',
          id,
          field_name,
          field_value,
        })),
      },
    });

    yield put(actions.changeFieldSuccess(payload));
  } catch (error) {
    yield put(actions.changeFieldFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

export function* previewPagesSliceSaga() {
  yield takeLatest(actions.loadPreviewPagesRequest.type, loadPreviewPages);
  yield takeLatest(actions.loadPreviewPageRequest.type, loadPreviewPage);
  yield takeLatest(actions.savePreviewPageRequest.type, savePreviewPage);
  yield takeLatest(actions.deletePreviewPageRequest.type, deletePreviewPage);
  yield takeLatest(actions.changeFieldRequest.type, changeField);
  yield takeLatest(actions.updatePeopleRequest.type, updatePeople);
}
