import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { dashboardSaga } from './saga';
import { DashboardState } from './types';
import { apiCallInitialState } from '../../../../types/ApiCall';
import { ResponseErrorPayload } from '../../../../utils/request';

export const initialState: DashboardState = {
  submittedUpdates: { ...apiCallInitialState, data: [] },
};

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    loadRequest(state, action: PayloadAction<any>) {
      state.submittedUpdates.loading = true;
      state.submittedUpdates.error = undefined;
    },
    loadSuccess(state, action: PayloadAction<any>) {
      state.submittedUpdates.loading = false;
      state.submittedUpdates.data = action.payload;
      state.submittedUpdates.error = undefined;
    },
    loadFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.submittedUpdates.loading = false;
      state.submittedUpdates.error = action.payload;
    },
  },
});

export const { actions: dashboardActions } = slice;

export const useDashboardSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: dashboardSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useDashboardSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
