/**
 *
 * PreviewPage Sound Files
 *
 */
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { IPreviewPagePerson, ItemTypes } from './slice/types';
import { isEqual, uniq } from 'lodash';
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { Close, DragHandleOutlined, Handyman, Star } from '@mui/icons-material';
import { useConfirm } from '../../components/ConfirmDialog';
import { grey } from '@mui/material/colors';
import { useDrop, useDrag } from 'react-dnd';
import type { Identifier, XYCoord } from 'dnd-core';

interface SoundFilesProps {
  loading: boolean;
  person: IPreviewPagePerson;
  onUpdateDetails: (data?: {
    settings: {
      sound_files: Array<number>;
    };
  }) => void;
  onDelete: () => void;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export function Person(props: SoundFilesProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { person, onUpdateDetails, onDelete, index, moveCard, loading } = props;
  const [description, setDescription] = useState<string>(
    person.description as string,
  );
  const [includedSoundFiles, setIncludedSoundFiles] = useState<Array<number>>(
    person.pivot.settings.sound_files,
  );

  useEffect(() => {
    if (!isEqual(person.pivot.settings.sound_files, includedSoundFiles))
      onUpdateDetails({ settings: { sound_files: includedSoundFiles } });
  }, [includedSoundFiles]);

  useEffect(() => {
    setIncludedSoundFiles(person.pivot.settings.sound_files);
  }, [person.pivot.settings.sound_files]);

  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { person, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.2 : 1;
  drag(drop(ref));

  return (
    <Box
      ref={preview}
      sx={{
        p: 4,
        pl: 8,
        borderBottom: 1,
        borderBottomColor: grey['400'],
        opacity,
        position: 'relative',
      }}
    >
      <Box
        ref={ref}
        sx={{
          backgroundColor: grey['100'],
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: 40,
          cursor: isDragging ? 'grabbing' : 'grab',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <DragHandleOutlined sx={{ fontSize: 30 }} />
      </Box>

      <Stack spacing={4}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        >
          <Stack direction={'row'} spacing={2}>
            {!!person.tvg_rates && (
              <Tooltip title={'TVG Rates'}>
                <Star fontSize={'large'} color={'warning'} />
              </Tooltip>
            )}
            <Stack spacing={1}>
              <Typography variant={'h4'}>
                {person.first_name} {person.last_name}
              </Typography>
              <Typography variant={'h5'}>
                <Stack direction={'row'} spacing={1}>
                  {!!person.is_child && (
                    <>
                      <Box>TVG Kids / Is Child</Box>
                      <Box>|</Box>
                    </>
                  )}
                  <Box>appears as {person.name_abbreviated}</Box>
                </Stack>
              </Typography>
            </Stack>
          </Stack>
          <IconButton
            color={'error'}
            size={'small'}
            onClick={() => onDelete()}
            sx={{ marginLeft: 'auto' }}
          >
            <Close />
          </IconButton>
        </Box>
        {(person.sound_files || []).map(sound_file => (
          <Stack spacing={1} key={`sound-file-${sound_file.id}`}>
            <Typography variant={'h6'}>{sound_file.description}</Typography>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={includedSoundFiles.indexOf(sound_file.id) > -1}
                    onChange={ev =>
                      setIncludedSoundFiles(
                        uniq(
                          ev.target.checked
                            ? [...includedSoundFiles, sound_file.id]
                            : [
                                ...includedSoundFiles.filter(
                                  id => id !== sound_file.id,
                                ),
                              ],
                        ),
                      )
                    }
                  />
                }
                label="Included on preview page"
              />
              <audio src={sound_file.file_url} controls preload="none" />
            </Box>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
}
