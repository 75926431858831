import { Box, Button, Container, Grid, Paper, Typography } from '@mui/material';
import { get, isEqual } from 'lodash';
import { ManagementTable } from '../../components/ManagementTable';
import { Link, useLocation } from 'react-router-dom';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { useTaxonomiesSlice } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectChangeField,
  selectDeletePages,
  selectTaxonomies,
} from './slice/selectors';
import { useDebounce } from 'utils/useDebounce';
import { useQuery } from 'utils/query';
import { useParams } from 'react-router';
import { GridSortDirection } from '@mui/x-data-grid-pro';
import { useConfirm } from '../../components/ConfirmDialog';

export function List() {
  const match = useParams();
  const confirm = useConfirm();
  const { actions } = useTaxonomiesSlice();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const taxonomies = useSelector(selectTaxonomies);
  const deletePages = useSelector(selectDeletePages);
  const changeField = useSelector(selectChangeField);
  const [selectedRows, setSelectedRows] = useState<Array<number | string>>([]);

  const { query, setQuery } = useQuery();
  const [pageSize, setPageSize] = React.useState<number>(
    +(query.pageSize || 25),
  );
  const [page, setPage] = React.useState<number>(+(query.page || 1));
  const [sort, setSort] = React.useState<
    [field: string, direction?: GridSortDirection] | undefined
  >(query.sort || undefined);

  const getFilters = () => query.filters;
  const [filters, setFilters] = React.useState(getFilters());

  React.useEffect(() => {
    if (!isEqual(getFilters(), filters)) setFilters(getFilters());
  }, [search]);

  const debouncedFilters = useDebounce(filters, 400);

  React.useEffect(() => {
    loadTaxonomies();
  }, [deletePages.loading]);

  React.useEffect(() => {
    loadTaxonomies();
  }, [changeField.loading]);

  const doAction = useCallback(
    action => {
      switch (action) {
        case 'delete':
          confirm({
            title: `Delete (${selectedRows.length} selected)`,
          })
            .then(() =>
              dispatch(actions.deleteTaxonomyRequest(selectedRows as number[])),
            )
            .catch(() => {});
          break;
        case 'set_active':
          confirm({
            title: `Set ${selectedRows.length} selected rows to active`,
          })
            .then(() =>
              dispatch(
                actions.changeFieldRequest(
                  selectedRows.map(id => ({
                    field_name: 'active',
                    field_value: true,
                    id: id as number,
                  })),
                ),
              ),
            )
            .catch(() => {});
          break;
        case 'set_inactive':
          confirm({
            title: `Set ${selectedRows.length} selected rows to inactive`,
          })
            .then(() =>
              dispatch(
                actions.changeFieldRequest(
                  selectedRows.map(id => ({
                    field_name: 'active',
                    field_value: false,
                    id: id as number,
                  })),
                ),
              ),
            )
            .catch(() => {});
          break;
      }
    },
    [selectedRows],
  );
  React.useEffect(() => {
    setQuery('pageSize', pageSize);
  }, [pageSize]);

  React.useEffect(() => {
    setQuery('page', page);
  }, [page]);

  React.useEffect(() => {
    setQuery('filters', filters);
  }, [filters]);

  React.useEffect(() => {
    setQuery('sort', sort);
  }, [sort]);

  React.useEffect(() => {
    loadTaxonomies();
  }, [dispatch, debouncedFilters, page, pageSize]);

  const loadTaxonomies = () => {
    dispatch(
      actions.loadTaxonomiesRequest({
        ...filters,
        'page[number]': Math.max(page - 1, 0),
        'page[size]': pageSize,
        sort: sort,
      }),
    );
  };
  return (
    <Box sx={{ my: 4 }}>
      <Container maxWidth="xl">
        <Grid
          container
          spacing={2}
          alignItems={'center'}
          justifyContent={'flex-start'}
        >
          <Grid item>
            <Typography variant="h1" color={'primary'}>
              All taxonomies
            </Typography>
          </Grid>
          <Grid item sx={{ marginLeft: 'auto' }} />
        </Grid>
      </Container>

      <Container maxWidth="xl">
        <Paper sx={{ my: 2 }}>
          <ManagementTable
            rows={taxonomies.data as any}
            columns={[
              {
                value: 'name',
                label: 'Name',
              },
              {
                value: v => `${new Date(v.updated_at).toLocaleString()}`,
                label: 'Last updated',
              },
              {
                classes: 'align-right',
                value: v => {
                  return (
                    <Button
                      sx={{ my: 1 }}
                      component={Link}
                      to={`./${v.id}`}
                      variant={'contained'}
                      color={'primary'}
                    >
                      Edit
                    </Button>
                  );
                },
              },
            ]}
            onClick={() => {}}
            page={page}
            onChangePage={page => {
              setPage(page);
            }}
            onChangeRowsPerPage={size => setPageSize(size)}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[10, 25, 50]}
            count={get(taxonomies, 'meta.total', 0)}
            loading={taxonomies.loading}
            loadingComponent={
              <LoadingIndicator minHeight={300} message={'Loading data'} />
            }
          />
        </Paper>
      </Container>
    </Box>
  );
}
