/**
 *
 * PreviewPageForm
 *
 */

import React from 'react';
import { Box, Button, Container, Paper, Tab, Tabs } from '@mui/material';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { usePreviewPagesSlice } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectPreviewPage,
  selectSavePreviewPage,
  selectUpdatePeople,
} from './slice/selectors';
import { useSnackbar } from 'notistack';
import { TabbedContent } from '../../components/TabbedContent';
import { PreviewPageForm } from './Form';
import { People } from './People';
import { ChevronLeft } from '@mui/icons-material';
import { PersonForm } from '../People/Form';
import { SoundFiles } from '../People/SoundFiles';
import { addToPreviewPageActions } from '../AddToPreviewPage/slice';
import { grey } from '@mui/material/colors';
import { Helmet } from 'react-helmet-async';

export function Edit() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { actions } = usePreviewPagesSlice();
  const loadPreviewPage = useSelector(selectPreviewPage);
  const savePreviewPage = useSelector(selectSavePreviewPage);
  const updatePeople = useSelector(selectUpdatePeople);
  const [value, setValue] = React.useState(0);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const params = useParams<{
    id: string;
    section?: string;
  }>();

  const basePath = '/portal/preview-pages';

  React.useEffect(() => {
    load();
    return () => {
      dispatch(actions.loadPreviewPageUnmount());
    };
  }, []);

  React.useEffect(() => {
    if (loadPreviewPage.error) {
      navigate('/portal/preview-pages');
    }
  }, [loadPreviewPage]);

  React.useEffect(() => {
    if (savePreviewPage.loading) return;
    if (savePreviewPage.error) {
      enqueueSnackbar(savePreviewPage.error.message, {
        variant: 'error',
      });
    }
    if (savePreviewPage.data) {
      enqueueSnackbar('Preview page saved successfully', {
        variant: 'success',
      });
      if (params.id === 'new') {
        navigate(`/portal/preview-pages/${savePreviewPage.data.id}`, {
          replace: true,
        });
      } else {
        load();
      }
    }
  }, [savePreviewPage.loading]);

  React.useEffect(() => {
    if (params.id !== 'new') load();
  }, [params.id]);

  React.useEffect(() => {
    if (!updatePeople.loading) load();
  }, [updatePeople.loading]);

  const load = () => {
    const id = params.id === 'new' ? 'new' : parseInt(params.id || '');
    dispatch(actions.loadPreviewPageRequest({ id }));
  };

  return (
    <>
      <Helmet>
        <title>
          {loadPreviewPage.data ? loadPreviewPage.data.reference : 'Create new'}
          - Preview Page
        </title>
      </Helmet>
      <Box>
        <Paper
          square
          sx={{
            backgroundColor: 'secondary.dark',
            color: 'white',
            py: 1,
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ flex: '0 0 20%', pl: 1 }}>
            <Button
              color={'inherit'}
              size={'small'}
              variant="text"
              startIcon={<ChevronLeft />}
              sx={{ marginLeft: 'auto' }}
              component={Link}
              to={basePath}
            >
              Back to preview pages
            </Button>
          </Box>

          <Box sx={{ flex: '0 0 20%', textAlign: 'right', pr: 1 }} />
        </Paper>
        <Container sx={{ my: 4 }} maxWidth={'xl'}>
          <Paper>
            <Box sx={{ px: 1, backgroundColor: grey['200'] }}>
              <Tabs value={params.section || ''}>
                <Tab
                  value={''}
                  component={Link}
                  to={`${basePath}/${params.id}`}
                  label={'General'}
                />
                <Tab
                  value={'people'}
                  disabled={params.id === 'new'}
                  component={Link}
                  to={`${basePath}/${params.id}/people`}
                  label={'People'}
                />
                <Box sx={{ ml: 'auto', display: 'flex', alignItems: 'center' }}>
                  {!!loadPreviewPage.data && (
                    <Button
                      variant={'contained'}
                      size={'small'}
                      color={'warning'}
                      onClick={() =>
                        window.open(loadPreviewPage.data!.public_url, '_blank')
                      }
                    >
                      View public page
                    </Button>
                  )}
                </Box>
              </Tabs>
            </Box>

            {!params.section && (
              <PreviewPageForm
                loadPreviewPage={loadPreviewPage}
                savePreviewPage={savePreviewPage}
                onSave={data => dispatch(actions.savePreviewPageRequest(data))}
              />
            )}

            {params.section === 'people' && (
              <People
                updatePeople={updatePeople}
                loadPreviewPage={loadPreviewPage}
                onUpdate={data =>
                  dispatch(
                    actions.updatePeopleRequest({
                      ...data,
                      id: loadPreviewPage.data?.id,
                    }),
                  )
                }
              />
            )}
          </Paper>
        </Container>
      </Box>
    </>
  );
}
