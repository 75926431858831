/**
 *
 * Taxonomy Items
 *
 */
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ApiCall } from '../../../types/ApiCall';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { Box, Button, Paper } from '@mui/material';
import { isEqual } from 'lodash';
import move from 'lodash-move/index';
import { useDeepCompareMemoize } from '../../../utils/useDeepCompareMemoize';
import { FormBoxContainer } from 'styles/theme/themes';
import { Add } from '@mui/icons-material';
import { IVoiceService, VoiceServicesApiCall } from './slice/types';
import { Item } from './Item';

interface ItemsProps {
  loadVoiceServices: VoiceServicesApiCall;
  updateItems: ApiCall;
  onUpdate: (data: any) => void;
}

export function Items(props: ItemsProps) {
  const { loadVoiceServices, updateItems, onUpdate } = props;

  const initListState = useMemo(() => {
    return loadVoiceServices.data;
  }, [loadVoiceServices]);

  const [listState, setListState] = useState<Array<IVoiceService>>(
    initListState || [],
  );

  const actions = useMemo(() => {
    const emptyActions: {
      update: Array<any>;
      delete: Array<any>;
      create: Array<any>;
    } = {
      update: [],
      delete: [],
      create: listState
        .filter(item => !item.id)
        .map(item => ({
          name: item.name,
          order: item.order,
          active: item.active,
        })),
    };

    return initListState?.reduce((acc, item) => {
      const newItem = listState.find(t => t.id === item.id);
      if (!newItem) {
        acc.delete.push({
          id: item.id,
        });
      } else if (!isEqual(item, newItem)) {
        acc.update.push({
          ...newItem,
          id: item.id,
        });
      }
      return acc;
    }, emptyActions);
  }, [listState]);

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const newList = move(listState, dragIndex, hoverIndex).map(
        (item, index) => ({
          ...item,
          order: (index + 1) * 10,
        }),
      );

      setListState(newList);
    },
    [listState],
  );

  useEffect(() => {
    setListState(initListState || []);
  }, useDeepCompareMemoize([initListState]));

  useEffect(() => {
    setListState(
      [...listState]
        .sort((t1, t2) => {
          return t1.order! - t2.order!;
        })
        .map((t, i) => ({ ...t, order: (i + 1) * 10 })),
    );
  }, useDeepCompareMemoize([listState]));

  if (!loadVoiceServices.data) {
    return <LoadingIndicator />;
  }

  return (
    <Paper
      sx={{
        my: 4,
      }}
    >
      <FormBoxContainer
        className="items"
        sx={{
          pointerEvents: updateItems.loading ? 'none' : 'auto',
          opacity: updateItems.loading ? 0.5 : 1,
        }}
      >
        {listState.map((item, index) => (
          <Item
            key={`${item.id || item.tempKey}-item-entry`}
            index={index}
            moveCard={moveCard}
            loading={updateItems.loading}
            item={item}
            onUpdateDetails={(index, data) => {
              setListState(
                listState.map((p, i) => ({
                  ...p,
                  ...(i === index ? { ...data } : {}),
                })),
              );
            }}
            onDelete={index =>
              setListState([...listState.filter((t, i) => i !== index)])
            }
          />
        ))}

        <Box sx={{ textAlign: 'right' }}>
          <Button
            size={'large'}
            startIcon={<Add color={'secondary'} />}
            sx={{ mb: 4, mt: 2, mx: 2 }}
            onClick={() =>
              setListState([
                ...listState,
                {
                  id: null,
                  name: 'New Service',
                  active: true,
                  has_rate: true,
                  order: listState.length * 10,
                  tempKey: Math.random().toString(36).slice(2),
                },
              ])
            }
          >
            Add new
          </Button>
        </Box>

        {!!loadVoiceServices.data.length && (
          <Box className={'formActions'}>
            <Button
              className={'alignRight'}
              sx={{ m: 2 }}
              color={'secondary'}
              variant={'contained'}
              disabled={
                loadVoiceServices.loading ||
                updateItems.loading ||
                isEqual(listState, initListState)
              }
              onClick={() => {
                setListState(initListState || []);
              }}
            >
              Cancel changes
            </Button>
            <Button
              className={'align-right'}
              sx={{ m: 2 }}
              color={'primary'}
              variant={'contained'}
              disabled={
                loadVoiceServices.loading ||
                updateItems.loading ||
                isEqual(listState, initListState)
              }
              onClick={() => {
                onUpdate({
                  actions,
                });
              }}
            >
              Save Changes
            </Button>
          </Box>
        )}
      </FormBoxContainer>
    </Paper>
  );
}
