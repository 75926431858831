/**
 *
 * TaxonomyForm
 *
 */

import React from 'react';
import { Field, Form, Formik } from 'formik';
import { Box, Button, Grid, Paper, Skeleton } from '@mui/material';
import { Link } from 'react-router-dom';
import { CheckboxWithLabel, TextField } from 'formik-mui';
import clsx from 'clsx';
import Yup from 'utils/yup';
import { ITaxonomy, SaveTaxonomyApiCall, TaxonomyApiCall } from './slice/types';

import { FormBoxContainer } from 'styles/theme/themes';

const schema = Yup.object().shape({
  name: Yup.string().required('Required'),
  allow_multiple: Yup.boolean(),
  active: Yup.boolean(),
});

interface TaxonomyFormProps {
  loadTaxonomy: TaxonomyApiCall;
  saveTaxonomy: SaveTaxonomyApiCall;
  onSave: (data: ITaxonomy) => void;
}

export function TaxonomyForm(props: TaxonomyFormProps) {
  const { loadTaxonomy, saveTaxonomy, onSave } = props;

  const data: Partial<ITaxonomy> = {
    id: null,
    name: '',
    allow_multiple: false,
    required: false,
    active: true,
    ...(loadTaxonomy.data || {}),
  };

  if (loadTaxonomy.loading && !loadTaxonomy.data)
    return (
      <Box sx={{ my: 4, height: 400 }}>
        <Skeleton height={400} variant={'rectangular'} />
      </Box>
    );
  return (
    <FormBoxContainer>
      <Paper sx={{ my: 2, p: 2 }}>
        <Formik
          initialValues={data}
          enableReinitialize
          validationSchema={schema}
          validateOnChange
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            onSave(values as ITaxonomy);
          }}
        >
          {({
            errors,
            values,
            setFieldValue,
            submitForm,
            isSubmitting,
            setSubmitting,
          }) => {
            if (
              isSubmitting &&
              !(loadTaxonomy.loading || saveTaxonomy.loading)
            ) {
              setSubmitting(false);
            }
            if (
              !isSubmitting &&
              (loadTaxonomy.loading || saveTaxonomy.loading)
            ) {
              setSubmitting(true);
            }

            return (
              <Form>
                <Box className={clsx('has-actions')}>
                  <Box className={'formContent'}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          id="name"
                          name="name"
                          type="text"
                          label="Vocabulary Name"
                          fullWidth
                          className={'field'}
                        />
                      </Grid>{' '}
                      <Grid item xs={12} sm={12}>
                        <Field
                          component={CheckboxWithLabel}
                          name="allow_multiple"
                          type="checkbox"
                          Label={{ label: 'Allow multiple values?' }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Field
                          component={CheckboxWithLabel}
                          name="required"
                          type="checkbox"
                          Label={{ label: 'Is a required field' }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Field
                          name="active"
                          type="checkbox"
                          Label={{ label: 'Active' }}
                          component={CheckboxWithLabel}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className={'formActions'} sx={{ mt: 4 }}>
                    <Button
                      variant="text"
                      size="small"
                      component={Link}
                      to="/portal/admin/taxonomies"
                    >
                      Cancel
                    </Button>
                    <Button
                      className="align-right"
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      Save {values.id ? 'Changes' : 'New Taxonomy'}
                    </Button>
                  </Box>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Paper>
    </FormBoxContainer>
  );
}
