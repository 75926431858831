/**
 *
 * AdminSettings
 *
 */
import * as React from 'react';
import { useParams } from 'react-router';
import { Box, Container, Paper, Tab, Tabs, Typography } from '@mui/material';
import { Link, Navigate } from 'react-router-dom';
import { Users } from '../Users';
import { Taxonomies } from '../Taxonomies';
import { VoiceServices } from '../VoiceServices';
import { LegalDocuments } from '../LegalDocuments';

interface Props {}

// Custom hook for resolving paths

export function AdminSettings(props: Props) {
  const params = useParams<{
    section?: string;
  }>();

  const basePath = '/portal/admin';

  return (
    <Box>
      {' '}
      <Paper
        square
        sx={{
          backgroundColor: 'secondary.dark',
          color: 'white',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box sx={{ flex: '0 0 20%', pl: 1 }}>
          <Typography variant={'subtitle2'}>Admin Settings</Typography>
        </Box>
        <Tabs
          value={params.section || 'users'}
          variant={'scrollable'}
          textColor={'inherit'}
        >
          <Tab
            value={'users'}
            component={Link}
            to={`${basePath}/users`}
            label={'Users'}
          />
          <Tab
            value={'taxonomies'}
            component={Link}
            to={`${basePath}/taxonomies`}
            label={'Taxonomies'}
          />
          <Tab
            value={'voice-services'}
            component={Link}
            to={`${basePath}/voice-services`}
            label={'Voice Services'}
          />
          <Tab
            value={'legal-documents'}
            component={Link}
            to={`${basePath}/legal-documents`}
            label={'Legal documents'}
          />
        </Tabs>
        <Box sx={{ flex: '0 0 20%', textAlign: 'right', pr: 1 }} />
      </Paper>
      <Container maxWidth={'xl'}>
        {params.section === 'users' && <Users />}
        {params.section === 'taxonomies' && <Taxonomies />}
        {params.section === 'voice-services' && <VoiceServices />}
        {params.section === 'legal-documents' && <LegalDocuments />}
        {!params.section && <Navigate to={`users`} />}
      </Container>
    </Box>
  );
}
