import { call, cancelled, put, takeLatest } from 'redux-saga/effects';
import { addToPreviewPageActions as actions } from '.';
import { apiRequest } from '../../../../utils/request';

function* load(action) {
  try {
    const payload = yield call(apiRequest, {
      url: `preview-pages`,
      method: 'get',
      data: {},
    });

    const people = yield call(apiRequest, {
      url: `people`,
      method: 'get',
      data: { ...action.payload, 'page[size]': 1000, 'page[number]': 1 },
    });

    yield put(actions.loadSuccess({ data: payload.data, people: people.data }));
  } catch (error) {
    yield put(actions.loadFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* add(action) {
  try {
    const payload = yield call(apiRequest, {
      url: `people/actions`,
      method: 'post',
      data: {
        actions: action.payload.ids.map(personId => ({
          id: personId,
          type: 'add-to-preview-page',
          preview_page_id: action.payload.pageId,
          preview_page_reference: action.payload.pageName,
        })),
      },
    });

    yield put(actions.addSuccess(payload.data));
  } catch (error) {
    yield put(actions.addFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

export function* addToPreviewPageSaga() {
  yield takeLatest(actions.loadRequest.type, load);
  yield takeLatest(actions.addRequest.type, add);
}
