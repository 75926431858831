/**
 *
 * Taxonomy Sound Files
 *
 */
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { IVoiceService } from './slice/types';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Close, DragHandleOutlined, Edit } from '@mui/icons-material';
import { useConfirm } from '../../components/ConfirmDialog';
import { grey } from '@mui/material/colors';
import { useDrag, useDrop } from 'react-dnd';
import { Identifier, XYCoord } from 'dnd-core';
import { ItemTypes } from '../PreviewPages/slice/types';

interface VoiceServiceItemProps {
  loading: boolean;
  item: IVoiceService;
  onUpdateDetails: (index: number, data?: Partial<IVoiceService>) => void;
  onDelete: (index: number) => void;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export function Item(props: VoiceServiceItemProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { item, onUpdateDetails, onDelete, moveCard, index, loading } = props;

  const [name, setName] = useState<string>(item.name || '');
  const [active, setActive] = useState<boolean>(!!item.active);
  const [hasRate, setHasRate] = useState<boolean>(!!item.has_rate);
  const [editing, setEditing] = useState<boolean>(false);

  const confirm = useConfirm();

  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  useEffect(() => {
    const obj = {
      name,
      active,
      has_rate: hasRate,
    };
    if (name !== item.name) {
      onUpdateDetails(index, obj);
    }

    if (active !== item.active) {
      onUpdateDetails(index, obj);
    }

    if (hasRate !== item.has_rate) {
      onUpdateDetails(index, obj);
    }
  }, [active, name, hasRate]);

  useEffect(() => {
    if (!loading) {
      setEditing(false);
    }
  }, [loading]);

  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { item, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.2 : 1;
  drag(drop(ref));

  return (
    <Box
      ref={preview}
      sx={{
        pr: 4,
        py: 2,
        pl: 8,
        borderBottom: 1,
        borderBottomColor: grey['400'],
        opacity,
        position: 'relative',
      }}
    >
      <Box
        ref={ref}
        sx={{
          backgroundColor: grey['100'],
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: 40,
          cursor: isDragging ? 'grabbing' : 'grab',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <DragHandleOutlined sx={{ fontSize: 30 }} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        {editing ? (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <FormControlLabel
                  label={'Active'}
                  control={<Checkbox checked={active} />}
                  onChange={(event, checked) => setActive(checked)}
                />{' '}
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControlLabel
                  label={'Has Rate'}
                  control={<Checkbox checked={hasRate} />}
                  onChange={(event, checked) => setHasRate(checked)}
                />{' '}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={name}
                  onChange={ev => setName(ev.target.value)}
                  label={'Description'}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        size={'small'}
                        onClick={() => setEditing(false)}
                      >
                        <Close />
                      </IconButton>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              variant="h6"
              sx={{
                opacity: active ? 1 : 0.6,
              }}
            >
              {name}
              {!active && `(Inactive)`}
            </Typography>
            <IconButton size="small" onClick={() => setEditing(true)}>
              <Edit />
            </IconButton>
          </Box>
        )}{' '}
        <Tooltip title={'Remove item'}>
          <IconButton
            color={'error'}
            size={'small'}
            onClick={() => onDelete(index)}
          >
            <Close />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
}
