/**
 *
 * DateRangePicker
 *
 */
import * as React from 'react';
import { ResponsiveDatePicker } from '../ResponsiveDatePicker';
import { Grid } from '@mui/material';

interface Props {
  values: [from: Date | null, to: Date | null];
  onChange: Function;
}

export function DateRangePicker(props: Props) {
  const [values, setValues] = React.useState<
    [from: Date | null, to: Date | null]
  >(props.values);

  const changeFromDate = value => {
    setValues([value, values[1]]);
  };

  const changeToDate = value => {
    setValues([values[0], value]);
  };

  React.useEffect(() => {
    props.onChange(values);
  }, [values]);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <ResponsiveDatePicker
            label={'From'}
            defaultValue={props.values[0] || undefined}
            onChange={changeFromDate}
            dateProps={{ maxDate: values[1] || undefined }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ResponsiveDatePicker
            label={'To'}
            defaultValue={props.values[1] || undefined}
            onChange={changeToDate}
            dateProps={{ minDate: values[0] || undefined }}
          />
        </Grid>
      </Grid>
    </div>
  );
}
