/**
 *
 * AuthProvider
 *
 */

import React, { ReactChild, ReactNode, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthProviderSlice } from './slice';
import { selectAuthProvider } from './slice/selectors';
import { Box } from '@mui/material';
import { getToken } from 'utils/request';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { useHasFocus } from 'utils/useHasFocus';
import { useEffectOnChange } from 'utils/useEffectOnChange';

interface Props {
  children: ReactNode;
}

export function AuthProvider(props: Props) {
  const { actions } = useAuthProviderSlice();
  const { initialised, loggedIn, loadUser } = useSelector(selectAuthProvider);
  const dispatch = useDispatch();

  const hasFocus = useHasFocus();

  const checkUser = useCallback(() => {
    if (!loggedIn) return;
    if (document.hidden || !document.hasFocus()) return;
    dispatch(actions.loadUserDataRequest());
  }, [loggedIn, dispatch]);

  // Try user load once on load
  useEffect(() => {
    setTimeout(() => {
      if (getToken()) {
        dispatch(actions.loadUserDataRequest());
      } else {
        dispatch(actions.setInitialised(true));
      }
    }, 1000);
  }, [dispatch, actions]);

  // if user returns to page, reload user data
  useEffectOnChange(
    () => {
      if (!loadUser.loading) dispatch(actions.loadUserDataRequest());
    },
    hasFocus,
    true,
  );

  useEffect(() => {
    const i = setInterval(() => {
      checkUser();
    }, 60000);
    return () => {
      clearInterval(i);
    };
  }, [checkUser]);

  if (!initialised) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        <LoadingIndicator color="primary" size={60} />
      </Box>
    );
  }
  return <Box>{props.children}</Box>;
}
