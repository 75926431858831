import { call, cancelled, put, takeLatest } from 'redux-saga/effects';
import { emailPeopleActions as actions } from '.';
import { apiRequest } from '../../../../utils/request';

function* load(action) {
  try {
    const payload = yield call(apiRequest, {
      url: `people/get-email-link-data`,
      method: 'get',
      data: {},
    });

    const people = yield call(apiRequest, {
      url: `people`,
      method: 'get',
      data: { ...action.payload, 'page[size]': 1000, 'page[number]': 1 },
    });

    yield put(actions.loadSuccess({ data: payload.data, people: people.data }));
  } catch (error) {
    yield put(actions.loadFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

export function* addToPreviewPageSaga() {
  yield takeLatest(actions.loadRequest.type, load);
}
