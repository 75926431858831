/**
 *
 * Users
 *
 */
import * as React from 'react';
import { useParams } from 'react-router';
import { Route, Routes } from 'react-router-dom';
import { List } from './List';
import { UserForm } from './UserForm';

interface Props {}

export function Users(props: Props) {
  const match = useParams();

  return (
    <Routes>
      <Route path={``} element={<List />} />
      <Route path={`:id`} element={<UserForm />} />
    </Routes>
  );
}
