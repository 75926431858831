/**
 *
 * PreviewPage People
 *
 */
import * as React from 'react';
import { IPreviewPagePerson, PreviewPageApiCall } from './slice/types';
import { ApiCall, UploadApiCall } from '../../../types/ApiCall';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { Person } from './Person';
import { Box, Button, Paper } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IPerson, ISoundFile } from '../People/slice/types';
import { useDeepCompareMemoize } from '../../../utils/useDeepCompareMemoize';
import { differenceWith, isEqual } from 'lodash';
import move from 'lodash-move/index';
import { FormBoxContainer } from 'styles/theme/themes';

interface PeopleProps {
  loadPreviewPage: PreviewPageApiCall;
  updatePeople: ApiCall;
  onUpdate: (data: any) => void;
}

export function People(props: PeopleProps) {
  const { loadPreviewPage, updatePeople, onUpdate } = props;

  const initListState = useMemo(() => {
    return loadPreviewPage.data?.people;
  }, [loadPreviewPage.data]);

  const [listState, setListState] = useState<Array<IPreviewPagePerson>>(
    initListState || [],
  );

  const actions = useMemo(() => {
    const emptyActions: { update: Array<any>; delete: Array<any> } = {
      update: [],
      delete: [],
    };

    return initListState?.reduce((acc, person) => {
      const newPerson = listState.find(p => p.id === person.id);
      if (!newPerson) {
        acc.delete.push({
          id: person.id,
        });
      } else if (!isEqual(person, newPerson)) {
        acc.update.push({
          id: person.id,
          ...newPerson.pivot,
        });
      }

      return acc;
    }, emptyActions);
  }, [listState]);

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const newList = move(listState, dragIndex, hoverIndex).map(
        (item, index) => ({
          ...item,
          pivot: { ...item.pivot, order: (index + 1) * 10 },
        }),
      );

      setListState(newList);
    },
    [listState],
  );

  useEffect(() => {
    setListState(initListState || []);
  }, useDeepCompareMemoize([initListState]));

  useEffect(() => {
    setListState(
      [...listState].sort((p1, p2) => p1.pivot.order - p2.pivot.order),
    );
  }, useDeepCompareMemoize([listState]));

  if (!loadPreviewPage.data) {
    return <LoadingIndicator />;
  }

  return (
    <Paper
      sx={{
        my: 4,
      }}
    >
      <FormBoxContainer
        className="soundFiles"
        sx={{
          pointerEvents: updatePeople.loading ? 'none' : 'auto',
          opacity: updatePeople.loading ? 0.5 : 1,
        }}
      >
        {listState.map((person, index) => (
          <Person
            key={`${person.id}-person-entry`}
            index={index}
            moveCard={moveCard}
            loading={updatePeople.loading}
            person={person}
            onUpdateDetails={data => {
              setListState(
                listState.map(p => ({
                  ...p,
                  ...(p.id === person.id
                    ? { pivot: { ...p.pivot, ...data } }
                    : {}),
                })),
              );
            }}
            onDelete={() =>
              setListState(listState.filter(p => p.id !== person.id))
            }
          />
        ))}
        {!!loadPreviewPage.data!.people.length && (
          <Box className={'formActions'}>
            <Button
              className={'alignRight'}
              sx={{ m: 2 }}
              color={'secondary'}
              variant={'contained'}
              disabled={isEqual(listState, initListState)}
              onClick={() => {
                setListState(initListState || []);
              }}
            >
              Cancel changes
            </Button>
            <Button
              className={'align-right'}
              sx={{ m: 2 }}
              color={'primary'}
              variant={'contained'}
              disabled={isEqual(listState, initListState)}
              onClick={() => {
                onUpdate({
                  actions,
                });
              }}
            >
              Save Changes
            </Button>
          </Box>
        )}
      </FormBoxContainer>
    </Paper>
  );
}
