import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import {
  apiCallInitialState,
  ResultMetaData,
  uploadApiCallInitialState,
} from 'types/ApiCall';
import { ResponseErrorPayload } from 'utils/request';
import { previewPagesSliceSaga } from './saga';
import { PreviewPagesSliceState, IPreviewPage } from './types';

export const initialState: PreviewPagesSliceState = {
  previewPages: {
    loading: false,
    data: [],
  },
  save: uploadApiCallInitialState,
  delete: apiCallInitialState,
  changeField: apiCallInitialState,
  previewPage: {
    loading: false,
    data: undefined,
  },
  updatePeople: apiCallInitialState,
};

interface UpdatePeopleRequest {
  remove: Array<number>;
  update: Array<{
    on_website: boolean;
    order: number;
    description: string;
    [key: string]: any;
  }>;
  create: Array<{
    contents: any;
    filename: string;
  }>;
}

const slice = createSlice({
  name: 'previewPagesSlice',
  initialState,
  reducers: {
    loadPreviewPagesDataRequest(
      state,
      action: PayloadAction<{ [key: string]: any }>,
    ) {
      state.previewPages.loading = true;
      state.previewPages.error = undefined;
    },
    loadPreviewPagesDataSuccess(
      state,
      action: PayloadAction<{
        data: Array<IPreviewPage>;
        meta: ResultMetaData;
      }>,
    ) {
      state.previewPages.loading = false;
      state.previewPages.data = action.payload.data;
      state.previewPages.meta = action.payload.meta;
    },
    loadPreviewPagesDataFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.previewPages.loading = false;
      state.previewPages.error = action.payload;
    },
    loadPreviewPagesRequest(
      state,
      action: PayloadAction<{ [key: string]: any }>,
    ) {
      state.previewPages.loading = true;
      state.previewPages.error = undefined;
    },
    loadPreviewPagesSuccess(
      state,
      action: PayloadAction<{
        data: Array<IPreviewPage>;
        meta: ResultMetaData;
      }>,
    ) {
      state.previewPages.loading = false;
      state.previewPages.data = action.payload.data;
      state.previewPages.meta = action.payload.meta;
    },
    loadPreviewPagesFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.previewPages.loading = false;
      state.previewPages.error = action.payload;
    },
    loadPreviewPageRequest(
      state,
      action: PayloadAction<{ id: number | 'new' }>,
    ) {
      state.previewPage.loading = true;
      state.previewPage.error = undefined;
    },
    loadPreviewPageUnmount(state, action: PayloadAction<void>) {
      state.previewPage.loading = false;
      state.previewPage.error = undefined;
      state.previewPage.data = undefined;
      state.save.loading = false;
      state.save.error = undefined;
      state.save.data = undefined;
    },
    loadPreviewPageSuccess(
      state,
      action: PayloadAction<{
        data: IPreviewPage;
      }>,
    ) {
      state.previewPage.loading = false;
      state.previewPage.data = action.payload.data;
    },
    loadPreviewPageFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.previewPage.loading = false;
      state.previewPage.error = action.payload;
    },
    deletePreviewPageRequest(state, action: PayloadAction<number[]>) {
      state.delete.loading = true;
      state.delete.error = undefined;
    },
    deletePreviewPageSuccess(
      state,
      action: PayloadAction<{
        status: 'success';
      }>,
    ) {
      state.delete.loading = false;
      state.delete.data = action.payload.status;
    },
    deletePreviewPageFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.delete.loading = false;
      state.delete.error = action.payload;
    },
    changeFieldRequest(
      state,
      action: PayloadAction<
        {
          id: number;
          field_name: string;
          field_value: string | number | boolean;
        }[]
      >,
    ) {
      state.changeField.loading = true;
      state.changeField.error = undefined;
    },
    changeFieldSuccess(
      state,
      action: PayloadAction<{
        status: 'success';
      }>,
    ) {
      state.changeField.loading = false;
      state.changeField.data = action.payload.status;
    },
    changeFieldFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.changeField.loading = false;
      state.changeField.error = action.payload;
    },
    savePreviewPageRequest(
      state,
      action: PayloadAction<{ [key: string]: any }>,
    ) {
      state.save.loading = true;
      state.save.progress = 0;
      state.save.error = undefined;
    },
    savePreviewPageUnmount(state, action: PayloadAction<void>) {
      state.save.loading = true;
      state.save.error = undefined;
      state.save.data = undefined;
    },
    savePreviewPageSuccess(
      state,
      action: PayloadAction<{ data: IPreviewPage }>,
    ) {
      state.save.loading = false;
      state.save.data = action.payload.data;
    },
    savePreviewPageProgress(
      state,
      action: PayloadAction<{ progress: number }>,
    ) {
      state.save.progress = action.payload.progress;
    },
    savePreviewPageFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.save.loading = false;
      state.save.progress = 0;
      state.save.error = action.payload;
    },
    updatePeopleRequest(state, action: PayloadAction<UpdatePeopleRequest>) {
      state.updatePeople.loading = true;
      state.updatePeople.error = undefined;
    },
    updatePeopleSuccess(state, action: PayloadAction<{ file: File }>) {
      state.updatePeople.loading = false;
      state.updatePeople.data = action.payload;
      state.updatePeople.error = undefined;
    },
    updatePeopleFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.updatePeople.loading = false;
      state.updatePeople.error = action.payload;
    },
    updatePeopleReset(state, action: PayloadAction<void>) {
      state.updatePeople = uploadApiCallInitialState;
    },
  },
});

export const { actions: previewPagesSliceActions } = slice;

export const usePreviewPagesSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: previewPagesSliceSaga });
  return { actions: slice.actions };
};
