import React from 'react';
import { AppBar, Box, Button, Toolbar } from '@mui/material';
import {
  AutoStories,
  Home,
  ManageAccounts,
  Person,
  PowerSettingsNew,
  RecordVoiceOver,
  Settings,
} from '@mui/icons-material';
import { authProviderActions } from 'app/containers/AuthProvider/slice';
import { useDispatch } from 'react-redux';
import { useConfirm } from '../ConfirmDialog';
import { Link } from 'react-router-dom';
import { User } from '../../containers/AuthProvider/slice/types';

export interface Props {
  userData: User;
}

export default function PortalFooter(props) {
  const confirm = useConfirm();
  const dispatch = useDispatch();
  return (
    <Box
      sx={{
        flexGrow: 1,
      }}
    >
      <AppBar elevation={0} square position="static" color={'secondary'}>
        <Toolbar>
          <Button
            color="inherit"
            startIcon={<Home />}
            component={Link}
            to={'/portal'}
          >
            Home
          </Button>
          <Button
            color="inherit"
            startIcon={<RecordVoiceOver />}
            component={Link}
            to={'/portal/people'}
          >
            People
          </Button>
          <Button
            color="inherit"
            startIcon={<AutoStories />}
            component={Link}
            to={'/portal/preview-pages'}
          >
            Preview Pages
          </Button>
          <Button
            color="inherit"
            startIcon={<ManageAccounts />}
            component={Link}
            to={'/portal/accounts'}
          >
            Account Settings
          </Button>

          <Button
            color="inherit"
            component={Link}
            to={'/portal/admin'}
            startIcon={<Settings />}
          >
            Admin Settings
          </Button>

          <Button
            color="inherit"
            startIcon={<PowerSettingsNew />}
            onClick={ev => {
              confirm({ description: 'Log out - are you sure?' })
                .then(() => dispatch(authProviderActions.userLogoutRequest()))
                .catch(() => {});
            }}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
