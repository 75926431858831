import { call, cancelled, put, takeLatest, take } from 'redux-saga/effects';
import { apiRequest } from 'utils/request';
import { taxonomiesSliceActions as actions } from '.';
import { createUploadFileChannel } from 'utils/saga/createFileUploadChannel';

function* loadTaxonomies(action) {
  try {
    const payload = yield call(apiRequest, {
      url: `taxonomy/vocabularies`,
      method: 'get',
      data: {
        ...action.payload,
      },
    });

    yield put(actions.loadTaxonomiesSuccess({ ...payload }));
  } catch (error) {
    yield put(actions.loadTaxonomiesFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* loadTaxonomy(action) {
  try {
    let payload;

    if (action.payload.id !== 'new') {
      payload = yield call(apiRequest, {
        url: `taxonomy/vocabularies/${action.payload.id}`,
        method: 'get',
        data: {
          include_terms: true,
        },
      });
    }

    yield put(actions.loadTaxonomySuccess({ ...payload }));
  } catch (error) {
    yield put(actions.loadTaxonomyFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* saveTaxonomy(action) {
  try {
    const channel = yield call(
      createUploadFileChannel,
      action.payload.id
        ? `taxonomy/vocabularies/${action.payload.id}`
        : 'taxonomy/vocabularies',
      {
        ...action.payload,
      },
      action.payload.id ? 'put' : 'post',
    );
    while (true) {
      const { progress = 0, err, success, response } = yield take(channel);
      if (err) {
        yield put(actions.saveTaxonomyFailed(err));
        return;
      }
      if (success) {
        const res = JSON.parse(response || {});
        yield put(actions.saveTaxonomySuccess(res));
        return;
      }
      yield put(actions.saveTaxonomyProgress({ progress }));
    }
  } catch (error) {
    yield put(actions.saveTaxonomyFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* updateTerms(action) {
  try {
    const payload = yield call(apiRequest, {
      url: `taxonomy/vocabularies/${action.payload.id}/update-terms`,
      method: 'put',
      data: {
        ...action.payload,
      },
    });

    yield put(actions.updateTermsSuccess(payload));
  } catch (error) {
    yield put(actions.updateTermsFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* deleteTaxonomy(action) {
  try {
    const payload = yield call(apiRequest, {
      url: `taxonomy/vocabularies/actions`,
      method: 'post',
      data: {
        actions: action.payload.map(id => ({
          type: 'delete',
          id,
        })),
      },
    });

    yield put(actions.deleteTaxonomySuccess(payload));
  } catch (error) {
    yield put(actions.deleteTaxonomyFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* changeField(action) {
  try {
    const payload = yield call(apiRequest, {
      url: `taxonomy/vocabularies/actions`,
      method: 'post',
      data: {
        actions: action.payload.map(({ id, field_name, field_value }) => ({
          type: 'set-field-value',
          id,
          field_name,
          field_value,
        })),
      },
    });

    yield put(actions.changeFieldSuccess(payload));
  } catch (error) {
    yield put(actions.changeFieldFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

export function* taxonomiesSliceSaga() {
  yield takeLatest(actions.loadTaxonomiesRequest.type, loadTaxonomies);
  yield takeLatest(actions.loadTaxonomyRequest.type, loadTaxonomy);
  yield takeLatest(actions.saveTaxonomyRequest.type, saveTaxonomy);
  yield takeLatest(actions.deleteTaxonomyRequest.type, deleteTaxonomy);
  yield takeLatest(actions.changeFieldRequest.type, changeField);
  yield takeLatest(actions.updateTermsRequest.type, updateTerms);
}
