import { call, cancelled, put, takeLatest, take } from 'redux-saga/effects';
import { apiRequest } from 'utils/request';
import { peopleSliceActions as actions } from '.';
import { createUploadFileChannel } from 'utils/saga/createFileUploadChannel';
import { createRequestSaga, createUploadRequestSaga } from 'utils/saga';

const loadPeople = createRequestSaga({
  actionType: actions.loadPeopleRequest.type,
  url: 'people',
  method: 'get',
  data: payload => ({
    ...payload,
    is_admin: true,
  }),
  successAction: actions.loadPeopleSuccess,
  failureAction: actions.loadPeopleFailed,
});

const loadPerson = createRequestSaga({
  actionType: actions.loadPersonRequest.type,
  url: payload => `people/${payload.id}`,
  method: 'get',
  data: payload => ({
    ...payload,
  }),
  successAction: actions.loadPersonSuccess,
  failureAction: actions.loadPersonFailed,
});

const savePerson = createRequestSaga({
  actionType: actions.savePersonRequest.type,
  url: payload => (payload.id ? `people/${payload.id}` : 'people'),
  method: payload => (payload.id ? 'put' : 'post'),
  data: payload => ({
    ...payload,
  }),
  successAction: actions.savePersonSuccess,
  failureAction: actions.savePersonFailed,
});

const closeEditInvitation = createRequestSaga({
  actionType: actions.closeEditInvitationRequest.type,
  url: payload => `people/${payload.id}/close-edit-invitation`,
  method: 'post',
  data: payload => ({
    action: payload.closeAction,
  }),
  successAction: actions.closeEditInvitationSuccess,
  failureAction: actions.closeEditInvitationFailed,
});

const sendEditInvitation = createRequestSaga({
  actionType: actions.sendEditInvitationRequest.type,
  url: 'people/actions',
  method: 'post',
  data: payload => ({
    actions: payload.map(id => ({
      type: 'send-edit-invitation',
      id,
    })),
  }),
  successAction: actions.sendEditInvitationSuccess,
  failureAction: actions.sendEditInvitationFailed,
});

const updateSoundFiles = createUploadRequestSaga({
  actionType: actions.updateSoundFilesRequest.type,
  getURL: payload => `people/${payload.id}/update-sound-files`,
  getMethod: payload => (payload.id ? 'put' : 'post'),
  successAction: actions.updateSoundFilesSuccess,
  failureAction: actions.updateSoundFilesFailed,
  progressAction: actions.updateSoundFilesProgress,
});

const deletePeople = createRequestSaga({
  actionType: actions.deletePeopleRequest.type,
  url: 'people/actions',
  method: 'post',
  data: payload => ({
    actions: payload.map(id => ({
      type: 'delete',
      id,
    })),
  }),
  successAction: actions.deletePeopleSuccess,
  failureAction: actions.deletePeopleFailed,
});

const changeField = createRequestSaga({
  actionType: actions.changeFieldRequest.type,
  url: 'people/actions',
  method: 'post',
  data: payload => ({
    actions: payload.map(({ id, field_name, field_value }) => ({
      type: 'set-field-value',
      id,
      field_name,
      field_value,
    })),
  }),
  successAction: actions.changeFieldSuccess,
  failureAction: actions.changeFieldFailed,
});

export function* peopleSliceSaga() {
  yield takeLatest(actions.loadPeopleRequest.type, loadPeople);
  yield takeLatest(actions.loadPersonRequest.type, loadPerson);
  yield takeLatest(actions.savePersonRequest.type, savePerson);
  yield takeLatest(actions.deletePeopleRequest.type, deletePeople);
  yield takeLatest(actions.changeFieldRequest.type, changeField);
  yield takeLatest(actions.updateSoundFilesRequest.type, updateSoundFiles);
  yield takeLatest(actions.sendEditInvitationRequest.type, sendEditInvitation);
  yield takeLatest(
    actions.closeEditInvitationRequest.type,
    closeEditInvitation,
  );
}
