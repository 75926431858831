/**
 *
 * FilterBar
 *
 */
import * as React from 'react';
import { identity, isEmpty, isEqual, pickBy, uniqBy } from 'lodash';
import key from 'weak-key';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { usePrevious } from 'utils/usePrevious';
import { DateRangePicker } from 'app/components/DateRangePicker';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useQuery } from 'utils/query';
import { Close, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { ITaxonomyVocabulary } from '../App/slice/types';
import { Autocomplete } from '@mui/material';
import { AttributeValueFilterField } from '../../components/AttributeValueFilterField';

interface Props {
  fields?: Array<{
    name: string;
    props: { [key: string]: any };
  }>;
}

export function FilterBar(props: Props) {
  const { search, pathname } = useLocation();
  const { fields = [] } = props;
  const { query, setQuery } = useQuery();

  let q = {};
  try {
    q = JSON.parse(queryString.parse(search).filters || '{}');
  } catch (e) {}

  const dispatch = useDispatch();
  const [filters, setFilters] = React.useState<{ [key: string]: any }>(q);
  const prevFilters = usePrevious(filters);

  const filtersActive = !isEmpty(filters);
  const [open, setOpen] = React.useState<boolean>(filtersActive);

  const setFilterValue = (obj: { [key: string]: any }) => {
    const newFilters = pickBy({ ...filters, ...obj }, identity);
    Object.keys(newFilters).forEach(fKey => {
      if (isEmpty(newFilters[fKey])) {
        delete newFilters[fKey];
      }
    });
    setFilters(newFilters);
  };

  const clearFilters = () => {
    setFilters({});
  };

  const availableFields = {
    date_range: () => (
      <Grid item sm>
        <DateRangePicker
          values={[filters.date_from, filters.date_to]}
          onChange={values => {
            setFilterValue({ date_from: values[0], date_to: values[1] });
          }}
        />
      </Grid>
    ),
    checkbox: ({
      label = 'Checkbox label',
      fieldName = 'checkbox',
      width = 4,
    }) => (
      <Grid item xs={12} lg={Math.max(width, 2)}>
        <FormControlLabel
          label={
            <Typography sx={{ whiteSpace: 'nowrap' }} variant="subtitle2">
              {label}
            </Typography>
          }
          control={
            <Checkbox
              checked={!!filters[fieldName] || false}
              onChange={ev => {
                setFilterValue({
                  [fieldName]: ev.target.checked ? '1' : undefined,
                });
              }}
            />
          }
        />
      </Grid>
    ),
    select: ({
      label = 'Select label',
      fieldName = 'select',
      defaultValue = '',
      options = [],
      width = 4,
    }: {
      label: string;
      fieldName: string;
      defaultValue?: string;
      options: Array<{ value: string | number; label: string }>;
      width: number;
    }) => (
      <Grid item xs={12} lg={Math.max(width, 2)}>
        <FormControl fullWidth>
          <InputLabel id={`filter-bar-${fieldName}-label`} shrink>
            {label}
          </InputLabel>
          <Select
            labelId={`filter-bar-${fieldName}-label`}
            id={`filter-bar-${fieldName}`}
            value={filters[fieldName] || defaultValue}
            label={label}
            onChange={ev => {
              setFilterValue({
                [fieldName]: ev.target.value || '',
              });
            }}
            input={<OutlinedInput notched label={label} />}
          >
            {options.map(option => (
              <MenuItem
                key={key({ ...option, fieldName })}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    ),
    attributeValue: ({
      label = '',
      attributeOptions,
      valueOptions,
      fieldName,
      valueName = 'value',
      width = 4,
    }: {
      label?: string;
      fieldName: string;
      valueName: string;
      attributeOptions: Array<{ id: number | string; label: string }>;
      valueOptions: Array<number | string>;
      width?: number;
    }) => (
      <Grid item xs={12} lg={Math.max(width, 3)}>
        <AttributeValueFilterField
          attributeOptions={attributeOptions}
          valueOptions={valueOptions}
          label={label}
          valueName={valueName}
          initValues={filters[fieldName]}
          onChange={values => {
            setFilterValue({
              [fieldName]: values || '',
            });
          }}
        />
      </Grid>
    ),
    searchTerm: ({
      label = 'Search term',
      fieldName = 'searchTerm',
      width = 4,
    }) => (
      <Grid item xs={12} lg={Math.max(width, 4)}>
        <TextField
          fullWidth
          label={label}
          value={filters[fieldName] || ''}
          onChange={ev => {
            setFilterValue({ [fieldName]: ev.target.value });
          }}
        />
      </Grid>
    ),
    terms: ({
      label,
      vocabulary,
      width = 4,
    }: {
      label?: string;
      vocabulary: ITaxonomyVocabulary;
      width?: number;
    }) => {
      if (!vocabulary) return null;

      if (!vocabulary.has_values) {
        const values = vocabulary.terms
          .filter(vt => !!(filters.terms || []).find(t => t.id === vt.id))
          .map(vt => ({ ...vt, value: true }));
        return (
          <Grid item xs lg={Math.max(width, 3)}>
            <FormControl fullWidth>
              <Autocomplete
                id={`filter-attribute-${vocabulary.id}`}
                multiple
                renderInput={params => (
                  <TextField
                    {...params}
                    label={label || vocabulary.name}
                    InputLabelProps={{
                      ...params.InputLabelProps,
                    }}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
                getOptionLabel={option => option.name}
                options={vocabulary.terms.map(term => ({
                  id: term.id,
                  name: term.name,
                  value: true,
                }))}
                value={values}
                onChange={(ev, value) => {
                  const includedTerms = value.map(t => ({
                    id: t.id,
                    value: true,
                  }));

                  const excludedTermIds = vocabulary.terms
                    .filter(
                      t => includedTerms.map(t => t.id).indexOf(t.id) === -1,
                    )
                    .map(t => t.id);

                  setFilterValue({
                    terms: uniqBy(
                      [
                        ...(filters.terms || []).filter(
                          t => excludedTermIds.indexOf(t.id) === -1,
                        ),
                        ...includedTerms,
                      ],
                      'id',
                    ),
                  });
                }}
              />
            </FormControl>
          </Grid>
        );
      }
      return null; // todo - create has_values version
    },
    divider: ({}) => (
      <Grid item xs={12}>
        <Divider />
      </Grid>
    ),
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
        {open ? (
          <Button
            variant={'text'}
            onClick={() => setOpen(false)}
            startIcon={<KeyboardArrowUp fontSize="large" />}
          >
            Hide filters
          </Button>
        ) : (
          <Button
            variant={'text'}
            onClick={() => setOpen(true)}
            startIcon={<KeyboardArrowDown fontSize="large" />}
          >
            Show filters
          </Button>
        )}
        {!!filtersActive && (
          <Button
            sx={{ marginLeft: 'auto' }}
            variant={'text'}
            onClick={() => clearFilters()}
            startIcon={<Close fontSize="large" />}
          >
            Clear filters
          </Button>
        )}
      </Box>

      <Box
        sx={{
          maxHeight: open ? '80vh' : '0px',
          overflowY: 'auto',
          transition: 'all 0.4s ease-out',
        }}
      >
        <Box sx={{ pt: 1 }}>
          <Grid container spacing={2} alignItems={'center'}>
            {fields.map(field => (
              <React.Fragment key={key(field)}>
                {availableFields[field.name](field.props)}
              </React.Fragment>
            ))}
          </Grid>
        </Box>
        <Box sx={{ pt: 1, textAlign: 'right' }}>
          <Button
            disabled={isEqual(filters || {}, query.filters || {})}
            color={'info'}
            variant={'contained'}
            onClick={() => {
              setQuery('filters', filters);
              setQuery('page', 1);
            }}
          >
            Apply filter changes
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
