/**
 *
 * LoadingIndicator
 *
 */
import * as React from 'react';
import {
  Box,
  BoxProps,
  CircularProgress,
  CircularProgressProps,
} from '@mui/material';
import { Block } from '@mui/icons-material';

interface Props extends BoxProps {
  message?: React.ReactNode;
  minHeight?: number | string;
}

export function NoResultsMessage(props: Props) {
  const { message = 'No results found', minHeight, sx, ...rProps } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        py: 8,
        minHeight: minHeight || 300,
        ...sx,
      }}
      {...rProps}
    >
      {!!message && <Box sx={{ mb: 6 }}>{message}</Box>}
      <Box sx={{ position: 'relative' }}>
        <Block scale={5} />
      </Box>
    </Box>
  );
}
