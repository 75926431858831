/**
 *
 * VoiceServices
 *
 */
import * as React from 'react';
import { useParams } from 'react-router';
import { Route, Routes } from 'react-router-dom';
import { Edit } from './Edit';

interface Props {}

export function VoiceServices(props: Props) {
  const match = useParams();
  return (
    <Routes>
      <Route path={``} element={<Edit />} />
    </Routes>
  );
}
