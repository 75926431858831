import { call, cancelled, put, takeLatest } from 'redux-saga/effects';
import { apiRequest } from 'utils/request';
import { appActions as actions } from '.';

function* load() {
  try {
    const payload = yield call(apiRequest, {
      url: `taxonomy/vocabularies`,
      method: 'get',
      data: {
        include_terms: true,
      },
    });

    const payload2 = yield call(apiRequest, {
      url: `voice-services`,
      method: 'get',
      data: {},
    });

    const payload3 = yield call(apiRequest, {
      url: `users`,
      method: 'get',
      data: {
        only_active: true,
      },
    });

    yield put(
      actions.loadSuccess({
        vocabularies: payload.data,
        voice_services: payload2.data,
        users: payload3.data,
      }),
    );
  } catch (error) {
    yield put(actions.loadFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

export function* appSaga() {
  yield takeLatest(actions.loadRequest.type, load);
}
