import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { addToPreviewPageSaga } from './saga';
import { EmailPeopleState } from './types';
import { apiCallInitialState } from '../../../../types/ApiCall';
import { ResponseErrorPayload } from '../../../../utils/request';

export const initialState: EmailPeopleState = {
  load: {
    ...apiCallInitialState,
    people: [],
    data: {
      options: [],
    },
  },
};

const slice = createSlice({
  name: 'emailPeople',
  initialState,
  reducers: {
    setOpen(state, action: PayloadAction<boolean>) {
      state.open = action.payload;
    },
    setOptionIndex(state, action: PayloadAction<number>) {
      state.optionIndex = action.payload;
    },
    loadRequest(state, action: PayloadAction<{ ids: Array<number | string> }>) {
      state.load.loading = true;
      state.load.error = undefined;
    },
    loadSuccess(state, action: PayloadAction<any>) {
      state.load.loading = false;
      state.load.data = action.payload.data;
      state.load.people = action.payload.people;
      state.load.error = undefined;
    },
    loadFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.load.loading = false;
      state.load.error = action.payload;
    },
  },
});

export const { actions: emailPeopleActions } = slice;

export const useEmailPeopleSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: addToPreviewPageSaga });
  return { actions: slice.actions };
};
