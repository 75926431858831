/**
 *
 * PreviewPage
 *
 */
import React, { memo, useEffect, useMemo, useState } from 'react';
import { usePreviewPageSlice } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectPreviewPageLoad } from './slice/selectors';
import { useParams } from 'react-router';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import {
  Box,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import key from 'weak-key';
import { MarkupBlock } from '../../components/MarkupBlock';
import { useNavigate } from 'react-router-dom';
import { Star } from '@mui/icons-material';

interface Props {}

export const PreviewPage = memo((props: Props) => {
  const [showPhotos, setShowPhotos] = useState<boolean>(false);

  const params = useParams<{
    key: string;
  }>();
  const navigate = useNavigate();
  const { actions } = usePreviewPageSlice();
  const dispatch = useDispatch();
  const theme = useTheme();
  const load = useSelector(selectPreviewPageLoad);

  useEffect(() => {
    dispatch(actions.loadRequest(params.key || ''));
  }, [dispatch]);

  useEffect(() => {
    if (load.error) navigate('/404', { replace: true });
  }, [load.error]);

  const content = useMemo(() => {
    if (!load.data) {
      return (
        <LoadingIndicator
          message={'Loading preview page'}
          minHeight={'100vh'}
        />
      );
    }
    return (
      <>
        <Box sx={{ textAlign: 'center', py: 4 }}>
          {load.data.logo_url ? (
            <Box
              sx={{
                transition: theme =>
                  theme.transitions.create('opacity', {
                    duration: 400,
                  }),
                display: 'inline-block',
                backgroundRepeat: 'no-repeat',
                backgroundImage: `url("${load.data.logo_url}")`,
                backgroundSize: 'contain',
                backgroundPosition: 'center center',
                width: 300,
                height: 150,
              }}
              title={load.data.company_name}
            />
          ) : (
            <Typography variant={'h1'}>{load.data.company_name}</Typography>
          )}
        </Box>
        <Box
          sx={{
            px: 4,
            my: 2,
            '& .description a': {
              textDecoration: 'underline',
            },
          }}
        >
          <MarkupBlock
            markup={load.data.description}
            nl2br
            className="description"
          />{' '}
        </Box>
        <Box sx={{ my: 2, px: 4 }}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  onClick={ev => setShowPhotos(!showPhotos)}
                  checked={showPhotos}
                />
              }
              label="Toggle photos"
            />
          </FormGroup>
        </Box>
        <Stack divider={<Divider />}>
          {load.data.people.map(person => (
            <Box sx={{ px: 4, py: 2 }} key={key(person)}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={4} md={3} textAlign={'center'}>
                  <Paper sx={{ backgroundColor: 'grey.A200' }}>
                    <Box
                      sx={{
                        transition: theme =>
                          theme.transitions.create('opacity', {
                            duration: 400,
                          }),
                        opacity: showPhotos ? 1 : 0,
                        display: 'inline-block',
                        backgroundRepeat: 'no-repeat',
                        backgroundImage: `url("${person.photo_url}")`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center center',
                        width: '100%',
                        height: 0,
                        paddingTop: '150%',
                      }}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                  <Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                      }}
                    >
                      {!!person.tvg_rates && (
                        <Tooltip title={'TVG Rates'}>
                          <Star fontSize={'large'} color={'warning'} />
                        </Tooltip>
                      )}

                      <Typography variant={'h5'}>
                        {person.name_abbreviated}
                      </Typography>
                    </Box>
                    <Stack spacing={1}>
                      {(person.sound_files || []).map(sf => (
                        <Box sx={{ py: 1 }} key={key(sf)}>
                          <Typography sx={{ mb: 1 }} variant={'h6'}>
                            {sf.description}
                          </Typography>
                          <audio preload="none" src={sf.file_url} controls />
                        </Box>
                      ))}
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ))}
        </Stack>
      </>
    );
  }, [load, showPhotos]);

  return (
    <Box sx={{ backgroundColor: grey.A400, minHeight: '100vh' }}>
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Paper>{content}</Paper>
      </Container>
    </Box>
  );
});
