import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { appSaga } from './saga';
import { AppState } from './types';
import { ResponseErrorPayload } from 'utils/request';
import { apiCallInitialState } from '../../../../types/ApiCall';

export const initialState: AppState = {
  load: {
    loading: false,
    error: undefined,
    data: {
      vocabularies: [],
      voice_services: [],
      users: [],
    },
  },
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    loadRequest(state, action: PayloadAction<void>) {
      state.load.loading = true;
      state.load.error = undefined;
    },
    loadSuccess(state, action: PayloadAction<any>) {
      state.load.loading = false;
      state.load.data = action.payload;
    },
    loadFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.load.loading = false;
      state.load.error = action.payload;
    },
  },
});

export const { actions: appActions } = slice;

export const useAppSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: appSaga });
  return { actions: slice.actions };
};
