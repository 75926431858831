/**
 *
 * ImportDataForm
 *
 */
import * as React from 'react';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  Fab,
  MenuItem,
  Select,
  TextField,
  useTheme,
  Typography,
  Paper,
} from '@mui/material';
import { Add, AddCircle, Close } from '@mui/icons-material';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { useEffect } from 'react';

interface Props {
  loading: boolean;
  progress: number;
  request: Function;
}

export function SoundFileUploadForm(props: Props) {
  const [stage, setStage]: [stage: number, setStage: Function] =
    React.useState(1);

  const theme = useTheme();

  const [file, setFile]: [file: File | undefined, setFile: Function] =
    React.useState(undefined);

  const removeFile = () => {
    setStage(1);
    setFile(undefined);
  };

  const fileChange = e => {
    const [f] = e.target.files || e.dataTransfer.files;
    setFile(f);
    setStage(3);
  };

  const upload = () => {
    if (!props.loading) {
      setStage(4);
      props.request(file);
    }
  };

  useEffect(() => {
    if (stage === 4 && !props.loading) {
      setStage(1);
    }
  }, [props.loading]);

  switch (stage) {
    case 1:
    case 2:
      return (
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            cursor: 'pointer',
            paddingTop: 4,
            paddingBottom: 4,
          }}
        >
          <Fab color="primary">
            <Add />
          </Fab>
          <Box
            sx={{
              ml: 4,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h4" color="primary">
              Add a new file
            </Typography>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              sx={{ textDecoration: 'underline' }}
            >
              Select from folder
            </Typography>
          </Box>

          <input
            type="file"
            accept={['audio/*'].join(' ')}
            onChange={fileChange}
            style={{
              opacity: 0,
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              cursor: 'pointer',
            }}
          />
        </Box>
      );

    case 3:
      return (
        <Paper
          elevation={2}
          sx={{
            backgroundColor: theme.palette.grey[50],
            maxWidth: 600,
            p: 4,
            position: 'relative',
          }}
        >
          <IconButton
            size="medium"
            color="primary"
            onClick={() => removeFile()}
            sx={{ position: 'absolute', right: 2, top: 2 }}
          >
            <Close />
          </IconButton>
          <Typography variant="h5" color={'primary'}>
            {file!.name}
          </Typography>
          <Typography variant="subtitle1">{file!.size} bytes</Typography>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={() => upload()}
            disabled={props.loading}
          >
            Upload
          </Button>
        </Paper>
      );

    case 4:
      return (
        <LoadingIndicator
          minHeight={300}
          ProgressProps={{
            variant: 'determinate',
            value: props.progress * 100,
          }}
          message={
            <>
              <Typography variant="h5">
                Uploading - {(props.progress || 0).toFixed(0)}%
              </Typography>
            </>
          }
        />
      );
  }
  return null;
}
