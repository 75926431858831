/**
 *
 * PersonForm
 *
 */

import React, { useCallback } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Skeleton,
  Typography,
} from '@mui/material';
import Yup from 'utils/yup';
import {
  IAttributeChange,
  IColumnChange,
  IVoiceServiceChange,
  PersonApiCall,
} from './slice/types';
import { FormBoxContainer } from 'styles/theme/themes';
import { ApiCall } from '../../../types/ApiCall';
import { useConfirm } from '../../components/ConfirmDialog';
import { get, isBoolean } from 'lodash';

const schema = Yup.object().shape({});

interface PersonFormProps {
  loadPerson: PersonApiCall;
  sendEditInvitation: ApiCall;
  closeEditInvitation: ApiCall;
  onSend: () => void;
  onClose: (action: 'accept' | 'cancel') => void;
}

export function EditInvitations(props: PersonFormProps) {
  const {
    loadPerson,
    sendEditInvitation,
    closeEditInvitation,
    onClose,
    onSend,
  } = props;

  const confirm = useConfirm();

  const cancelUpdates = useCallback(() => {
    confirm({
      title: 'Cancel updates?',
      description:
        'This will discard the updates and the database will remain unchanged.',
    })
      .then(() => onClose('cancel'))
      .catch(() => {});
  }, [loadPerson, closeEditInvitation, onClose]);

  const acceptUpdates = useCallback(() => {
    confirm({
      title: 'Accept updates?',
      description: 'This will merge the changes into the database.',
    })
      .then(() => onClose('accept'))
      .catch(() => {});
  }, [loadPerson, closeEditInvitation, onClose]);

  const showContent = useCallback(() => {
    if (loadPerson.loading && !loadPerson.data)
      return (
        <Box sx={{ my: 4, height: 400 }}>
          <Skeleton height={400} variant={'rectangular'} />
        </Box>
      );

    const status = loadPerson.data?.edit_invitation
      ? loadPerson.data?.edit_invitation.status
      : 0;

    switch (status) {
      case 0:
        return (
          <Box>
            <Button
              disabled={sendEditInvitation.loading}
              variant={'outlined'}
              onClick={() => {
                confirm({ title: 'Send invitation to edit details?' })
                  .then(() => {
                    onSend();
                  })
                  .catch(() => {});
              }}
              size={'large'}
            >
              Send invitation to edit
            </Button>
          </Box>
        );
      case 1:
        return (
          <Box>
            <Typography sx={{ mb: 2 }}>
              Invitation is currently awaiting submission (sent{' '}
              {new Date(
                loadPerson.data?.edit_invitation?.last_sent_at || 0,
              ).toLocaleDateString()}
              ). You can resend below. You can preview this form by clicking{' '}
              <a
                href={loadPerson.data?.edit_invitation_url || ''}
                target={'_blank'}
              >
                here
              </a>
              .
            </Typography>
            <Button
              disabled={sendEditInvitation.loading}
              variant={'outlined'}
              onClick={() => {
                confirm({ title: 'Resend invitation to edit details?' })
                  .then(() => {
                    onSend();
                  })
                  .catch(() => {});
              }}
              size={'large'}
            >
              Resend invitation
            </Button>
          </Box>
        );
      case 5:
        const columnChanges = get(
          loadPerson.data,
          'edit_invitation.data_changes.columnChanges',
          [],
        );
        const photoChanges = get(
          loadPerson.data,
          'edit_invitation.data_changes.photoChanges',
          null,
        );
        const attributeChanges = get(
          loadPerson.data,
          'edit_invitation.data_changes.attributeChanges',
          [],
        );

        const voiceServiceChanges = get(
          loadPerson.data,
          'edit_invitation.data_changes.voiceServiceChanges',
          [],
        );
        return (
          <FormBoxContainer>
            <Typography sx={{ mb: 4 }}>
              Updated details submitted{' '}
              {new Date(
                loadPerson.data?.edit_invitation?.submitted_at || 0,
              ).toLocaleDateString()}
              . Please review the changes below.
            </Typography>
            {!!columnChanges.length && (
              <Box sx={{ mb: 4 }}>
                <Box sx={{ mb: 2 }}>
                  <Typography variant={'h4'}>Column changes</Typography>
                </Box>
                <Grid container spacing={2}>
                  {columnChanges.map((columnChange: IColumnChange) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ mb: 4 }}>
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="h5">
                          {columnChange.label}
                        </Typography>
                      </Box>
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle2">From:</Typography>
                        <Typography variant="body1">
                          {isBoolean(columnChange.from) ? (
                            <>{columnChange.from === true ? 'True' : 'False'}</>
                          ) : (
                            columnChange.from
                          )}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="subtitle2">To:</Typography>
                        <Typography variant="body1">
                          {isBoolean(columnChange.to) ? (
                            <>{columnChange.to === true ? 'True' : 'False'}</>
                          ) : (
                            columnChange.to
                          )}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
            {!!photoChanges && (
              <Box sx={{ mb: 4 }}>
                <Box sx={{ mb: 2 }}>
                  <Typography variant={'h4'} sx={{ mb: 2 }}>
                    Photo changes
                  </Typography>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={'auto'}>
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="h5">From:</Typography>
                        {!!photoChanges.old_url ? (
                          <Box
                            sx={{
                              backgroundRepeat: 'no-repeat',
                              backgroundImage: `url("${photoChanges.old_url}")`,
                              backgroundSize: 'cover',
                              backgroundPosition: 'center center',
                              width: 150,
                              height: 200,
                            }}
                          />
                        ) : (
                          <Typography variant="h6">No image</Typography>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="h5">To:</Typography>
                        {!!photoChanges.new_url ? (
                          <Box
                            sx={{
                              backgroundRepeat: 'no-repeat',
                              backgroundImage: `url("${photoChanges.new_url}")`,
                              backgroundSize: 'cover',
                              backgroundPosition: 'center center',
                              width: 150,
                              height: 200,
                            }}
                          />
                        ) : (
                          <Typography variant="h6">No image</Typography>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            )}
            {!!attributeChanges.length && (
              <Box sx={{ mb: 4 }}>
                <Box sx={{ mb: 2 }}>
                  <Typography variant={'h4'}>Attribute changes</Typography>
                </Box>
                <Grid container spacing={2}>
                  {attributeChanges.map((attributeChange: IAttributeChange) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ mb: 4 }}>
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="h5">
                          {attributeChange.label}
                        </Typography>
                      </Box>
                      {attributeChange.changes.map(change => (
                        <Box sx={{ mb: 4 }}>
                          <Box sx={{ mb: 2 }}>
                            <Typography variant="subtitle2">
                              {change.action.toUpperCase()}:
                            </Typography>
                            <Typography variant="body1">
                              {change.label}
                            </Typography>
                          </Box>

                          {!!change.from && (
                            <Box sx={{ mb: 2 }}>
                              <Typography variant="subtitle2">From:</Typography>
                              <Typography variant="body1">
                                {change.from}
                              </Typography>
                            </Box>
                          )}

                          {!!(change.to && change.to !== true) && (
                            <Box>
                              <Typography variant="subtitle2">To:</Typography>
                              <Typography variant="body1">
                                {change.to}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      ))}
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}

            {!!voiceServiceChanges.length && (
              <Box sx={{ mb: 4 }}>
                <Box sx={{ mb: 2 }}>
                  <Typography variant={'h4'}>Voice service changes</Typography>
                </Box>
                <Grid container spacing={2}>
                  {voiceServiceChanges.map(
                    (voiceServiceChange: IVoiceServiceChange) => (
                      <Grid item xs={12} sm={6} md={4} lg={3} sx={{ mb: 4 }}>
                        <Box sx={{ mb: 2 }}>
                          <Typography variant="h5">
                            {voiceServiceChange.label}
                          </Typography>
                        </Box>
                        {voiceServiceChange.changes.map(change => (
                          <Box sx={{ mb: 4 }}>
                            <Box sx={{ mb: 2 }}>
                              <Typography variant="body1">
                                {change.label}
                              </Typography>
                            </Box>

                            {!!change.from && (
                              <Box sx={{ mb: 2 }}>
                                <Typography variant="subtitle2">
                                  From:
                                </Typography>
                                <Typography variant="body1">
                                  {change.from}
                                </Typography>
                              </Box>
                            )}

                            {!!(change.to && change.to !== true) && (
                              <Box>
                                <Typography variant="subtitle2">To:</Typography>
                                <Typography variant="body1">
                                  {change.to}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        ))}
                      </Grid>
                    ),
                  )}
                </Grid>
              </Box>
            )}

            <Box className={'formActions'} sx={{ mt: 4 }}>
              <Button
                size="small"
                variant="contained"
                color="error"
                disabled={loadPerson.loading || closeEditInvitation.loading}
                onClick={() => cancelUpdates()}
              >
                Cancel updates
              </Button>
              <Button
                className="align-right"
                type="submit"
                variant="contained"
                color="primary"
                disabled={loadPerson.loading || closeEditInvitation.loading}
                onClick={() => acceptUpdates()}
              >
                Accept updates
              </Button>
            </Box>
          </FormBoxContainer>
        );
    }
  }, [loadPerson, onSend]);

  return <Box sx={{ my: 2, p: 2 }}>{showContent()}</Box>;
}
