/**
 *
 * LegalDocumentForm
 *
 */

import React, { useEffect } from 'react';
import { Box, Container, Paper, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useLegalDocumentsSlice } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectLegalDocumentsSlice } from './slice/selectors';
import { useSnackbar } from 'notistack';
import { useHasChanged } from '../../../utils/usePrevious';
import { appActions } from '../App/slice';
import { Form } from './Form';
import key from 'weak-key';
import { get } from 'lodash';
import { ApiCall } from '../../../types/ApiCall';

interface Props {
  code: {
    code: string;
    name: string;
  };
  document?: any;
  onUpdateItem: (values: any) => void;
}
export function DocumentForm(props: Props) {
  const { code, document, onUpdateItem } = props;
  return (
    <Paper variant={'outlined'} sx={{ p: 4 }}>
      <Stack spacing={2}>
        <Typography variant={'h5'}>{code.name}</Typography>

        <Form
          name={code.name}
          code={code.code}
          data={document}
          updateItem={onUpdateItem}
        />
      </Stack>
    </Paper>
  );
}
