/**
 *
 * AddToPreviewPage
 *
 */
import * as React from 'react';
import { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { useAddToPreviewPageSlice } from './slice';
import { IPerson } from '../People/slice/types';
import {
  Box,
  Dialog,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button, { ButtonProps } from '@mui/material/Button';
import { DialogProps } from '@mui/material/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { selectAddToPreviewPage } from './slice/selectors';

import { Autocomplete } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { get } from 'lodash';
import { useHasChanged } from '../../../utils/usePrevious';
import { LoadingIndicator } from '../../components/LoadingIndicator';

interface Props {
  ids: (string | number)[];
  title?: ReactNode;
  dialogProps?: DialogProps;
  confirmationButtonProps?: ButtonProps;
  cancellationButtonProps?: ButtonProps;
  cancellationText?: ReactNode;
  confirmationText?: ReactNode;
  confirmationAndContinueText?: ReactNode;
}

export function AddToPreviewPage(props: Props) {
  const {
    ids,
    title = <>Add to preview page?</>,
    dialogProps = {},
    cancellationButtonProps = {},
    cancellationText = 'Cancel',
    confirmationButtonProps = {},
    confirmationText = 'Add',
    confirmationAndContinueText = 'Add & go to page',
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { actions } = useAddToPreviewPageSlice();
  const selectedState = useSelector(selectAddToPreviewPage);
  const { load, add, pageId, pageName, newPage, goToPage, open } =
    selectedState;

  const onClose = () => {
    dispatch(actions.setOpen(false));
  };
  const onCancel = () => {
    dispatch(actions.setOpen(false));
  };

  const people = useMemo(() => {
    return load.people;
  }, [load.data, ids]);

  const onConfirm = (goToPage?: boolean) => {
    dispatch(
      actions.addRequest({
        ids: people.map(p => p.id!),
        pageId: newPage ? undefined : pageId,
        pageName: !newPage ? undefined : pageName,
        goToPage,
      }),
    );
  };

  const loadItems = () => {
    dispatch(actions.loadRequest({ ids }));
  };

  useEffect(() => {
    if (open) loadItems();
  }, [open]);

  const addLoadingChanged = useHasChanged(add.loading);

  useEffect(() => {
    if (addLoadingChanged && !add.loading) {
      dispatch(actions.loadRequest({ ids }));
      dispatch(actions.setOpen(false));
    }
  });

  useEffect(() => {
    if (!load.loading) {
      let id;

      if (newPage) {
        id = get(load.data, '[0].id', null);
      } else {
        id = pageId;
      }

      if (newPage && id) {
        dispatch(actions.setPageName(''));
        dispatch(actions.setNewPage(false));
        dispatch(actions.setPageId(id));
      }

      if (goToPage && id) {
        dispatch(actions.setGoToPage(false));
        navigate(`/portal/preview-pages/${id}/people`);
      }
    }
  }, [load.loading]);

  // don't show if no people selected
  useEffect(() => {
    if (!ids.length && !!open) {
      dispatch(actions.setOpen(false));
    }
  }, [people, open, dispatch]);

  useEffect(() => {
    return () => {
      if (open) dispatch(actions.setOpen(false));
    };
  }, [open]);

  const canSubmit = useCallback(() => {
    return !(
      !people.length ||
      (newPage && !pageName) ||
      (!newPage && !pageId) ||
      add.loading ||
      load.loading
    );
  }, [people, pageId, pageName, newPage, add.loading, load.loading]);

  return (
    <Dialog fullWidth {...dialogProps} open={!!open} onClose={onClose}>
      {title && <DialogTitle>{title}</DialogTitle>}

      <DialogContent>
        {load.loading && <LoadingIndicator />}
        {!load.loading && people.length > 0 && (
          <>
            <DialogContentText sx={{ mb: 2 }}>
              {people.length > 1
                ? `Adding ${people.length} people to preview page.`
                : 'Adding selected person to a preview page.'}
            </DialogContentText>
            <DialogContentText>
              If a person is already added to the page, they will not be added
              again and no page-specific settings will be overwritten.
            </DialogContentText>
            <Box sx={{ my: 2 }}>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  Choose page
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  value={newPage ? 'new' : 'existing'}
                  onChange={event =>
                    dispatch(actions.setNewPage(event.target.value === 'new'))
                  }
                >
                  <FormControlLabel
                    value="new"
                    control={<Radio />}
                    label="New page"
                  />
                  <FormControlLabel
                    value="existing"
                    control={<Radio />}
                    label="Existing page"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            {newPage ? (
              <Box sx={{ my: 2 }}>
                <TextField
                  fullWidth
                  value={pageName}
                  onChange={ev =>
                    dispatch(actions.setPageName(ev.target.value))
                  }
                  placeholder="Enter new preview page reference here"
                />
              </Box>
            ) : (
              <Box sx={{ my: 2 }}>
                <Autocomplete
                  renderInput={p => (
                    <TextField
                      placeholder="Select existing preview page"
                      {...p}
                    />
                  )}
                  onChange={(e, v) =>
                    dispatch(actions.setPageId(v ? v.id! : undefined))
                  }
                  value={load.data.find(pp => pp.id === pageId) || null}
                  getOptionLabel={option =>
                    `${option.reference} (${option.id})`
                  }
                  options={load.data}
                />
              </Box>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-end' }}>
        <Button
          {...cancellationButtonProps}
          onClick={onCancel}
          sx={{ marginRight: 'auto' }}
        >
          {cancellationText}
        </Button>
        <Button
          disabled={!canSubmit()}
          color="primary"
          variant={'contained'}
          {...confirmationButtonProps}
          onClick={() => onConfirm()}
        >
          {confirmationText}
        </Button>
        <Button
          disabled={!canSubmit()}
          color="primary"
          variant={'contained'}
          {...confirmationButtonProps}
          onClick={() => onConfirm(true)}
        >
          {confirmationAndContinueText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
