import React from 'react';
import {
  AppBar,
  Box,
  ButtonBase,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  AutoStories,
  Home,
  ManageAccounts,
  PowerSettingsNew,
  RecordVoiceOver,
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { User } from 'app/containers/AuthProvider/slice/types';
import { authProviderActions } from '../../containers/AuthProvider/slice';
import { useConfirm } from '../ConfirmDialog';
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';

export interface Props {
  userData: User;
}

export default function PortalAppBar(props: Props) {
  const theme = useTheme();
  const confirm = useConfirm();
  const dispatch = useDispatch();

  return (
    <AppBar elevation={0} square position="sticky" color={'secondary'}>
      <Toolbar>
        <Box
          sx={{
            padding: '8px 8px 8px 0',
            flexGrow: 1,
            [theme.breakpoints.down('sm')]: {
              display: 'none',
            },
            '& svg': {
              maxHeight: 40,
              width: 'auto',
            },
          }}
        >
          <ButtonBase component={Link} to={'/portal'}>
            <Typography variant="h6">TVG VOICE REPO</Typography>
          </ButtonBase>
        </Box>
        <Box
          sx={{
            '& .iconButton': {
              ml: 1,
            },
          }}
        >
          <Tooltip title={'Home Dashboard'}>
            <IconButton
              className="iconButton"
              component={Link}
              to={'/portal'}
              color="inherit"
              aria-label="menu"
            >
              <Home />
            </IconButton>
          </Tooltip>
          <Tooltip title={'Preview Pages'}>
            <IconButton
              className="iconButton"
              color="inherit"
              aria-label="menu"
              component={Link}
              to={'/portal/preview-pages'}
            >
              <AutoStories />
            </IconButton>
          </Tooltip>

          <Tooltip title={'Manage People'}>
            <IconButton
              className="iconButton"
              color="inherit"
              aria-label="menu"
              component={Link}
              to={'/portal/people'}
            >
              <RecordVoiceOver />
            </IconButton>
          </Tooltip>

          <Tooltip title={'Account Management'}>
            <IconButton
              className="iconButton"
              color="inherit"
              aria-label="menu"
              component={Link}
              to={'/portal/accounts'}
            >
              <ManageAccounts />
            </IconButton>
          </Tooltip>

          <Tooltip title={'Logout'}>
            <IconButton
              className="iconButton"
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={ev => {
                confirm({
                  description: 'Log out - are you sure?',
                })
                  .then(() => dispatch(authProviderActions.userLogoutRequest()))
                  .catch(() => {});
              }}
            >
              <PowerSettingsNew />
            </IconButton>
          </Tooltip>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
