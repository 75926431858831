/**
 *
 * AccountSettings
 *
 */
import * as React from 'react';
import {
  Box,
  Button,
  Container,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import clsx from 'clsx';
import { FormBoxContainer } from 'styles/theme/themes';
import { PasswordField } from 'app/components/PasswordField';
import Yup from 'utils/yup';
import { useAccountSettingsSlice } from './slice';
import { selectChangePassword } from './slice/selectors';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router';
import { useMemo } from 'react';
import { Link, Navigate, Route, Routes } from 'react-router-dom';
import { Users } from '../Users';
import { ChangePassword } from './ChangePassword';
import { Reset2FA } from './Reset2FA';

interface Props {}

export function AccountSettings(props: Props) {
  const params = useParams<{
    section?: string;
  }>();

  const basePath = '/portal/accounts';

  const defaultPage = `${basePath}/change-password`;
  return (
    <Box>
      {' '}
      <Paper
        square
        sx={{
          backgroundColor: 'secondary.dark',
          color: 'white',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box sx={{ flex: '0 0 20%', pl: 1 }} />
        <Tabs
          value={params.section || defaultPage}
          variant={'scrollable'}
          textColor={'inherit'}
        >
          <Tab
            value={'change-password'}
            component={Link}
            to={`/portal/accounts/change-password`}
            label={'Change Password'}
          />
          <Tab
            value={'reset-2fa'}
            component={Link}
            to={`/portal/accounts/reset-2fa`}
            label={'Reset 2fa'}
          />
        </Tabs>
        <Box sx={{ flex: '0 0 20%', textAlign: 'right', pr: 1 }} />
      </Paper>
      <Container maxWidth={'xl'}>
        {params.section === `change-password` && <ChangePassword />}
        {params.section === `reset-2fa` && <Reset2FA />}

        {!params.section && <Navigate to={defaultPage} />}
      </Container>
    </Box>
  );
}
