import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) =>
  state.voiceServicesSlice || initialState;

export const selectVoiceServicesSlice = createSelector(
  [selectSlice],
  state => state,
);
export const selectVoiceServices = createSelector(
  [selectSlice],
  state => state.voiceServices,
);

export const selectUpdateItems = createSelector(
  [selectSlice],
  state => state.updateItems,
);
