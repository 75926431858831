/* --- STATE --- */
import { ApiCall } from 'types/ApiCall';

export interface AuthProviderState {
  login: ApiCall;
  authenticate2fa: ApiCall<any>;
  logout: ApiCall;
  loadUser: UserApiCall;
  forgotPassword: ApiCall;
  resetPassword: ApiCall;
  activateAccount: ApiCall;
  initialised: boolean;
  loggedIn: boolean;
  refreshing: boolean;
  loading: boolean;
}

export interface UserApiCall extends ApiCall {
  data: User | undefined;
}

// todo - get this from the API
export enum UserPermission {
  VIEW_OWN_DATA = 'view own data',
  VIEW_ALL_DATA = 'view all data',
  ADMINISTER_SYSTEM = 'administer system',
}
export type User = {
  id: number;
  uuid?: string;
  email: string;
  permissions: Array<UserPermission>;
  pusher?: {
    app_key: string;
    cluster: string;
    tls: boolean;
  };
  active: boolean;
  google2fa_status: 'disabled' | 'active' | 'inactive';
};

export type ContainerState = AuthProviderState;
