import { call, cancelled, put, takeLatest, take } from 'redux-saga/effects';
import { apiRequest } from 'utils/request';
import { legalDocumentsSliceActions as actions } from '.';

function* loadLegalDocuments(action) {
  try {
    const payload = yield call(apiRequest, {
      url: `documents/load-by-codes`,
      method: 'get',
      data: {
        codes: ['nda', 'coc', 'rrg', 'ccp'],
      },
    });

    yield put(actions.loadLegalDocumentsSuccess(payload));
  } catch (error) {
    yield put(actions.loadLegalDocumentsFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* updateItem(action) {
  try {
    const payload = yield call(apiRequest, {
      url: `documents/update/${action.payload.code}`,
      method: 'put',
      data: {
        ...action.payload,
      },
    });

    yield put(actions.updateItemSuccess(payload));
  } catch (error) {
    yield put(actions.updateItemFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

export function* legalDocumentsSliceSaga() {
  yield takeLatest(actions.loadLegalDocumentsRequest.type, loadLegalDocuments);
  yield takeLatest(actions.updateItemRequest.type, updateItem);
}
