import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import { Box, IconButton } from '@mui/material';

interface Props {
  buttonText?: React.ReactNode;
  popoverContent: React.ReactNode;
  buttonProps?: ButtonProps;
  buttonIcon?: React.ReactNode;
}

export function PopoverButton(props: Props) {
  const { buttonIcon, buttonText, popoverContent, buttonProps } = props;
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'demoPopover',
  });
  return (
    <div>
      {buttonIcon ? (
        <IconButton {...(props.buttonProps || {})} {...bindTrigger(popupState)}>
          {props.buttonIcon}
        </IconButton>
      ) : (
        <Button
          variant="contained"
          {...(props.buttonProps || {})}
          {...bindTrigger(popupState)}
        >
          {props.buttonText}
        </Button>
      )}
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box>{props.popoverContent}</Box>
      </Popover>
    </div>
  );
}
