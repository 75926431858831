/**
 *
 * LegalDocumentForm
 *
 */

import React, { useEffect } from 'react';
import { Box, Container, Paper, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useLegalDocumentsSlice } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectLegalDocumentsSlice } from './slice/selectors';
import { useSnackbar } from 'notistack';
import { useHasChanged } from '../../../utils/usePrevious';
import { appActions } from '../App/slice';
import { Form } from './Form';
import key from 'weak-key';
import { get } from 'lodash';
import { DocumentForm } from './DocumentForm';

export function Edit() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { actions } = useLegalDocumentsSlice();

  const { legalDocuments, updateItem } = useSelector(selectLegalDocumentsSlice);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const load = () => {
    dispatch(actions.loadLegalDocumentsRequest());
  };

  useEffect(() => {
    load();
    return () => {
      // unmount if needed
    };
  }, []);

  const updateLoadingHasChanged = useHasChanged(updateItem.loading);

  React.useEffect(() => {
    if (updateLoadingHasChanged && !updateItem.loading) {
      load();
      dispatch(appActions.loadRequest());
    }
  });
  /*

   */

  const codes = [
    {
      code: 'nda',
      name: 'Non-Disclosure Agreement',
    },
    {
      code: 'coc',
      name: 'Code of Conduct',
    },
    {
      code: 'rrg',
      name: 'Remote Recording Guidelines',
    },
    {
      code: 'ccp',
      name: 'Child Care Policy',
    },
  ];
  console.log(legalDocuments.data);
  return (
    <>
      <Box>
        <Container maxWidth={'xl'}>
          <Paper sx={{ my: 4, p: 4 }}>
            <Stack spacing={2}>
              {codes.map(code => (
                <DocumentForm
                  onUpdateItem={values =>
                    dispatch(actions.updateItemRequest(values))
                  }
                  key={key(code)}
                  code={code}
                  document={get(legalDocuments, `data[${code.code}]`)}
                />
              ))}
            </Stack>
          </Paper>
        </Container>
      </Box>
    </>
  );
}
