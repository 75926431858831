/**
 *
 * Taxonomy Sound Files
 *
 */
import * as React from 'react';
import { ITaxonomyTerm } from './slice/types';
import { uniq } from 'lodash';
import {
  TextField,
  Box,
  IconButton,
  Button,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import {
  Check,
  Close,
  DragHandleOutlined,
  Edit,
  Save,
} from '@mui/icons-material';
import { isEqual } from 'lodash';
import { useConfirm } from '../../components/ConfirmDialog';
import { grey } from '@mui/material/colors';
import { useDrag, useDrop } from 'react-dnd';
import { Identifier, XYCoord } from 'dnd-core';
import { ItemTypes } from '../PreviewPages/slice/types';

interface SoundFilesProps {
  loading: boolean;
  term: ITaxonomyTerm;
  onUpdateDetails: (index: number, data?: Partial<ITaxonomyTerm>) => void;
  onDelete: (index: number) => void;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export function Term(props: SoundFilesProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { term, onUpdateDetails, onDelete, moveCard, index, loading } = props;

  const [name, setName] = useState<string>(term.name || '');
  const [isOther, setIsOther] = useState<boolean>(!!term.is_other);
  const [editing, setEditing] = useState<boolean>(false);

  const confirm = useConfirm();

  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  useEffect(() => {
    if (name !== term.name || isOther !== term.is_other) {
      onUpdateDetails(index, { name, is_other: isOther });
    }
  }, [name, isOther]);

  useEffect(() => {
    if (name !== term.name || isOther !== term.is_other) {
      setName(term.name || '');
      setIsOther(!!term.is_other);
      setEditing(false);
    }
  }, [term]);

  useEffect(() => {
    if (!loading) {
      setEditing(false);
    }
  }, [loading]);

  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { term, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.2 : 1;
  drag(drop(ref));

  return (
    <Box
      ref={preview}
      sx={{
        pr: 4,
        py: 2,
        pl: 8,
        borderBottom: 1,
        borderBottomColor: grey['400'],
        opacity,
        position: 'relative',
      }}
    >
      <Box
        ref={ref}
        sx={{
          backgroundColor: grey['100'],
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: 40,
          cursor: isDragging ? 'grabbing' : 'grab',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <DragHandleOutlined sx={{ fontSize: 30 }} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        {editing ? (
          <>
            <Box sx={{ pr: 1, width: '60%' }}>
              <TextField
                value={name}
                onChange={ev => setName(ev.target.value)}
                label={'Description'}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <IconButton
                      size={'small'}
                      onClick={() => setEditing(false)}
                    >
                      <Close />
                    </IconButton>
                  ),
                }}
              />
            </Box>
            <Box sx={{ pl: 1 }}>
              <FormControlLabel
                label={'Is "Other" Field'}
                control={<Checkbox checked={isOther} />}
                onChange={(event, checked) => setIsOther(checked)}
              />{' '}
            </Box>
          </>
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6">
              {name} {isOther ? ' ["Other" field]' : ''}
            </Typography>
            <IconButton size="small" onClick={() => setEditing(true)}>
              <Edit />
            </IconButton>
          </Box>
        )}{' '}
        <IconButton
          color={'error'}
          size={'small'}
          onClick={() => onDelete(index)}
        >
          <Close />
        </IconButton>
      </Box>
    </Box>
  );
}
