/**
 *
 * TaxonomyField
 *
 */
import * as React from 'react';
import {
  ITaxonomyTerm,
  ITaxonomyVocabulary,
} from '../../containers/App/slice/types';
import { FieldProps, FormikProps } from 'formik';
import { findIndex, get } from 'lodash';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Autocomplete } from '@mui/lab';
import key from 'weak-key';
import {
  HTMLAttributes,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { IVoiceService } from '../../containers/People/slice/types';

interface Props
  extends Pick<HTMLAttributes<HTMLDivElement>, 'className'>,
    FieldProps {
  label?: ReactNode;
  form: FormikProps<any>;
  serviceId: number;
  includeRate?: boolean;
  itemsPerRow?: 1 | 2 | 3 | 4 | 6;
}

const levels = ['Very experienced', 'Experienced', 'No experience'];

export function VoiceServiceField(props: Props) {
  const {
    label,
    serviceId,
    itemsPerRow = 1,
    field,
    form,
    className,
    includeRate = false,
    ...rProps
  } = props;

  const serviceKey = `voice-service-${serviceId}`;
  const service: IVoiceService = field.value[serviceKey];

  const { level, rate = '' } = service;

  const setLevelValue = useCallback(
    (level: string) => {
      form.setFieldValue(
        field.name,
        {
          ...field.value,
          [serviceKey]: { ...field.value[serviceKey], level, rate },
        },
        true,
      );
    },
    [form, level, rate],
  );

  const setRateValue = useCallback(
    (rate: string) => {
      form.setFieldValue(field.name, {
        ...field.value,
        [serviceKey]: { ...field.value[serviceKey], level, rate },
      });
    },
    [form, level, rate],
  );

  if (!service) return null;

  const errors: { level?: string; rate?: string } = get(
    form.errors[field.name],
    serviceKey,
    {},
  );

  if (form) {
  }

  return (
    <>
      <Box className={className}>
        <Typography variant="subtitle2">{label || service.name}</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={includeRate ? 6 : 12}>
            <Select
              error={!!errors.level}
              displayEmpty
              renderValue={value =>
                value !== '' ? value : 'Select experience level'
              }
              fullWidth
              value={level}
              onChange={ev => {
                setLevelValue(ev.target.value || '');
              }}
            >
              {levels.map(l => (
                <MenuItem key={`level-${l}`} value={l}>
                  {l}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          {!!includeRate && (
            <Grid item xs={12} sm={6}>
              <TextField
                error={!!errors.rate}
                placeholder={'Enter service rate'}
                value={rate}
                onChange={ev => setRateValue(ev.target.value)}
                fullWidth
              />
            </Grid>
          )}
        </Grid>
        {!!form.errors[`${field.name}.${serviceKey}`] && (
          <Box>ERROR ON TYHIS FIELD</Box>
        )}
      </Box>
    </>
  );
}
