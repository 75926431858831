/**
 *
 * PersonForm
 *
 */

import React from 'react';
import { Field, Form, Formik } from 'formik';
import { Box, Button, Grid, Skeleton } from '@mui/material';
import { Link } from 'react-router-dom';
import { CheckboxWithLabel } from 'formik-mui';
import clsx from 'clsx';
import Yup from 'utils/yup';
import { IPerson, PersonApiCall } from './slice/types';
import { FormBoxContainer } from 'styles/theme/themes';
import { useSelector } from 'react-redux';
import { selectAppSettings } from '../App/slice/selectors';
import { VoiceServiceField } from '../../components/VoiceServiceField';
import key from 'weak-key';

const schema = Yup.object().shape({
  tvg_rates: Yup.boolean(),
  /*
  voice_services: Yup.lazy(details => {
    return Yup.object(
      Object.fromEntries(
        Object.keys(details).map(key => [
          key,
          Yup.object({
            level: Yup.string().required(),
            rate: Yup.string()
              .test('rate', 'Rate is required', (value, context) => {
                // @ts-ignore
                const tvg_rates = context.from[2].value.tvg_rates;
                // @ts-ignore
                const has_rate = context.from[1].value[key].has_rate;
                return tvg_rates || value || !has_rate;
              })
              .nullable(),
          }),
        ]),
      ),
    );
  }),

   */
});

interface PersonFormProps {
  loadPerson: PersonApiCall;
  savePerson: PersonApiCall;
  onSave: (data: IPerson) => void;
}

export function VoiceServicesForm(props: PersonFormProps) {
  const { loadPerson, savePerson, onSave } = props;
  const settings = useSelector(selectAppSettings);

  const data: Partial<IPerson> = {
    id: loadPerson.data ? loadPerson.data.id : null,
    tvg_rates: loadPerson.data ? loadPerson.data.tvg_rates : false,
    voice_services: loadPerson.data ? loadPerson.data.voice_services : {},
  };

  if (loadPerson.loading && !loadPerson.data)
    return (
      <Box sx={{ my: 4, height: 400 }}>
        <Skeleton height={400} variant={'rectangular'} />
      </Box>
    );

  return (
    <FormBoxContainer>
      <Box sx={{ my: 2, p: 2 }}>
        <Formik
          initialValues={data}
          enableReinitialize
          validationSchema={schema}
          validateOnChange
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            onSave(values as IPerson);
          }}
        >
          {({
            setFieldError,
            errors,
            values,
            submitForm,
            isSubmitting,
            setSubmitting,
          }) => {
            if (isSubmitting && !(loadPerson.loading || savePerson.loading)) {
              setSubmitting(false);
            }
            if (!isSubmitting && (loadPerson.loading || savePerson.loading)) {
              setSubmitting(true);
            }

            return (
              <Form>
                <Box className={clsx('has-actions')}>
                  <Box className={'formContent'}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Field
                          name="tvg_rates"
                          type="checkbox"
                          Label={{ label: 'TVG Rates?' }}
                          component={CheckboxWithLabel}
                        />
                      </Grid>

                      {Object.keys(data.voice_services || {}).map(k => (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          key={`voice-service-field-${k}`}
                        >
                          <Field
                            includeRate={
                              !values.tvg_rates &&
                              (data.voice_services || {})[k].has_rate
                            }
                            component={VoiceServiceField}
                            name={'voice_services'}
                            label={(data.voice_services || {})[k].name}
                            serviceId={(data.voice_services || {})[k].id}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                  <Box className={'formActions'} sx={{ mt: 4 }}>
                    <Button
                      variant="text"
                      size="small"
                      component={Link}
                      to="/portal/people"
                    >
                      Cancel
                    </Button>
                    <Button
                      className="align-right"
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      Save {values.id ? 'Changes' : 'New Person'}
                    </Button>
                  </Box>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </FormBoxContainer>
  );
}
