import { call, cancelled, put, takeLatest } from 'redux-saga/effects';
import { apiRequest } from 'utils/request';
import { previewPageActions as actions } from '.';

function* load(action) {
  try {
    const payload = yield call(apiRequest, {
      url: `preview/${action.payload}`,
      method: 'get',
      data: {},
    });

    yield put(actions.loadSuccess(payload.data));
  } catch (error) {
    yield put(actions.loadFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

export function* previewPageSaga() {
  yield takeLatest(actions.loadRequest.type, load);
}
