import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { addToPreviewPageSaga } from './saga';
import { AddToPreviewPageState } from './types';
import { apiCallInitialState } from '../../../../types/ApiCall';
import { ResponseErrorPayload } from '../../../../utils/request';
import { IPreviewPage } from '../../PreviewPages/slice/types';
import { IPerson } from '../../People/slice/types';

export const initialState: AddToPreviewPageState = {
  open: false,
  load: {
    ...apiCallInitialState,
    data: [],
    people: [],
  },
  add: apiCallInitialState,
};

const slice = createSlice({
  name: 'addToPreviewPage',
  initialState,
  reducers: {
    setOpen(state, action: PayloadAction<boolean>) {
      state.open = action.payload;
    },
    setNewPage(state, action: PayloadAction<boolean>) {
      state.newPage = action.payload;
    },
    setPageId(state, action: PayloadAction<number | undefined>) {
      state.pageId = action.payload;
    },
    setPageName(state, action: PayloadAction<string>) {
      state.pageName = action.payload;
    },
    setGoToPage(state, action: PayloadAction<boolean>) {
      state.goToPage = action.payload;
    },
    loadRequest(state, action: PayloadAction<{ ids: Array<number | string> }>) {
      state.load.loading = true;
      state.load.error = undefined;
    },
    loadSuccess(
      state,
      action: PayloadAction<{ data: IPreviewPage[]; people: IPerson[] }>,
    ) {
      state.load.loading = false;
      state.load.data = action.payload.data;
      state.load.people = action.payload.people;
      state.load.error = undefined;
    },
    loadFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.load.loading = false;
      state.load.error = action.payload;
    },
    addRequest(
      state,
      action: PayloadAction<{
        ids: Array<number | string>;
        pageId?: number;
        pageName?: string;
        goToPage?: boolean;
      }>,
    ) {
      state.add.loading = true;
      state.add.error = undefined;
      state.goToPage = !!action.payload.goToPage;
    },
    addSuccess(state, action: PayloadAction<any>) {
      state.add.loading = false;
      state.add.data = action.payload;
      state.add.error = undefined;
    },
    addFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.add.loading = false;
      state.add.error = action.payload;
    },
  },
});

export const { actions: addToPreviewPageActions } = slice;

export const useAddToPreviewPageSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: addToPreviewPageSaga });
  return { actions: slice.actions };
};
