import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) =>
  state.previewPagesSlice || initialState;

export const selectPreviewPagesSlice = createSelector(
  [selectSlice],
  state => state,
);
export const selectPreviewPages = createSelector(
  [selectSlice],
  state => state.previewPages,
);

export const selectPreviewPage = createSelector(
  [selectSlice],
  state => state.previewPage,
);

export const selectSavePreviewPage = createSelector(
  [selectSlice],
  state => state.save,
);

export const selectUpdatePeople = createSelector(
  [selectSlice],
  state => state.updatePeople,
);

export const selectDeletePages = createSelector(
  [selectSlice],
  state => state.delete,
);

export const selectChangeField = createSelector(
  [selectSlice],
  state => state.changeField,
);
