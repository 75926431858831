/* --- STATE --- */

import { ApiCall, UploadApiCall } from 'types/ApiCall';
import { IPerson } from '../../People/slice/types';

export interface PreviewPagesSliceState {
  previewPages: PreviewPagesApiCall;
  previewPage: PreviewPageApiCall;
  save: SavePreviewPageApiCall;
  delete: ApiCall;
  changeField: ApiCall;
  updatePeople: UpdatePeopleApiCall;
}

export interface IPreviewPagePerson extends Partial<IPerson> {
  pivot: {
    settings: {
      sound_files: Array<number>;
    };
    order: number;
  };

  [key: string]: any;
}

export interface IPreviewPage {
  id: number | null;
  uuid?: string;
  reference: string;
  description: string;
  is_white_labelled: boolean;
  white_label_logo_url?: string;
  people: Array<IPreviewPagePerson>;
  metadata: any;

  [key: string]: any;
}

export interface PreviewPagesApiCall extends ApiCall {
  data: Array<IPreviewPage>;
}

export interface PreviewPageApiCall extends ApiCall {
  data: IPreviewPage | undefined;
}

export interface SavePreviewPageApiCall extends UploadApiCall {
  data: IPreviewPage | undefined;
}

export interface UpdatePeopleApiCall extends ApiCall {}

export const ItemTypes = {
  CARD: 'card',
  //
};
