import {
  useInjectReducer,
  useInjectSaga,
} from '../../../../utils/redux-injectors';
import { peopleSliceSaga } from './saga';
import { slice } from '.';

export const usePeopleSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: peopleSliceSaga });
  return { actions: slice.actions };
};
