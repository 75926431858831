import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import {
  apiCallInitialState,
  ResultMetaData,
  uploadApiCallInitialState,
} from 'types/ApiCall';
import { ResponseErrorPayload } from 'utils/request';
import { taxonomiesSliceSaga } from './saga';
import { TaxonomiesSliceState, ITaxonomy } from './types';

export const initialState: TaxonomiesSliceState = {
  taxonomies: {
    loading: false,
    data: [],
  },
  save: uploadApiCallInitialState,
  delete: apiCallInitialState,
  changeField: apiCallInitialState,
  taxonomy: {
    loading: false,
    data: undefined,
  },
  updateTerms: apiCallInitialState,
};

interface UpdateTermsRequest {
  remove: Array<number>;
  update: Array<{
    on_website: boolean;
    order: number;
    description: string;
    [key: string]: any;
  }>;
  create: Array<{
    contents: any;
    filename: string;
  }>;
}

const slice = createSlice({
  name: 'taxonomiesSlice',
  initialState,
  reducers: {
    loadTaxonomiesDataRequest(
      state,
      action: PayloadAction<{ [key: string]: any }>,
    ) {
      state.taxonomies.loading = true;
      state.taxonomies.error = undefined;
    },
    loadTaxonomiesDataSuccess(
      state,
      action: PayloadAction<{
        data: Array<ITaxonomy>;
        meta: ResultMetaData;
      }>,
    ) {
      state.taxonomies.loading = false;
      state.taxonomies.data = action.payload.data;
      state.taxonomies.meta = action.payload.meta;
    },
    loadTaxonomiesDataFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.taxonomies.loading = false;
      state.taxonomies.error = action.payload;
    },
    loadTaxonomiesRequest(
      state,
      action: PayloadAction<{ [key: string]: any }>,
    ) {
      state.taxonomies.loading = true;
      state.taxonomies.error = undefined;
    },
    loadTaxonomiesSuccess(
      state,
      action: PayloadAction<{
        data: Array<ITaxonomy>;
        meta: ResultMetaData;
      }>,
    ) {
      state.taxonomies.loading = false;
      state.taxonomies.data = action.payload.data;
      state.taxonomies.meta = action.payload.meta;
    },
    loadTaxonomiesFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.taxonomies.loading = false;
      state.taxonomies.error = action.payload;
    },
    loadTaxonomyRequest(state, action: PayloadAction<{ id: number | 'new' }>) {
      state.taxonomy.loading = true;
      state.taxonomy.error = undefined;
    },
    loadTaxonomyUnmount(state, action: PayloadAction<void>) {
      state.taxonomy.loading = false;
      state.taxonomy.error = undefined;
      state.taxonomy.data = undefined;
      state.save.loading = false;
      state.save.error = undefined;
      state.save.data = undefined;
    },
    loadTaxonomySuccess(
      state,
      action: PayloadAction<{
        data: ITaxonomy;
      }>,
    ) {
      state.taxonomy.loading = false;
      state.taxonomy.data = action.payload.data;
    },
    loadTaxonomyFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.taxonomy.loading = false;
      state.taxonomy.error = action.payload;
    },
    deleteTaxonomyRequest(state, action: PayloadAction<number[]>) {
      state.delete.loading = true;
      state.delete.error = undefined;
    },
    deleteTaxonomySuccess(
      state,
      action: PayloadAction<{
        status: 'success';
      }>,
    ) {
      state.delete.loading = false;
      state.delete.data = action.payload.status;
    },
    deleteTaxonomyFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.delete.loading = false;
      state.delete.error = action.payload;
    },
    changeFieldRequest(
      state,
      action: PayloadAction<
        {
          id: number;
          field_name: string;
          field_value: string | number | boolean;
        }[]
      >,
    ) {
      state.changeField.loading = true;
      state.changeField.error = undefined;
    },
    changeFieldSuccess(
      state,
      action: PayloadAction<{
        status: 'success';
      }>,
    ) {
      state.changeField.loading = false;
      state.changeField.data = action.payload.status;
    },
    changeFieldFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.changeField.loading = false;
      state.changeField.error = action.payload;
    },
    saveTaxonomyRequest(state, action: PayloadAction<{ [key: string]: any }>) {
      state.save.loading = true;
      state.save.progress = 0;
      state.save.error = undefined;
    },
    saveTaxonomyUnmount(state, action: PayloadAction<void>) {
      state.save.loading = true;
      state.save.error = undefined;
      state.save.data = undefined;
    },
    saveTaxonomySuccess(state, action: PayloadAction<{ data: ITaxonomy }>) {
      state.save.loading = false;
      state.save.data = action.payload.data;
    },
    saveTaxonomyProgress(state, action: PayloadAction<{ progress: number }>) {
      state.save.progress = action.payload.progress;
    },
    saveTaxonomyFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.save.loading = false;
      state.save.progress = 0;
      state.save.error = action.payload;
    },
    updateTermsRequest(state, action: PayloadAction<UpdateTermsRequest>) {
      state.updateTerms.loading = true;
      state.updateTerms.error = undefined;
    },
    updateTermsSuccess(state, action: PayloadAction<{ file: File }>) {
      state.updateTerms.loading = false;
      state.updateTerms.data = action.payload;
      state.updateTerms.error = undefined;
    },
    updateTermsFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.updateTerms.loading = false;
      state.updateTerms.error = action.payload;
    },
    updateTermsReset(state, action: PayloadAction<void>) {
      state.updateTerms = uploadApiCallInitialState;
    },
  },
});

export const { actions: taxonomiesSliceActions } = slice;

export const useTaxonomiesSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: taxonomiesSliceSaga });
  return { actions: slice.actions };
};
