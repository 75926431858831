/**
 *
 * EmailPeople
 *
 */
import * as React from 'react';
import { ReactNode, useEffect, useMemo } from 'react';
import { useEmailPeopleSlice } from './slice';
import { IPerson } from '../People/slice/types';
import {
  Box,
  Button,
  Dialog,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { DialogProps } from '@mui/material/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { selectEmailPeople } from './slice/selectors';
import { useNavigate } from 'react-router-dom';
import key from 'weak-key';
import { LoadingIndicator } from '../../components/LoadingIndicator';

interface Props {
  ids: (string | number)[];
  title?: ReactNode;
  dialogProps?: DialogProps;
}

export function EmailPeople(props: Props) {
  const { ids, title = <>Send email to selected</>, dialogProps = {} } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { actions } = useEmailPeopleSlice();
  const { load, open, optionIndex } = useSelector(selectEmailPeople);

  const onClose = () => {
    dispatch(actions.setOpen(false));
  };
  const onCancel = () => {
    dispatch(actions.setOpen(false));
  };

  useEffect(() => {
    if (open) dispatch(actions.loadRequest({ ids }));
  }, [open]);

  const people = useMemo(() => {
    return load.people;
  }, [load.data, ids]);

  const url = useMemo(() => {
    if (optionIndex === undefined) return;

    const option = load.data.options[+optionIndex]!;
    const subject = encodeURIComponent(option.subject);
    const body = encodeURIComponent(option.body);

    const bcc = people
      .map(p => p.email)
      .filter(e => !!e)
      .join(';');
    return `mailto:${option.to}?subject=${subject}&body=${body}&bcc=${bcc}`;
  }, [people, load.data, optionIndex]);

  return (
    <Dialog fullWidth {...dialogProps} open={!!open} onClose={onClose}>
      {title && <DialogTitle>{title}</DialogTitle>}

      <DialogContent>
        {load.loading && <LoadingIndicator />}
        {!load.loading && people.length > 0 && (
          <Stack spacing={2}>
            <DialogContentText sx={{ mb: 2 }}>
              {people.length > 1
                ? `Emailing ${people.length} people.`
                : 'Emailing selected person.'}
            </DialogContentText>
            <DialogContentText>
              Click the link below to open your email client.
            </DialogContentText>
            <Box>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                value={optionIndex}
                onChange={event =>
                  dispatch(actions.setOptionIndex(+event.target.value))
                }
              >
                {load.data.options.map((option, index) => (
                  <FormControlLabel
                    value={index}
                    key={key(option)}
                    control={<Radio />}
                    label={option.subject}
                  />
                ))}
              </RadioGroup>
            </Box>

            <Box>
              <Button
                component={'a'}
                variant={'outlined'}
                href={url}
                target={'_blank'}
              >
                Click here to open email client
              </Button>
            </Box>
          </Stack>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-end' }}></DialogActions>
    </Dialog>
  );
}
