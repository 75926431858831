import * as React from 'react';
import { Hidden, TextField, useTheme } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import moment from 'moment';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import { useDebounce } from '../../../utils/useDebounce';

interface Props {
  label: string;
  onChange: Function;
  defaultValue?: Date;
  dateProps?: {
    minDate?: Date;
    maxDate?: Date;
  };
}

export function ResponsiveDatePicker(props: Props) {
  const [error, setError] = React.useState<string | null>(null);
  const [value, setValue] = React.useState<Date | null>(
    props.defaultValue || null,
  );
  const theme = useTheme();

  React.useEffect(() => {
    if (props.defaultValue !== value) {
      setValue(props.defaultValue || null);
    }
  }, [props.defaultValue]);

  const debouncedValue = useDebounce(value, 300);

  React.useEffect(() => {
    if (error) return;
    const v = value ? moment(value).format('YYYY-MM-DD') : null;
    props.onChange(v);
  }, [debouncedValue]);

  const pickerProps = {
    onError: e => {
      setError(e);
    },
    inputFormat: 'dd/MM/yyyy',
    label: props.label,
    value,
    onChange: (newValue, selectionState) => {
      setValue(newValue || null);
    },
    renderInput: params => <TextField fullWidth {...params} />,
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div style={{ width: '100%' }}>
        <Hidden smUp>
          <MobileDatePicker
            {...(props.dateProps || {})}
            {...pickerProps}
            clearable
          />
        </Hidden>
        <Hidden smDown>
          <DatePicker
            {...(props.dateProps || {})}
            {...pickerProps}
            views={['year', 'month', 'day']}
          />
        </Hidden>
      </div>
    </LocalizationProvider>
  );
}
