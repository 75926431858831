/**
 *
 * Filename
 *
 */
import * as React from 'react';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { Box, BoxProps, Stack, Typography } from '@mui/material';

interface Props extends BoxProps {
  name: string;
  extension: string;
}

export function Filename(props: Props) {
  const { name, extension, ...rProps } = props;
  return (
    <Box {...rProps}>
      <Stack direction={'row'} alignItems={'center'} spacing={1}>
        <Box sx={{ width: 20 }}>
          {/* @ts-ignore */}
          <FileIcon
            extension={extension}
            {...defaultStyles[extension as keyof typeof defaultStyles]}
          ></FileIcon>
        </Box>
        <Typography>{name}</Typography>
      </Stack>
    </Box>
  );
}
