/**
 *
 * PersonForm
 *
 */

import React, { useEffect } from 'react';
import { Badge, Box, Button, Container, Paper, Tab, Tabs } from '@mui/material';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { usePeopleSlice } from './slice/hook';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCloseEditInvitation,
  selectPerson,
  selectSavePerson,
  selectSendEditInvitation,
  selectUpdateSoundFiles,
} from './slice/selectors';
import { useSnackbar } from 'notistack';
import { PersonForm } from './Form';
import { SoundFiles } from './SoundFiles';
import { ChevronLeft } from '@mui/icons-material';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { PersonAttributesForm } from './AttributesForm';
import { EditInvitations } from './EditInvitations';
import { AddToPreviewPage } from '../AddToPreviewPage';
import { IPerson } from './slice/types';
import { addToPreviewPageActions } from '../AddToPreviewPage/slice';
import { grey } from '@mui/material/colors';
import { useHasChanged } from '../../../utils/usePrevious';
import { VoiceServicesForm } from './VoiceServicesForm';
import { Helmet } from 'react-helmet-async';
import useHandleApiResponse from '../../../utils/useHandleApiResponse';

export function Edit() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { actions } = usePeopleSlice();
  const loadPerson = useSelector(selectPerson);
  const savePerson = useSelector(selectSavePerson);
  const sendEditInvitation = useSelector(selectSendEditInvitation);
  const closeEditInvitation = useSelector(selectCloseEditInvitation);
  const updateSoundFiles = useSelector(selectUpdateSoundFiles);
  const { enqueueSnackbar } = useSnackbar();

  const params = useParams<{
    id: string;
    section?: string;
  }>();

  const basePath = '/portal/people';

  useEffect(() => {
    if (params.id === 'new') return;
    load();
    return () => {
      dispatch(actions.loadPersonUnmount());
    };
  }, []);

  useHandleApiResponse(loadPerson, {
    errorMessage: 'Error loading person.',
  });

  useHandleApiResponse(savePerson, {
    successMessage: 'Person saved.',
    onSuccess: () => {
      if (params.id === 'new') {
        navigate(`/portal/people/${savePerson.data?.id}`, { replace: true });
      } else {
        load();
      }
    },
  });

  const updateSoundFilesLoadingChanged = useHasChanged(
    updateSoundFiles.loading,
  );
  const sendEditInvitationLoadingChanged = useHasChanged(
    sendEditInvitation.loading,
  );
  const closeEditInvitationLoadingChanged = useHasChanged(
    closeEditInvitation.loading,
  );
  const matchParamsIdChanged = useHasChanged(params.id);

  useEffect(() => {
    if (
      (updateSoundFilesLoadingChanged && !updateSoundFiles.loading) ||
      (sendEditInvitationLoadingChanged && !sendEditInvitation.loading) ||
      (closeEditInvitationLoadingChanged && !closeEditInvitation.loading) ||
      (matchParamsIdChanged && params.id !== 'new')
    ) {
      load();
    }
  });

  const load = () => {
    const id = params.id === 'new' ? 'new' : parseInt(params.id || '');
    dispatch(actions.loadPersonRequest({ id }));
  };

  if (params.id !== 'new' && !loadPerson.data) return <LoadingIndicator />;

  return (
    <>
      <Helmet>
        <title>
          {loadPerson.data
            ? `${loadPerson.data.first_name} ${loadPerson.data.last_name}`
            : 'Create new'}
          - Voice Artist
        </title>
      </Helmet>
      <Box>
        <Paper
          square
          sx={{
            backgroundColor: 'secondary.dark',
            color: 'white',
            py: 1,
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ flex: '0 0 20%', pl: 1 }}>
            <Button
              variant="text"
              startIcon={<ChevronLeft />}
              sx={{ marginLeft: 'auto' }}
              component={Link}
              to={basePath}
              color={'inherit'}
              size={'small'}
            >
              Back to people
            </Button>
          </Box>

          <Box sx={{ flex: '0 0 20%', textAlign: 'right', pr: 1 }} />
        </Paper>
        <Container sx={{ my: 4 }} maxWidth={'xl'}>
          <Paper>
            <Box sx={{ px: 1, backgroundColor: grey['200'] }}>
              <Tabs value={params.section || ''} variant={'scrollable'}>
                <Tab
                  value={''}
                  component={Link}
                  to={`${basePath}/${params.id}`}
                  label={'General'}
                />
                <Tab
                  value={'sound-files'}
                  disabled={params.id === 'new'}
                  component={Link}
                  to={`${basePath}/${params.id}/sound-files`}
                  label={'Sound files'}
                />
                <Tab
                  value={'attributes'}
                  disabled={params.id === 'new'}
                  component={Link}
                  to={`${basePath}/${params.id}/attributes`}
                  label={'Attributes'}
                />
                <Tab
                  value={'voice-services'}
                  disabled={params.id === 'new'}
                  component={Link}
                  to={`${basePath}/${params.id}/voice-services`}
                  label={'Voice Services'}
                />
                <Tab
                  value={'edit-invitations'}
                  disabled={params.id === 'new'}
                  component={Link}
                  to={`${basePath}/${params.id}/edit-invitations`}
                  label={
                    <Badge
                      variant={
                        (loadPerson.data?.edit_invitation || {}).status === 5
                          ? 'standard'
                          : 'dot'
                      }
                      badgeContent={
                        (loadPerson.data?.edit_invitation || {}).status === 5
                          ? '1'
                          : ''
                      }
                      color={
                        (loadPerson.data?.edit_invitation || {}).status === 5
                          ? 'error'
                          : 'success'
                      }
                      invisible={!loadPerson.data?.edit_invitation}
                    >
                      Edit invitations
                    </Badge>
                  }
                />
                <Box sx={{ ml: 'auto', display: 'flex', alignItems: 'center' }}>
                  <Button
                    variant={'contained'}
                    size={'small'}
                    color={'warning'}
                    onClick={() =>
                      dispatch(addToPreviewPageActions.setOpen(true))
                    }
                  >
                    Add to preview page
                  </Button>
                </Box>
              </Tabs>
            </Box>
            {!params.section && (
              <PersonForm
                loadPerson={loadPerson}
                savePerson={savePerson}
                onSave={data => dispatch(actions.savePersonRequest(data))}
              />
            )}
            {params.section === 'sound-files' && (
              <SoundFiles
                updateSoundFiles={{
                  loading: updateSoundFiles.loading,
                  progress: updateSoundFiles.progress,
                }}
                loadPerson={loadPerson}
                onUpdate={data =>
                  dispatch(
                    actions.updateSoundFilesRequest({
                      ...data,
                      id: loadPerson.data?.id,
                    }),
                  )
                }
              />
            )}
            {params.section === 'attributes' && (
              <PersonAttributesForm
                loadPerson={loadPerson}
                savePerson={savePerson}
                onSave={data => dispatch(actions.savePersonRequest(data))}
              />
            )}
            {params.section === 'voice-services' && (
              <VoiceServicesForm
                loadPerson={loadPerson}
                savePerson={savePerson}
                onSave={data => dispatch(actions.savePersonRequest(data))}
              />
            )}
            {params.section === 'edit-invitations' && (
              <EditInvitations
                loadPerson={loadPerson}
                sendEditInvitation={sendEditInvitation}
                closeEditInvitation={closeEditInvitation}
                onSend={() =>
                  dispatch(
                    actions.sendEditInvitationRequest(
                      loadPerson.data?.id ? [loadPerson.data?.id] : [],
                    ),
                  )
                }
                onClose={closeAction =>
                  dispatch(
                    actions.closeEditInvitationRequest({
                      id: loadPerson.data!.id!,
                      closeAction,
                    }),
                  )
                }
              />
            )}
          </Paper>
        </Container>
      </Box>
      {loadPerson.data && <AddToPreviewPage ids={[loadPerson.data.id!]} />}
    </>
  );
}
