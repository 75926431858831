import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.taxonomiesSlice || initialState;

export const selectTaxonomiesSlice = createSelector(
  [selectSlice],
  state => state,
);
export const selectTaxonomies = createSelector(
  [selectSlice],
  state => state.taxonomies,
);

export const selectTaxonomy = createSelector(
  [selectSlice],
  state => state.taxonomy,
);

export const selectSaveTaxonomy = createSelector(
  [selectSlice],
  state => state.save,
);

export const selectUpdateTerms = createSelector(
  [selectSlice],
  state => state.updateTerms,
);

export const selectDeletePages = createSelector(
  [selectSlice],
  state => state.delete,
);

export const selectChangeField = createSelector(
  [selectSlice],
  state => state.changeField,
);
