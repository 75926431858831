import { ResponseErrorPayload } from 'utils/request';

export interface ApiCall<T = any> {
  loading: boolean;
  error?: ResponseErrorPayload;
  data: T;
  meta?: ResultMetaData;
}

export const apiCallInitialState: ApiCall = {
  loading: false,
  error: undefined,
  data: undefined,
};

export interface UploadApiCall extends ApiCall {
  progress: number;
}

export const uploadApiCallInitialState: UploadApiCall = {
  ...apiCallInitialState,
  progress: 0,
};

export interface ResultMetaData {
  current_page: number;
  from: number;
  last_page: number;
  per_page: number;
  to: number;
  total: number;
  [key: string]: any;
}
