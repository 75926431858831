import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { previewPageSaga } from './saga';
import { PreviewPageState } from './types';
import { apiCallInitialState } from '../../../../types/ApiCall';
import { ResponseErrorPayload } from '../../../../utils/request';
import { IPreviewPage } from '../../PreviewPages/slice/types';

export const initialState: PreviewPageState = {
  load: apiCallInitialState,
};

const slice = createSlice({
  name: 'previewPage',
  initialState,
  reducers: {
    loadRequest(state, action: PayloadAction<string>) {
      state.load.loading = true;
      state.load.error = undefined;
    },
    loadSuccess(state, action: PayloadAction<IPreviewPage>) {
      state.load.loading = false;
      state.load.data = action.payload;
    },
    loadFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.load.loading = false;
      state.load.error = action.payload;
    },
  },
});

export const { actions: previewPageActions } = slice;

export const usePreviewPageSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: previewPageSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = usePreviewPageSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
