/**
 *
 * Dashboard
 *
 */
import * as React from 'react';
import { useCallback, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useDashboardSlice } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectDashboardSubmittedUpdates } from './slice/selectors';
import { grey } from '@mui/material/colors';
import { useQuery } from '../../../utils/query';
import { GridSortDirection } from '@mui/x-data-grid-pro';
import { NoResultsMessage } from '../../components/NoResultsMessage';
import key from 'weak-key';

interface Props {}

export function Dashboard(props: Props) {
  const { actions } = useDashboardSlice();
  const dispatch = useDispatch();
  const submittedUpdates = useSelector(selectDashboardSubmittedUpdates);
  const [pageSize, setPageSize] = React.useState<number>(25);
  const [page, setPage] = React.useState<number>(1);
  const [sort, setSort] = React.useState<
    [field: string, direction?: GridSortDirection] | undefined
  >(undefined);

  const load = useCallback(() => {
    dispatch(
      actions.loadRequest({
        'page[number]': Math.max(page - 1, 0),
        'page[size]': pageSize,
        sort: sort,
      }),
    );
  }, [page, dispatch]);

  useEffect(() => {
    load();
  }, [dispatch, page, pageSize]);

  return (
    <Container>
      <Typography sx={{ my: 4 }} variant={'h2'} color={'primary'}>
        Dashboard
      </Typography>
      <Paper sx={{ my: 4, p: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Card variant={'outlined'} sx={{ height: '100%' }}>
              <CardHeader
                title={'Quick links'}
                sx={{
                  borderBottom: 1,
                  borderColor: grey['300'],
                  backgroundColor: 'secondary.main',
                  color: 'white',
                }}
              />
              <CardContent>
                <Typography variant={'body1'}>
                  Please use the links below to access the portal data.
                </Typography>
                <Stack spacing={4} sx={{ my: 4 }}>
                  <Button
                    component={Link}
                    to={'/portal/people'}
                    variant={'outlined'}
                    size={'large'}
                  >
                    Go to people
                  </Button>
                  <Button
                    component={Link}
                    to={'/portal/preview-pages'}
                    variant={'outlined'}
                    size={'large'}
                  >
                    Go to preview pages
                  </Button>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card variant={'outlined'} sx={{ height: '100%' }}>
              <CardHeader
                title={'Recent user updates'}
                sx={{
                  borderBottom: 1,
                  borderColor: grey['300'],
                  backgroundColor: 'secondary.main',
                  color: 'white',
                }}
              />
              <CardContent
                sx={{
                  maxHeight: 'calc(100vh - 300px)',
                  overflowY: 'auto',
                  py: 0,
                }}
              >
                <Stack sx={{}} divider={<Divider />}>
                  {submittedUpdates.data.map(person => (
                    <Box sx={{ py: 2 }} key={key(person)}>
                      <Typography variant={'body2'}>
                        {person.first_name} {person.last_name}
                      </Typography>
                      <Typography variant={'body2'} sx={{ my: 2 }}>
                        Submitted:{' '}
                        {new Date(
                          person.edit_invitation?.submitted_at || 0,
                        ).toLocaleString()}
                      </Typography>
                      <Button
                        component={Link}
                        to={`/portal/people/${person.id}/edit-invitations`}
                        variant={'contained'}
                        size={'small'}
                      >
                        Review update
                      </Button>
                    </Box>
                  ))}
                  {!submittedUpdates.data.length && (
                    <NoResultsMessage
                      message={'No updates currently pending'}
                    />
                  )}
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
