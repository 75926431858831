/**
 *
 * People
 *
 */
import * as React from 'react';
import { useParams } from 'react-router';
import { Route, Routes } from 'react-router-dom';
import { List } from './List';
import { Edit } from './Edit';

interface Props {}

export function People(props: Props) {
  const match = useParams();

  return (
    <Routes>
      <Route path={``} element={<List />} />
      <Route path={`:id/:section?/*`} element={<Edit />} />
    </Routes>
  );
}
