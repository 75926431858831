/**
 *
 * MarkupBlock
 *
 */
import * as React from 'react';
import { HTMLAttributes } from 'react';
import sanitizeHtml from 'sanitize-html';
import clsx from 'clsx';

interface Props extends HTMLAttributes<HTMLSpanElement> {
  markup?: string;
  nl2br?: boolean;
}

export function MarkupBlock(props: Props) {
  const { className, nl2br, ...rest } = props;

  let markup = props.markup || '';
  if (typeof markup !== 'string') return null;

  if (!!props.nl2br) {
    const markupElements = markup.split('\n');
    markup = markupElements
      .map(el => `<span class="nl2br">${el}</span>`)
      .join('<br />');
  }

  return (
    <span
      className={clsx(className)}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: sanitizeHtml(unescape(markup), {
          allowedTags: [
            'h1',
            'h2',
            'h3',
            'h4',
            'h5',
            'h6',
            'blockquote',
            'p',
            'a',
            'ul',
            'ol',
            'nl',
            'li',
            'b',
            'i',
            'span',
            'strong',
            'em',
            'strike',
            'sup',
            'code',
            'hr',
            'br',
            'div',
            'table',
            'thead',
            'caption',
            'tbody',
            'tr',
            'th',
            'td',
            'pre',
            'iframe',
          ],
          allowedAttributes: {
            '*': ['style', 'class', 'href', 'target'],
          },
          allowedStyles: {
            '*': {
              // Match HEX and RGB
              color: [
                /^#(0x)?[0-9a-f]+$/i,
                /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/,
              ],
              'text-align': [/^left$/, /^right$/, /^center$/],
              // Match any number with px, em, or %
              'font-size': [/^\d+(?:px|em|%)$/],
            },
            p: {
              'font-size': [/^\d+rem$/],
            },
          },
        }),
      }}
      {...rest}
    />
  );
}
