import { useRef, useEffect } from 'react';
import { isEqual } from 'lodash';

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
export const useHasChanged = val => {
  const prevVal = usePrevious(val);
  const hasChanged = !isEqual(prevVal, val) && prevVal !== undefined;
  if (hasChanged) {
    // console.log(prevVal, val);
  }
  return hasChanged;
};
