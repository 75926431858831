/**
 *
 * ManagementTable
 *
 */
import * as React from 'react';
import { get, isEqual } from 'lodash';
import { v4 } from 'uuid';
import clsx from 'clsx';
import {
  Hidden,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Box,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  CheckBox,
  CheckBoxOutlineBlank,
  RadioButtonChecked,
  RadioButtonUnchecked,
} from '@mui/icons-material';
import { usePrevious } from 'utils/usePrevious';
import { LoadingIndicator } from '../LoadingIndicator';
import { ErrorBoundary } from 'react-error-boundary';
import { NoResultsMessage } from '../NoResultsMessage';

export interface TableColumn {
  value: string | Function;
  label?: string | React.ReactNode;
  classes?: string;
}

interface Props {
  rows: Array<{ id: string | number; [key: string]: any }>;
  columns: Array<TableColumn>;
  onClick: Function;
  page: number;
  onChangePage: (page: number) => void;
  rowsPerPage: number;
  rowsPerPageOptions?: Array<number>;
  onChangeRowsPerPage: (size: number) => void;
  count: number;
  loading: boolean;
  loadingComponent: any;
  rowClasses?: any;
  noDataMessage?: any;
  enableCheckboxSelection?: boolean;
  disableMultipleSelection?: boolean;
  enableSelectAll?: boolean;
  defaultRowsSelected?: Array<number | string>;
  onSelectionChange?: (ids: Array<string | number>) => void;
  disableHeader?: boolean;
}

export function ManagementTable(props: Props) {
  const {
    rows,
    onClick,
    page,
    onChangePage,
    onChangeRowsPerPage,
    rowsPerPage,
    rowsPerPageOptions = [10, 25, 50],
    count,
    loading,
    loadingComponent,
    noDataMessage,
  } = props;

  const theme = useTheme();

  const rowClasses = props.rowClasses || {};

  const [selectedRows, setSelectedRows] = React.useState<
    Array<number | string>
  >(props.defaultRowsSelected || []);

  const toggleRowSelection = id => {
    const index = selectedRows.indexOf(id);
    if (index === -1) {
      if (!props.disableMultipleSelection || selectedRows.length === 0) {
        setSelectedRows([...selectedRows, id]);
      } else {
        setSelectedRows([id]);
      }
    } else {
      setSelectedRows(
        selectedRows.slice(0, index).concat(selectedRows.slice(index + 1)),
      );
    }
  };

  const oldSelectedRows = usePrevious(selectedRows);
  React.useEffect(() => {
    if (props.onSelectionChange) props.onSelectionChange(selectedRows);
  }, [selectedRows]);

  React.useEffect(() => {
    if (!isEqual(props.defaultRowsSelected, selectedRows)) {
      setSelectedRows(props.defaultRowsSelected || []);
    }
  }, [props.defaultRowsSelected]);

  let checkboxColumn: Array<TableColumn> = [];

  if (props.enableCheckboxSelection) {
    checkboxColumn.push({
      classes: 'narrow-cell',
      value: row => (
        <IconButton onClick={() => toggleRowSelection(row.id)}>
          {selectedRows.indexOf(row.id) !== -1 ? (
            props.disableMultipleSelection ? (
              <RadioButtonChecked />
            ) : (
              <CheckBox />
            )
          ) : props.disableMultipleSelection ? (
            <RadioButtonUnchecked />
          ) : (
            <CheckBoxOutlineBlank />
          )}
        </IconButton>
      ),
    });
  }
  const columns = [...checkboxColumn, ...props.columns];

  return (
    <TableContainer style={{ height: '100%' }}>
      <React.Fragment>
        <Table
          sx={{
            width: '100%',
            [theme.breakpoints.down('md')]: {
              display: 'block',
              width: '100%',
            },
          }}
        >
          {!props.disableHeader && (
            <Hidden mdDown>
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCell
                      key={v4()}
                      className={clsx(column.classes)}
                      sx={{
                        padding: theme.spacing(2),
                        color: theme.palette.grey['600'],
                        fontWeight: 'bold',
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
            </Hidden>
          )}
          <TableBody
            sx={{
              [theme.breakpoints.down('md')]: {
                display: 'block',
                width: '100% !important',
              },
            }}
          >
            {rows.map(row => (
              <TableRow
                key={v4()}
                onClick={() => onClick(row)}
                className={clsx(
                  ...Object.keys(rowClasses).map(keyClass => ({
                    [keyClass]: rowClasses[keyClass](row),
                  })),
                )}
                sx={{
                  [theme.breakpoints.up('md')]: {
                    '& .no-wrap': {
                      whiteSpace: 'nowrap',
                    },
                    '& .align-right': {
                      textAlign: 'right',
                    },
                    '& .narrow-cell': {
                      width: '1px',
                      whiteSpace: 'nowrap',
                      paddingLeft: theme.spacing(0.5),
                      paddingRight: theme.spacing(0.5),
                    },
                    '&.draft': {
                      backgroundColor: theme.palette.grey[100],
                    },
                  },
                  [theme.breakpoints.down('md')]: {
                    display: 'block',
                    width: '100% !important',
                    height: 'auto',
                    padding: `8px 0`,
                    borderBottom: `1px solid ${theme.palette.grey.A100}`,
                  },
                }}
                hover
              >
                {columns.map(column => (
                  <TableCell
                    key={v4()}
                    className={clsx(column.classes)}
                    sx={{
                      padding: theme.spacing(2),
                      paddingTop: theme.spacing(0.5),
                      paddingBottom: theme.spacing(0.5),
                      [theme.breakpoints.down('md')]: {
                        display: 'block',
                        width: '100%',
                        border: 'none',
                      },
                    }}
                  >
                    {!!column.label && (
                      <Hidden mdUp>
                        <b>{column.label}:</b>{' '}
                      </Hidden>
                    )}

                    {typeof column.value === 'function' ? (
                      <ErrorBoundary
                        fallback={<>&nbsp;</>}
                        onError={e => {
                          //  console.log(e);
                        }}
                      >
                        {column.value(row) ? column.value(row) : <>&nbsp;</>}
                      </ErrorBoundary>
                    ) : (
                      get(row, column.value, '')
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
            {!rows.length && (
              <TableRow>
                <TableCell colSpan={1000}>{noDataMessage}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {count > rowsPerPage && (
          <TablePagination
            component={'div'}
            page={page}
            onPageChange={(ev, page) => onChangePage(page + 1)}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={event => {
              onChangeRowsPerPage(+event.target.value);
            }}
            rowsPerPageOptions={rowsPerPageOptions}
            count={count}
            showFirstButton
            showLastButton
          />
        )}
      </React.Fragment>
      {!rows.length && <NoResultsMessage message={loading ? '' : undefined} />}
      {loading ? (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            left: 0,
            top: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.6)',
          }}
        >
          {loadingComponent || <LoadingIndicator minHeight={300} />}
        </Box>
      ) : null}
    </TableContainer>
  );
}
