/**
 *
 * AttributeValueFilterField
 *
 */
import * as React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Clear, Edit } from '@mui/icons-material';
import key from 'weak-key';

interface ValuePair {
  id: number | string | undefined | null;
  [key: string]: number | string | undefined | null;
}

interface Props {
  onChange: (values?: ValuePair) => void;
  label: string;
  valueName: string;
  attributeOptions: Array<{ id: number | string; label: string }>;
  valueOptions: Array<number | string>;
  initValues: ValuePair | null | undefined;
}

export function AttributeValueFilterField(props: Props) {
  const {
    initValues,
    valueName,
    attributeOptions,
    valueOptions,
    onChange,
    label,
  } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [values, setValues] = useState<ValuePair>(
    initValues ?? {
      [valueName]: undefined,
      id: undefined,
    },
  );

  useEffect(() => {
    setValues(
      initValues ?? {
        [valueName]: undefined,
        id: undefined,
      },
    );
    setIsOpen(false);
  }, [initValues]);

  const submitValues = (values: ValuePair) => {
    if (values[valueName] && values.id) {
      onChange(values);
    } else {
      onChange();
    }
  };

  let buttonText = 'Set Value';

  if (initValues) {
    const attribute = attributeOptions.find(a => a.id === initValues.id);

    buttonText = `${(attribute || {}).label} to ${initValues[valueName]}`;
  }

  return (
    <Box>
      <Typography variant={'subtitle2'}>{label}</Typography>

      <Grid container spacing={1} alignItems={'center'}>
        <Grid item xs>
          <Button
            color={'secondary'}
            fullWidth
            variant={initValues ? 'contained' : 'outlined'}
            onClick={() => setIsOpen(true)}
            sx={{ textTransform: 'none' }}
            endIcon={<Edit />}
          >
            <Typography variant={'body2'}>{buttonText}</Typography>
          </Button>
        </Grid>

        {!!initValues && (
          <Grid item>
            <IconButton
              color={'error'}
              size={'small'}
              edge={'end'}
              onClick={() =>
                submitValues({ id: undefined, [valueName]: undefined })
              }
            >
              <Clear />
            </IconButton>
          </Grid>
        )}
      </Grid>

      <Dialog
        maxWidth={'xs'}
        fullWidth
        open={isOpen}
        onBackdropClick={() => setIsOpen(false)}
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl sx={{}} fullWidth>
                <InputLabel>Attribute</InputLabel>
                <Select
                  label={'Attribute'}
                  fullWidth
                  value={values.id ?? ''}
                  onChange={ev =>
                    setValues({
                      id: ev.target.value,
                      [valueName]: values[valueName],
                    })
                  }
                >
                  {attributeOptions.map(option => (
                    <MenuItem key={key(option)} value={option.id}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl sx={{}} fullWidth>
                <InputLabel>Value</InputLabel>

                <Select
                  fullWidth
                  label={'Value'}
                  value={values[valueName] ?? ''}
                  onChange={ev => {
                    setValues({ [valueName]: ev.target.value, id: values.id });
                  }}
                >
                  {valueOptions.map(option => (
                    <MenuItem
                      key={`attribute-filter-value-${option}`}
                      value={option}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => submitValues(values)}>Set</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
