/**
 *
 * PersonForm
 *
 */

import React from 'react';
import { Field, Form, Formik } from 'formik';
import {
  alertClasses,
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Skeleton,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { CheckboxWithLabel, TextField } from 'formik-mui';
import clsx from 'clsx';
import Yup from 'utils/yup';
import { IPerson, PersonApiCall, SavePersonApiCall } from './slice/types';
import { FormBoxContainer } from 'styles/theme/themes';
import { TaxonomyField } from '../../components/TaxonomyField';
import { useSelector } from 'react-redux';
import { selectAppSettings } from '../App/slice/selectors';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from 'formik-mui-lab';
import { ImageUploadFormElement } from '../../components/ImageUploadFormElement';
import { getBase64 } from '../../../utils/saga/createFileUploadChannel';
import { Alert } from '@mui/lab';

const schema = Yup.object().shape({
  first_name: Yup.string().required('Required'),
  last_name: Yup.string().required('Required'),
  email: Yup.string().email('Valid email required').required('Required'),
  description: Yup.string(),
});

interface PersonFormProps {
  loadPerson: PersonApiCall;
  savePerson: SavePersonApiCall;
  onSave: (data: IPerson) => void;
}

export function PersonForm(props: PersonFormProps) {
  const { loadPerson, savePerson, onSave } = props;
  const settings = useSelector(selectAppSettings);

  const data: Partial<IPerson> = {
    id: null,
    uuid: '',
    email: '',
    first_name: '',
    last_name: '',
    description: '',
    dob: '',
    date_joined: null,
    attributes: loadPerson.data ? loadPerson.data.metadata.attributes : {},
    active: true,
    on_website: true,
    meta_title: '',
    meta_description: '',
    slug: '',
    no_index: true,
    is_child: false,
    ...(loadPerson.data || {}),
  };

  if (loadPerson.loading && !loadPerson.data)
    return (
      <Box sx={{ my: 4, height: 400 }}>
        <Skeleton height={400} variant={'rectangular'} />
      </Box>
    );
  return (
    <FormBoxContainer>
      <Box sx={{ my: 2, p: 2 }}>
        <Formik
          initialValues={data}
          enableReinitialize
          validationSchema={schema}
          validateOnChange
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            onSave(values as IPerson);
          }}
        >
          {({
            errors,
            values,
            submitForm,
            isSubmitting,
            setSubmitting,
            setFieldValue,
          }) => {
            if (isSubmitting && !(loadPerson.loading || savePerson.loading)) {
              setSubmitting(false);
            }
            if (!isSubmitting && (loadPerson.loading || savePerson.loading)) {
              setSubmitting(true);
            }

            return (
              <Form>
                <Box className={clsx('has-actions')}>
                  <Box className={'formContent'}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4}>
                        <Field
                          component={TextField}
                          id="first_name"
                          name="first_name"
                          type="text"
                          label="First Name"
                          fullWidth
                          className={'field'}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Field
                          component={TextField}
                          id="last_name"
                          name="last_name"
                          type="text"
                          label="Last Name"
                          fullWidth
                          className={'field'}
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <Field
                          component={TextField}
                          id="country_code"
                          name="country_code"
                          type="text"
                          label="Country Code"
                          fullWidth
                          className={'field'}
                          maxLength={10}
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <Field
                          component={TextField}
                          id="date_joined"
                          name="date_joined"
                          type="date"
                          InputLabelProps={{ shrink: true }}
                          label="Date Joined TVG"
                          fullWidth
                          inputFormat="dd/MM/yyyy"
                          className={'field'}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          id="email"
                          name="email"
                          type="email"
                          label="Email Address"
                          fullWidth
                          className={'field'}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <Field
                            component={TextField}
                            id="dob"
                            name="dob"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            label="D.O.B."
                            fullWidth
                            inputFormat="dd/MM/yyyy"
                            className={'field'}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Field
                          name="is_child"
                          type="checkbox"
                          Label={{ label: 'TVG Kids / is child? ' }}
                          component={CheckboxWithLabel}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box sx={{ width: '100%' }}>
                          <Alert
                            icon={false}
                            sx={{
                              width: '100%',
                              [`& .${alertClasses.message}`]: {
                                width: '100%',
                              },
                            }}
                            severity={values.alert_text ? 'error' : 'info'}
                          >
                            <Field
                              component={TextField}
                              multiline
                              rows={2}
                              id="alert_text"
                              name="alert_text"
                              type="text"
                              label="Alert (Leave blank if none)"
                              fullWidth
                              className={'field'}
                            />
                          </Alert>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box sx={{ width: '100%' }}>
                          <Field
                            component={TextField}
                            multiline
                            rows={3}
                            id="access_rider_text"
                            name="access_rider_text"
                            type="text"
                            label="Access rider details"
                            fullWidth
                            className={'field'}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={4}>
                          <Grid item xs={12} sm={6} md={9}>
                            <Field
                              component={TextField}
                              id="description"
                              multiline
                              rows={10}
                              name="description"
                              type="description"
                              label="Description"
                              fullWidth
                              className={'field'}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <ImageUploadFormElement
                              logo_url={
                                loadPerson.data?.photo_id
                                  ? loadPerson.data?.photo_url
                                  : undefined
                              }
                              imageBoxProps={{
                                sx: { backgroundSize: 'cover', width: 150 },
                              }}
                              title={
                                <>
                                  Upload recent <br /> B/W headshot
                                </>
                              }
                              loading={savePerson.loading}
                              progress={savePerson.progress}
                              onFileChange={file => {
                                if (file) {
                                  getBase64(file).then(contents => {
                                    setFieldValue('photo_action', {
                                      type: 'new',
                                      data: {
                                        contents,
                                        filename: file.name,
                                        filesize: file.size,
                                        filetype: file.type,
                                      },
                                    });
                                  });
                                } else {
                                  setFieldValue('photo_action', {
                                    type: 'remove',
                                  });
                                }
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Divider variant={'fullWidth'} sx={{ my: 3 }} />

                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          id="meta_title"
                          name="meta_title"
                          type="text"
                          label="Meta title"
                          fullWidth
                          className={'field'}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          id="slug"
                          name="slug"
                          type="text"
                          label="URL slug"
                          fullWidth
                          className={'field'}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          multiline
                          rows={2}
                          id="meta_description"
                          name="meta_description"
                          type="text"
                          label="Meta description"
                          fullWidth
                          className={'field'}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Field
                          name="active"
                          type="checkbox"
                          Label={{ label: 'Active' }}
                          component={CheckboxWithLabel}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Field
                          name="on_website"
                          type="checkbox"
                          Label={{ label: 'On website' }}
                          component={CheckboxWithLabel}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Field
                          name="no_index"
                          type="checkbox"
                          Label={{ label: 'No index' }}
                          component={CheckboxWithLabel}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className={'formActions'} sx={{ mt: 4 }}>
                    <Button
                      variant="text"
                      size="small"
                      component={Link}
                      to="/portal/people"
                    >
                      Cancel
                    </Button>
                    <Button
                      className="align-right"
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      Save {values.id ? 'Changes' : 'New Person'}
                    </Button>
                  </Box>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </FormBoxContainer>
  );
}
