// generic formik form for legal documents

// Path: src/app/containers/LegalDocuments/Form.tsx

import React from 'react';
import { Formik, Form as FormikForm, Field } from 'formik';
import FileUpload from '../../components/FileUpload';
import { Box, Button, IconButton, Stack } from '@mui/material';
import { TextField } from 'formik-mui';
import { Filename } from '../../components/Filename';
import { Cancel, Edit } from '@mui/icons-material';

interface FormInterface {
  code: string;
  file?: File;
  name?: string;
}

interface Props {
  code: string;
  name: string;
  data?: {
    file_id?: string;
    file_url?: string;
    file_name?: string;
    file_type?: string;
    file_size?: number;
    code: string;
    name: string;
    [key: string]: any;
  };
  updateItem: (values: any) => void;
}

export function Form(props: Props) {
  const { code, name, data, updateItem } = props;

  const [showFile, setShowFile] = React.useState<boolean>(!!data?.file_id);
  console.log(data);

  return (
    <Formik<FormInterface>
      enableReinitialize
      initialValues={{
        ...data,
        code,
        name: data?.name || name,
      }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        let file_action: undefined | 'new' | 'remove' = undefined;
        if (data?.file_id && !values.file && !showFile) {
          file_action = 'remove';
        }
        if (values.file) {
          file_action = 'new';
        }
        updateItem({
          code: values.code,
          name: values.name,
          file_action: {
            type: file_action,
            data: values.file,
          },
        });
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        dirty,
      }) => {
        return (
          <FormikForm>
            <Stack spacing={4}>
              <Field component={TextField} name={'name'} label={'Name'} />

              {showFile ? (
                <Stack direction={'row'} spacing={2}>
                  <Filename
                    name={data?.file_name!}
                    extension={data?.file_name?.split('.').pop() || ''}
                  />
                  <IconButton onClick={() => setShowFile(false)}>
                    <Edit />
                  </IconButton>
                </Stack>
              ) : (
                <Stack direction={'row'} spacing={2}>
                  <FileUpload
                    name={'file'}
                    type={'File'}
                    allowedFileTypes={['*']}
                  />
                  {data?.file_id && (
                    <IconButton
                      onClick={() => {
                        setShowFile(true);
                      }}
                    >
                      <Cancel />
                    </IconButton>
                  )}
                </Stack>
              )}
              <Box>
                <Button
                  variant={'outlined'}
                  type="submit"
                  disabled={isSubmitting || (!dirty && showFile)}
                >
                  Save changes
                </Button>
              </Box>
            </Stack>
          </FormikForm>
        );
      }}
    </Formik>
  );
}
