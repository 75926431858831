/**
 *
 * Asynchronously loads the component for UpdateDetailsPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const UpdateDetailsPage = lazyLoad(
  () => import('./index'),
  module => module.UpdateDetailsPage,
);
