/**
 *
 * AccountSettings
 *
 */
import * as React from 'react';
import { Box, Button, Paper, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import clsx from 'clsx';
import { FormBoxContainer } from 'styles/theme/themes';
import { PasswordField } from 'app/components/PasswordField';
import Yup from 'utils/yup';
import { useAccountSettingsSlice } from './slice';
import { selectAccountSettings, selectChangePassword } from './slice/selectors';
import { useSnackbar } from 'notistack';
import { selectAuthProvider } from '../AuthProvider/slice/selectors';
import { useConfirm } from '../../components/ConfirmDialog/index';
import { useEffectOnChange } from '../../../utils/useEffectOnChange';

interface Props {}

export function Reset2FA(props: Props) {
  const dispatch = useDispatch();
  const { actions } = useAccountSettingsSlice();
  const { loadUser } = useSelector(selectAuthProvider);
  const { reset2fa } = useSelector(selectAccountSettings);
  const snackbar = useSnackbar();
  const confirm = useConfirm();

  useEffectOnChange(
    () => {
      if (reset2fa.data) {
        snackbar.enqueueSnackbar('2FA reset successfully', {
          variant: 'success',
          title: '2FA Reset',
        });
      }
    },
    reset2fa.loading,
    false,
  );

  return (
    <Box sx={{ my: 4 }}>
      <Paper sx={{ mt: 2, p: 4 }}>
        <Stack spacing={2}>
          <Typography>
            If you need to use a different device to authenticate with this
            application, you can reset your 2-Factor Authentication status here.
          </Typography>
          <Box mt={2}>
            <Button
              disabled={loadUser.data?.google2fa_status !== 'active'}
              variant="contained"
              onClick={() =>
                confirm({
                  title: 'Reset 2FA',
                  description:
                    'Are you sure you want to reset your 2FA status? You will need to reconfigure 2FA on your next login.',
                })
                  .then(() => dispatch(actions.reset2faRequest()))
                  .catch(() => {})
              }
            >
              Reset 2FA Now
            </Button>
          </Box>
        </Stack>
      </Paper>
    </Box>
  );
}
