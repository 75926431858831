/**
 *
 * PreviewPageForm
 *
 */

import React from 'react';
import { Field, Form, Formik } from 'formik';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Box, Button, Grid, Paper, Skeleton } from '@mui/material';
import { Link } from 'react-router-dom';
import { CheckboxWithLabel, TextField } from 'formik-mui';
import { DatePicker } from 'formik-mui-lab';
import clsx from 'clsx';
import Yup from 'utils/yup';
import {
  IPreviewPage,
  PreviewPageApiCall,
  SavePreviewPageApiCall,
} from './slice/types';
import { FormBoxContainer } from 'styles/theme/themes';
import { getBase64 } from 'utils/saga/createFileUploadChannel';
import moment from 'moment';
import { ImageUploadFormElement } from '../../components/ImageUploadFormElement';

const schema = Yup.object().shape({
  reference: Yup.string().required('Required'),
  description: Yup.string(),
});

interface PreviewPageFormProps {
  loadPreviewPage: PreviewPageApiCall;
  savePreviewPage: SavePreviewPageApiCall;
  onSave: (data: IPreviewPage) => void;
}

export function PreviewPageForm(props: PreviewPageFormProps) {
  const { loadPreviewPage, savePreviewPage, onSave } = props;

  const expires_at = moment().add(1, 'M');
  const data: Partial<IPreviewPage> = {
    id: null,
    uuid: '',
    reference: '',
    is_white_labelled: false,
    white_label_name: '',
    description: '',
    expires_at,
    logo_action: null,
    active: true,

    ...(loadPreviewPage.data || {}),
  };

  if (loadPreviewPage.loading && !loadPreviewPage.data)
    return (
      <Box sx={{ my: 4, height: 400 }}>
        <Skeleton height={400} variant={'rectangular'} />
      </Box>
    );
  return (
    <FormBoxContainer>
      <Paper sx={{ my: 2, p: 2 }}>
        <Formik
          initialValues={data}
          enableReinitialize
          validationSchema={schema}
          validateOnChange
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            onSave(values as IPreviewPage);
          }}
        >
          {({
            errors,
            values,
            setFieldValue,
            submitForm,
            isSubmitting,
            setSubmitting,
          }) => {
            if (
              isSubmitting &&
              !(loadPreviewPage.loading || savePreviewPage.loading)
            ) {
              setSubmitting(false);
            }
            if (
              !isSubmitting &&
              (loadPreviewPage.loading || savePreviewPage.loading)
            ) {
              setSubmitting(true);
            }

            return (
              <Form>
                <Box className={clsx('has-actions')}>
                  <Box className={'formContent'}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          id="reference"
                          name="reference"
                          type="text"
                          label="Reference"
                          fullWidth
                          className={'field'}
                        />
                      </Grid>{' '}
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          id="expires_at"
                          name="expires_at"
                          type="date"
                          InputLabelProps={{ shrink: true }}
                          label="Expiry Date"
                          fullWidth
                          inputFormat="dd/MM/yyyy"
                          className={'field'}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          id="description"
                          multiline
                          rows={5}
                          name="description"
                          type="description"
                          label="Description"
                          fullWidth
                          className={'field'}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Field
                          component={CheckboxWithLabel}
                          name="is_white_labelled"
                          type="checkbox"
                          Label={{ label: 'Is white labelled?' }}
                        />
                      </Grid>
                      {!!values.is_white_labelled && (
                        <>
                          <Grid item xs={12} sm={6}>
                            <Field
                              component={TextField}
                              id="white_label_name"
                              name="white_label_name"
                              type="text"
                              label="White labelled name"
                              fullWidth
                              className={'field'}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Box sx={{ pb: 2 }}>
                              <ImageUploadFormElement
                                logo_url={
                                  loadPreviewPage.data?.white_label_logo_id
                                    ? loadPreviewPage.data?.logo_url
                                    : null
                                }
                                loading={savePreviewPage.loading}
                                progress={savePreviewPage.progress}
                                onFileChange={file => {
                                  if (file) {
                                    getBase64(file).then(contents => {
                                      setFieldValue('logo_action', {
                                        type: 'new',
                                        data: {
                                          contents,
                                          filename: file.name,
                                          filesize: file.size,
                                          filetype: file.type,
                                        },
                                      });
                                    });
                                  } else {
                                    setFieldValue('logo_action', {
                                      type: 'remove',
                                    });
                                  }
                                }}
                              />
                            </Box>
                          </Grid>
                        </>
                      )}
                      <Grid item xs={12} sm={12}>
                        <Field
                          name="active"
                          type="checkbox"
                          Label={{ label: 'Active' }}
                          component={CheckboxWithLabel}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className={'formActions'} sx={{ mt: 4 }}>
                    <Button
                      variant="text"
                      size="small"
                      component={Link}
                      to="/portal/accounts/preview-pages"
                    >
                      Cancel
                    </Button>
                    <Button
                      className="align-right"
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      Save {values.id ? 'Changes' : 'New PreviewPage'}
                    </Button>
                  </Box>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Paper>
    </FormBoxContainer>
  );
}
