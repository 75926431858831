import * as React from 'react';
import GS from '@mui/material/GlobalStyles';

const inputGlobalStyles = (
  <GS
    styles={{
      'html, body': {
        height: '100%',
        width: '100%',
        lineHeight: 1.5,
      },

      body: {
        fontFamily: 'Helvetica, Arial, sans-serif',
        backgroundColor: 'white',
      },

      'body.fontLoaded': {
        fontFamily:
          "'Fira Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
      },
    }}
  />
);

export const GlobalStyles = props => {
  return <React.Fragment>{inputGlobalStyles}</React.Fragment>;
};

/*



  h1, h2, h3, h4, h5, h6 {
    font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  p,
  label {
    line-height: 1.5em;
  }

  input, select, button {
    font-family: inherit;
    font-size: inherit;
  }

  .icon {
    width: 1.5rem;
    height: 1.5rem;
  }

 */
