import { AnyAction, SerializedError } from '@reduxjs/toolkit';
import axios, { CancelTokenSource } from 'axios';
import { call, cancelled, put, take } from 'redux-saga/effects';
import { apiRequest, ResponseError } from 'utils/request';
import { createUploadFileChannel } from './saga/createFileUploadChannel';

export type NestedObject<T = {}> = T & {
  [key: string]: NestedObject | string | boolean | number | null | undefined;
};

export interface RequestSagaConfig<P, T> {
  actionType: string;
  url: string | ((payload: P) => string);
  method?:
    | 'get'
    | 'post'
    | 'put'
    | 'delete'
    | ((payload: P) => 'get' | 'post' | 'put' | 'delete');
  otherOptions?: any;
  data?: (payload: P) => NestedObject;
  successAction?: (response: T) => any;
  failureAction?: (error: any) => any;
  onSuccess?: (response: T, dataSent: P) => Generator<any, void, any>;
  onFailure?: (error: any, dataSent: P) => Generator<any, void, any>;
  onCancel?: () => Generator<any, void, any>;
}

export function createRequestSaga<P = any, T = any>({
  actionType,
  url,
  method = 'get',
  otherOptions,
  successAction,
  data,
  failureAction,
  onSuccess,
  onFailure,
  onCancel,
}: RequestSagaConfig<P, T>) {
  return function* (
    action: AnyAction & { payload: P },
  ): Generator<any, void, any> {
    const cancelSource: CancelTokenSource = axios.CancelToken.source();

    try {
      const payload: T = yield call(apiRequest, {
        url: typeof url === 'function' ? url(action.payload) : url,
        method: typeof method === 'function' ? method(action.payload) : method,
        data: data ? data(action.payload) : action.payload,
        cancelToken: cancelSource.token,
        ...otherOptions,
      });

      if (successAction) {
        yield put(successAction(payload));
      }

      if (onSuccess) {
        yield* onSuccess(payload, action.payload);
      }
    } catch (e) {
      const errorPayload = makeErrorPayload(e);

      if (failureAction) {
        yield put(failureAction(errorPayload));
      }

      if (onFailure) {
        yield* onFailure(e, action.payload);
      }
    } finally {
      if (yield cancelled()) {
        cancelSource.cancel('Operation canceled by the user.');
        if (onCancel) {
          yield* onCancel();
        }
      }
    }
  };
}

export interface UploadSagaConfig<P, T> {
  actionType: string;
  getURL: (payload: P) => string;
  getMethod: (payload: P) => 'post' | 'put';
  progressAction?: ({ progress: number }) => any;
  successAction?: (response: T) => any;
  failureAction?: (error: any) => any;
  onCancel?: () => Generator<any, void, any>;
}

export function createUploadRequestSaga<P = any, T = any>({
  actionType,
  getURL,
  getMethod,
  progressAction,
  successAction,
  failureAction,
  onCancel,
}: UploadSagaConfig<P, T>) {
  return function* (action: {
    type: string;
    payload: P;
  }): Generator<any, void, any> {
    const cancelSource: CancelTokenSource = axios.CancelToken.source();
    try {
      const channel = yield call(
        createUploadFileChannel,
        getURL(action.payload),
        action.payload,
        getMethod(action.payload),
      );

      while (true) {
        const { progress = 0, err, success, response } = yield take(channel);
        if (err) {
          if (failureAction) yield put(failureAction(err));
          return;
        }
        if (success) {
          const res = response ? JSON.parse(response) : undefined;
          if (successAction) yield put(successAction(res));
          return;
        }
        if (progressAction) yield put(progressAction(progress));
      }
    } catch (error) {
      if (failureAction) yield put(failureAction(error));
    } finally {
      if (yield cancelled()) {
        cancelSource.cancel('Operation canceled by the user.');
        if (onCancel) {
          yield* onCancel();
        }
      }
    }
  };
}

export const makeErrorPayload = (e: any): SerializedError => {
  if (axios.isAxiosError(e)) {
    // Handling Axios errors specifically

    if (e.response?.data) {
      return { ...e.response?.data, code: `${e.response?.status}` };
    }

    return {
      name: e.name,
      message: e.message,
      code: `${e.response?.status}`,
      // Any other relevant and serializable information
    };
  }

  // Handling generic Error objects
  return e instanceof Error
    ? {
        name: e.name,
        message: e.message,
      }
    : {
        name: 'unknown',
        message: 'An unknown error occurred.',
      };
};
