/**
 *
 * Taxonomy Terms
 *
 */
import * as React from 'react';
import { ITaxonomyTerm, TaxonomyApiCall } from './slice/types';
import { ApiCall, UploadApiCall } from '../../../types/ApiCall';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { Term } from './Term';
import { Box, Button, Paper } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ITerm } from './slice/types';
import { IPreviewPagePerson } from '../PreviewPages/slice/types';
import { isEqual, pullAt } from 'lodash';
import move from 'lodash-move/index';
import { useDeepCompareMemoize } from '../../../utils/useDeepCompareMemoize';
import { FormBoxContainer } from 'styles/theme/themes';
import key from 'weak-key';
import { Add, PersonAdd } from '@mui/icons-material';
import { Link } from 'react-router-dom';

interface TermsProps {
  loadTaxonomy: TaxonomyApiCall;
  updateTerms: ApiCall;
  onUpdate: (data: any) => void;
}

export function Terms(props: TermsProps) {
  const { loadTaxonomy, updateTerms, onUpdate } = props;

  const initListState = useMemo(() => {
    return loadTaxonomy.data?.terms;
  }, [loadTaxonomy.data]);

  const [listState, setListState] = useState<Array<ITaxonomyTerm>>(
    initListState || [],
  );

  const actions = useMemo(() => {
    const emptyActions: {
      update: Array<any>;
      delete: Array<any>;
      create: Array<any>;
    } = {
      update: [],
      delete: [],
      create: listState
        .filter(term => !term.id)
        .map(term => ({
          name: term.name,
          order: term.order,
          is_other: term.is_other,
          active: term.active,
        })),
    };

    return initListState?.reduce((acc, term) => {
      const newTerm = listState.find(t => t.id === term.id);
      if (!newTerm) {
        acc.delete.push({
          id: term.id,
        });
      } else if (!isEqual(term, newTerm)) {
        acc.update.push({
          id: term.id,
          ...newTerm,
        });
      }
      return acc;
    }, emptyActions);
  }, [listState]);

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const newList = move(listState, dragIndex, hoverIndex).map(
        (item, index) => ({
          ...item,
          order: (index + 1) * 10,
        }),
      );

      setListState(newList);
    },
    [listState],
  );

  useEffect(() => {
    setListState(initListState || []);
  }, useDeepCompareMemoize([initListState]));

  useEffect(() => {
    setListState(
      [...listState]
        .sort((t1, t2) => {
          return t1.order! - t2.order!;
        })
        .map((t, i) => ({ ...t, order: (i + 1) * 10 })),
    );
  }, useDeepCompareMemoize([listState]));

  if (!loadTaxonomy.data) {
    return <LoadingIndicator />;
  }

  return (
    <Paper
      sx={{
        my: 4,
      }}
    >
      <FormBoxContainer
        className="terms"
        sx={{
          pointerEvents: updateTerms.loading ? 'none' : 'auto',
          opacity: updateTerms.loading ? 0.5 : 1,
        }}
      >
        {listState.map((term, index) => (
          <Term
            key={`${term.id || term.tempKey}-term-entry`}
            index={index}
            moveCard={moveCard}
            loading={updateTerms.loading}
            term={term}
            onUpdateDetails={(index, data) => {
              setListState(
                listState.map((p, i) => ({
                  ...p,
                  ...(i === index ? { ...data } : {}),
                })),
              );
            }}
            onDelete={index =>
              setListState([...listState.filter((t, i) => i !== index)])
            }
          />
        ))}

        <Box sx={{ textAlign: 'right' }}>
          <Button
            size={'large'}
            startIcon={<Add color={'secondary'} />}
            sx={{ mb: 4, mt: 2, mx: 2 }}
            onClick={() =>
              setListState([
                ...listState,
                {
                  name: 'New Term',
                  active: true,
                  order: listState.length * 10,
                  is_other: false,
                  tempKey: Math.random().toString(36).slice(2),
                },
              ])
            }
          >
            Add new
          </Button>
        </Box>

        {!!loadTaxonomy.data!.terms.length && (
          <Box className={'formActions'}>
            <Button
              className={'alignRight'}
              sx={{ m: 2 }}
              color={'secondary'}
              variant={'contained'}
              disabled={isEqual(listState, initListState)}
              onClick={() => {
                setListState(initListState || []);
              }}
            >
              Cancel changes
            </Button>
            <Button
              className={'align-right'}
              sx={{ m: 2 }}
              color={'primary'}
              variant={'contained'}
              disabled={isEqual(listState, initListState)}
              onClick={() => {
                onUpdate({
                  actions,
                });
              }}
            >
              Save Changes
            </Button>
          </Box>
        )}
      </FormBoxContainer>
    </Paper>
  );
}
