import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import {
  apiCallInitialState,
  ResultMetaData,
  uploadApiCallInitialState,
} from 'types/ApiCall';
import { ResponseErrorPayload } from 'utils/request';
import { peopleSliceSaga } from './saga';
import { PeopleSliceState, IPerson } from './types';

export const initialState: PeopleSliceState = {
  people: {
    loading: false,
    data: [],
    resultSet: {
      key: '',
      count: 0,
    },
  },
  save: uploadApiCallInitialState,
  closeEditInvitation: apiCallInitialState,
  sendEditInvitation: apiCallInitialState,
  delete: apiCallInitialState,
  changeField: apiCallInitialState,
  person: {
    loading: false,
    data: undefined,
  },
  updateSoundFiles: uploadApiCallInitialState,
};

interface UpdateSoundFilesRequest {
  remove: Array<number>;
  update: Array<{
    on_website: boolean;
    order: number;
    description: string;
    [key: string]: any;
  }>;
  create: Array<{
    contents: any;
    filename: string;
  }>;
}

export const slice = createSlice({
  name: 'peopleSlice',
  initialState,
  reducers: {
    loadPeopleRequest(state, action: PayloadAction<{ [key: string]: any }>) {
      state.people.loading = true;
      state.people.error = undefined;
    },
    loadPeopleSuccess(
      state,
      action: PayloadAction<{
        data: Array<IPerson>;
        meta: ResultMetaData;
        resultSet: { key: string; count: number };
      }>,
    ) {
      state.people.loading = false;
      state.people.data = action.payload.data;
      state.people.meta = action.payload.meta;
      state.people.resultSet = action.payload.resultSet;
    },
    loadPeopleFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.people.loading = false;
      state.people.error = action.payload;
    },
    loadPersonRequest(state, action: PayloadAction<{ id: number | 'new' }>) {
      state.person.loading = true;
      state.person.error = undefined;
    },
    loadPersonUnmount(state, action: PayloadAction<void>) {
      state.person.loading = false;
      state.person.error = undefined;
      state.person.data = undefined;
      state.save.loading = false;
      state.save.error = undefined;
      state.save.data = undefined;
    },
    loadPersonSuccess(
      state,
      action: PayloadAction<{
        data: IPerson;
      }>,
    ) {
      state.person.loading = false;
      state.person.data = action.payload.data;
    },
    loadPersonFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.person.loading = false;
      state.person.error = action.payload;
    },
    deletePeopleRequest(state, action: PayloadAction<Array<number | string>>) {
      state.delete.loading = true;
      state.delete.error = undefined;
    },
    deletePeopleSuccess(
      state,
      action: PayloadAction<{
        status: 'success';
      }>,
    ) {
      state.delete.loading = false;
      state.delete.data = action.payload.status;
    },
    deletePeopleFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.delete.loading = false;
      state.delete.error = action.payload;
    },
    changeFieldRequest(
      state,
      action: PayloadAction<
        {
          id: number | string;
          field_name: string;
          field_value: string | number | boolean;
        }[]
      >,
    ) {
      state.changeField.loading = true;
      state.changeField.error = undefined;
    },
    changeFieldSuccess(
      state,
      action: PayloadAction<{
        status: 'success';
      }>,
    ) {
      state.changeField.loading = false;
      state.changeField.data = action.payload.status;
    },
    changeFieldFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.changeField.loading = false;
      state.changeField.error = action.payload;
    },
    sendEditInvitationRequest(
      state,
      action: PayloadAction<Array<number | string>>,
    ) {
      state.sendEditInvitation.loading = true;
      state.sendEditInvitation.error = undefined;
    },
    sendEditInvitationSuccess(
      state,
      action: PayloadAction<{
        status: 'success';
      }>,
    ) {
      state.sendEditInvitation.loading = false;
      state.sendEditInvitation.data = action.payload.status;
    },
    sendEditInvitationFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.sendEditInvitation.loading = false;
      state.sendEditInvitation.error = action.payload;
    },
    closeEditInvitationRequest(
      state,
      action: PayloadAction<{ id: number; closeAction: 'accept' | 'cancel' }>,
    ) {
      state.closeEditInvitation.loading = true;
      state.closeEditInvitation.error = undefined;
    },
    closeEditInvitationSuccess(
      state,
      action: PayloadAction<{
        status: 'success';
      }>,
    ) {
      state.closeEditInvitation.loading = false;
      state.closeEditInvitation.data = action.payload.status;
    },
    closeEditInvitationFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.closeEditInvitation.loading = false;
      state.closeEditInvitation.error = action.payload;
    },
    savePersonRequest(state, action: PayloadAction<{ [key: string]: any }>) {
      state.save.loading = true;
      state.save.error = undefined;
    },
    savePersonUnmount(state, action: PayloadAction<void>) {
      state.save.loading = true;
      state.save.error = undefined;
      state.save.data = undefined;
    },
    savePersonSuccess(state, action: PayloadAction<{ data: IPerson }>) {
      state.save.loading = false;
      state.save.data = action.payload.data;
    },
    savePersonFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.save.loading = false;
      state.save.error = action.payload;
    },
    updateSoundFilesRequest(
      state,
      action: PayloadAction<UpdateSoundFilesRequest>,
    ) {
      state.updateSoundFiles.loading = true;
      state.updateSoundFiles.error = undefined;
      state.updateSoundFiles.progress = 0;
    },
    updateSoundFilesSuccess(state, action: PayloadAction<{ file: File }>) {
      state.updateSoundFiles.loading = false;
      state.updateSoundFiles.data = action.payload;
      state.updateSoundFiles.error = undefined;
    },
    updateSoundFilesProgress(
      state,
      action: PayloadAction<{ progress: number }>,
    ) {
      state.updateSoundFiles.progress = action.payload.progress;
    },
    updateSoundFilesFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.updateSoundFiles.loading = false;
      state.updateSoundFiles.progress = 0;
      state.updateSoundFiles.error = action.payload;
    },
    updateSoundFilesReset(state, action: PayloadAction<void>) {
      state.updateSoundFiles = uploadApiCallInitialState;
    },
  },
});

export const { actions: peopleSliceActions } = slice;
