import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { PersonAdd, Search } from '@mui/icons-material';
import { ManagementTable } from '../../components/ManagementTable';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import * as React from 'react';
import { useUsersSlice } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectUsers } from './slice/selectors';
import { useParams } from 'react-router';
import { GridSortDirection } from '@mui/x-data-grid-pro';
import { useDebounce } from '../../../utils/useDebounce';
import { get, isEqual } from 'lodash';
import { useQuery } from '../../../utils/query';

export function List() {
  const match = useParams();

  const { actions } = useUsersSlice();
  const dispatch = useDispatch();
  const users = useSelector(selectUsers);

  const { query, setQuery } = useQuery();
  const [pageSize, setPageSize] = React.useState<number>(
    +(query.pageSize || 25),
  );
  const [page, setPage] = React.useState<number>(+(query.page || 1));
  const [sort, setSort] = React.useState<
    [field: string, direction?: GridSortDirection] | undefined
  >(query.sort || undefined);
  const [filters, setFilters] = React.useState<{ [key: string]: any }>(
    query.filters || {
      name: '',
    },
  );

  const getFilters = () => query.filters;
  const { search } = useLocation();

  React.useEffect(() => {
    if (!getFilters()) return;
    if (!isEqual(getFilters(), filters)) setFilters(getFilters());
  }, [search]);

  React.useEffect(() => {
    if (pageSize && pageSize !== query.pageSize) {
      setQuery('pageSize', pageSize);
    }
  }, [pageSize]);

  React.useEffect(() => {
    if (page && page !== query.page) {
      setQuery('page', page);
    }
  }, [page]);

  const debouncedFilters = useDebounce(filters, 700);
  React.useEffect(() => {
    loadUsers();
  }, [dispatch, debouncedFilters]);

  const loadUsers = () => {
    dispatch(
      actions.loadUsersRequest({
        ...filters,
        'page[number]': Math.max(page - 1, 0),
        'page[size]': pageSize,
        sort: sort,
      }),
    );
  };

  return (
    <Box sx={{ my: 4 }}>
      <Grid
        container
        spacing={2}
        alignItems={'center'}
        justifyContent={'flex-start'}
      >
        <Grid item>
          <Typography variant="h1" color={'primary'}>
            All users
          </Typography>
        </Grid>
        <Grid item sm>
          <Paper>
            <TextField
              placeholder={'Search by user name'}
              InputProps={{
                sx: { p: 1 },
                startAdornment: (
                  <InputAdornment position={'start'}>
                    <Search />
                  </InputAdornment>
                ),
              }}
              fullWidth
              value={filters.name}
              onChange={ev => setFilters({ ...filters, name: ev.target.value })}
              variant={'standard'}
            />
          </Paper>
        </Grid>{' '}
        <Grid item sx={{ marginLeft: 'auto' }}>
          <Button
            size={'large'}
            startIcon={<PersonAdd color={'secondary'} />}
            component={Link}
            to={`./new`}
          >
            Add new user
          </Button>
        </Grid>
      </Grid>
      <Paper sx={{ my: 2 }}>
        <ManagementTable
          rows={users.data}
          columns={[
            {
              value: v => `${v.first_name} ${v.last_name}`,
              label: 'Name',
            },
            {
              value: 'email',
              label: 'Email',
            },
            {
              value: v => {
                if (v.role_name === 'Administrator') {
                  return v.role_name;
                }
                return `${v.role_name} user`;
              },
              label: 'Role',
            },
            {
              value: v => (v.account_enabled ? 'Active' : 'Disabled'),
              label: 'Status',
            },
            {
              classes: 'align-right',
              value: v => {
                return (
                  <Button
                    sx={{ my: 1 }}
                    component={Link}
                    to={`./${v.id}`}
                    variant={'contained'}
                    color={'primary'}
                  >
                    Edit User
                  </Button>
                );
              },
            },
          ]}
          onClick={() => {}}
          page={page}
          onChangePage={page => {
            setPage(page);
          }}
          rowsPerPage={pageSize}
          onChangeRowsPerPage={size => setPageSize(size)}
          rowsPerPageOptions={[10, 25, 50]}
          count={get(users, 'meta.total', 0)}
          loading={users.loading}
          loadingComponent={
            <LoadingIndicator minHeight={300} message={'Loading data'} />
          }
        />
      </Paper>
    </Box>
  );
}
