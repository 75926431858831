import { call, cancelled, put, takeLatest, take } from 'redux-saga/effects';
import { apiRequest } from 'utils/request';
import { voiceServicesSliceActions as actions } from '.';

function* loadVoiceServices(action) {
  try {
    const payload = yield call(apiRequest, {
      url: `voice-services`,
      method: 'get',
      data: {
        ...action.payload,
      },
    });

    yield put(actions.loadVoiceServicesSuccess(payload.data));
  } catch (error) {
    yield put(actions.loadVoiceServicesFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* updateItems(action) {
  try {
    const payload = yield call(apiRequest, {
      url: `voice-services/update-items`,
      method: 'put',
      data: {
        ...action.payload,
      },
    });

    yield put(actions.updateItemsSuccess(payload));
  } catch (error) {
    yield put(actions.updateItemsFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

export function* voiceServicesSliceSaga() {
  yield takeLatest(actions.loadVoiceServicesRequest.type, loadVoiceServices);
  yield takeLatest(actions.updateItemsRequest.type, updateItems);
}
