import { Box, Button, Container, Grid, Paper, Typography } from '@mui/material';
import { get, isEqual } from 'lodash';
import { Check, Close, Create } from '@mui/icons-material';
import { ManagementTable } from '../../components/ManagementTable';
import { Link, useLocation } from 'react-router-dom';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { usePreviewPagesSlice } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectChangeField,
  selectDeletePages,
  selectPreviewPages,
} from './slice/selectors';
import { useDebounce } from 'utils/useDebounce';
import { useQuery } from 'utils/query';
import { useParams } from 'react-router';
import { GridSortDirection } from '@mui/x-data-grid-pro';
import { FilterBar } from '../FilterBar';
import { grey } from '@mui/material/colors';
import { ActionsMenu } from '../../components/ActionsMenu';
import { useConfirm } from '../../components/ConfirmDialog';
import { selectAppSettings } from '../App/slice/selectors';
import { useHasChanged } from '../../../utils/usePrevious';

export function List() {
  const match = useParams();
  const confirm = useConfirm();
  const { actions } = usePreviewPagesSlice();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const previewPages = useSelector(selectPreviewPages);
  const deletePages = useSelector(selectDeletePages);
  const changeField = useSelector(selectChangeField);
  const settings = useSelector(selectAppSettings);

  const [selectedRows, setSelectedRows] = useState<Array<number | string>>([]);

  const { query, setQuery } = useQuery();
  const [pageSize, setPageSize] = React.useState<number>(
    +(query.pageSize || 25),
  );
  const [page, setPage] = React.useState<number>(+(query.page || 1));
  const [sort, setSort] = React.useState<
    [field: string, direction?: GridSortDirection] | undefined
  >(query.sort || undefined);

  const getFilters = () => query.filters;
  const [filters, setFilters] = React.useState(getFilters());

  React.useEffect(() => {
    if (!isEqual(getFilters(), filters)) setFilters(getFilters());
  }, [search]);

  const debouncedFilters = useDebounce(filters, 400);

  const deletePagesChanged = useHasChanged(deletePages.loading);
  const changeFieldChanged = useHasChanged(changeField.loading);

  React.useEffect(() => {
    if (
      (deletePagesChanged && !deletePages.loading) ||
      (changeFieldChanged && !changeField.loading)
    ) {
      loadPreviewPages();
    }
  });

  const doAction = useCallback(
    action => {
      switch (action) {
        case 'delete':
          confirm({
            title: `Delete (${selectedRows.length} selected)`,
          })
            .then(() =>
              dispatch(
                actions.deletePreviewPageRequest(selectedRows as number[]),
              ),
            )
            .catch(() => {});
          break;
        case 'set_active':
          confirm({
            title: `Set ${selectedRows.length} selected rows to active`,
          })
            .then(() =>
              dispatch(
                actions.changeFieldRequest(
                  selectedRows.map(id => ({
                    field_name: 'active',
                    field_value: true,
                    id: id as number,
                  })),
                ),
              ),
            )
            .catch(() => {});
          break;
        case 'set_inactive':
          confirm({
            title: `Set ${selectedRows.length} selected rows to inactive`,
          })
            .then(() =>
              dispatch(
                actions.changeFieldRequest(
                  selectedRows.map(id => ({
                    field_name: 'active',
                    field_value: false,
                    id: id as number,
                  })),
                ),
              ),
            )
            .catch(() => {});
          break;
      }
    },
    [selectedRows],
  );
  React.useEffect(() => {
    setQuery('pageSize', pageSize);
  }, [pageSize]);

  React.useEffect(() => {
    setQuery('page', page);
  }, [page]);

  React.useEffect(() => {
    setQuery('filters', filters);
    setPage(1);
  }, [filters]);

  React.useEffect(() => {
    setQuery('sort', sort);
  }, [sort]);

  React.useEffect(() => {
    loadPreviewPages();
  }, [dispatch, debouncedFilters, page, pageSize]);

  const loadPreviewPages = () => {
    console.log(debouncedFilters, page, pageSize);
    dispatch(
      actions.loadPreviewPagesRequest({
        ...query.filters,
        'page[number]': Math.max(page, 1),
        'page[size]': pageSize,
        sort: sort,
      }),
    );
  };
  return (
    <Box sx={{ my: 4 }}>
      <Container maxWidth="xl">
        <Grid
          container
          spacing={2}
          alignItems={'center'}
          justifyContent={'flex-start'}
        >
          <Grid item>
            <Typography variant="h1" color={'primary'}>
              All preview pages
            </Typography>
          </Grid>
          <Grid item sx={{ marginLeft: 'auto' }}>
            <Button
              size={'large'}
              startIcon={<Create color={'secondary'} />}
              component={Link}
              to={`./new`}
            >
              Add new page
            </Button>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="xl">
        <Paper sx={{ my: 2 }}>
          <Box sx={{ px: 2, py: 1, backgroundColor: grey.A200 }}>
            <FilterBar
              fields={[
                { name: 'searchTerm', props: { label: 'Search pages' } },
                {
                  name: 'select',
                  props: {
                    label: 'User created/updated',
                    fieldName: 'user_id',
                    options: [
                      { value: '', label: 'All users' },
                      ...settings.users.map(u => ({
                        value: `${u.id}`,
                        label: `${u.first_name} ${u.last_name}`,
                      })),
                    ],
                  },
                },
              ]}
            />
          </Box>
          <Box sx={{ p: 2 }}>
            <ActionsMenu
              label={`Bulk actions (${selectedRows.length} selected)`}
              doAction={doAction}
              disabled={!selectedRows.length}
              actions={[
                {
                  type: 'delete',
                  label: 'Delete permanently',
                },
                {
                  type: 'set_active',
                  label: 'Set active',
                },
                {
                  type: 'set_inactive',
                  label: 'Set inactive',
                },
              ]}
            />
          </Box>
          <ManagementTable
            enableCheckboxSelection
            onSelectionChange={ids => setSelectedRows(ids)}
            defaultRowsSelected={selectedRows}
            rows={previewPages.data as any}
            columns={[
              {
                value: 'reference',
                label: 'Reference',
              },
              {
                value: 'expires_at',
                label: 'Expiry',
              },
              {
                value: v =>
                  `${new Date(v.created_at).toLocaleString()} by ${
                    v.created_by.first_name
                  } ${v.created_by.last_name} `,
                label: 'Created',
              },
              {
                value: v =>
                  `${new Date(v.updated_at).toLocaleString()} by ${
                    v.last_updated_by.first_name
                  } ${v.last_updated_by.last_name} `,
                label: 'Last updated',
              },
              {
                value: v =>
                  v.active ? (
                    <Check color={'success'} />
                  ) : (
                    <Close color={'error'} />
                  ),
                label: 'Status',
              },
              {
                classes: 'align-right',
                value: v => {
                  return (
                    <Button
                      sx={{ my: 1 }}
                      component={Link}
                      to={`./${v.id}`}
                      variant={'contained'}
                      color={'primary'}
                    >
                      Edit
                    </Button>
                  );
                },
              },
            ]}
            onClick={() => {}}
            page={page - 1}
            onChangePage={page => {
              setPage(page);
            }}
            onChangeRowsPerPage={size => setPageSize(size)}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[10, 25, 50]}
            count={get(previewPages, 'meta.total', 0)}
            loading={previewPages.loading}
            loadingComponent={
              <LoadingIndicator minHeight={300} message={'Loading data'} />
            }
          />
        </Paper>
      </Container>
    </Box>
  );
}
