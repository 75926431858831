/**
 *
 * Person Sound Files
 *
 */
import * as React from 'react';
import { ISoundFile, PersonApiCall } from './slice/types';
import { SoundFileUploadForm } from './SoundFilesUploadForm';
import { UploadApiCall } from '../../../types/ApiCall';
import { getBase64 } from '../../../utils/saga/createFileUploadChannel';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { SoundFile } from './SoundFile';
import { Box, Button, Paper } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import key from 'weak-key';
import { useWhatChanged } from '@simbathesailor/use-what-changed';
import { IPreviewPagePerson } from '../PreviewPages/slice/types';
import { isEqual } from 'lodash';
import move from 'lodash-move/index';
import { useDeepCompareMemoize } from '../../../utils/useDeepCompareMemoize';
import { FormBoxContainer } from 'styles/theme/themes';

interface SoundFilesProps {
  loadPerson: PersonApiCall;
  updateSoundFiles: Partial<UploadApiCall>;
  onUpdate: (data: any) => void;
}

export const SoundFiles = React.memo((props: SoundFilesProps) => {
  const { loadPerson, updateSoundFiles, onUpdate } = props;

  const initListState = useMemo(() => {
    return loadPerson.data?.sound_files;
  }, [loadPerson.data]);

  const [listState, setListState] = useState<Array<ISoundFile>>(
    initListState || [],
  );

  const actions = useMemo(() => {
    const emptyActions: { update: Array<any>; delete: Array<any> } = {
      update: [],
      delete: [],
    };

    return initListState?.reduce((acc, soundFile) => {
      const newSoundFile = listState.find(sf => sf.id === soundFile.id);
      if (!newSoundFile) {
        acc.delete.push({
          id: soundFile.id,
        });
      } else if (!isEqual(soundFile, newSoundFile)) {
        acc.update.push({
          ...newSoundFile,
        });
      }

      return acc;
    }, emptyActions);
  }, [listState]);

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const newList = move(listState, dragIndex, hoverIndex).map(
        (item, index) => ({
          ...item,
          order: (index + 1) * 10,
        }),
      );

      setListState(newList);
    },
    [listState],
  );

  useEffect(() => {
    setListState(initListState || []);
  }, useDeepCompareMemoize([initListState]));

  useEffect(() => {
    setListState([...listState].sort((sf1, sf2) => sf1.order - sf2.order));
  }, useDeepCompareMemoize([listState]));

  if (!loadPerson.data) {
    return <LoadingIndicator />;
  }

  console.log(listState);

  return (
    <>
      <Box
        sx={{
          my: 4,
        }}
      >
        <FormBoxContainer
          className="soundFiles"
          sx={{
            pointerEvents: updateSoundFiles.loading ? 'none' : 'auto',
            opacity: updateSoundFiles.loading ? 0.5 : 1,
          }}
        >
          {listState.map((soundFile, index) => (
            <SoundFile
              key={`soundfile-${soundFile.id}`}
              index={index}
              moveCard={moveCard}
              loading={updateSoundFiles.loading || false}
              soundFile={soundFile}
              onUpdateDetails={data => {
                setListState(
                  listState.map(sf => ({
                    ...sf,
                    ...(sf.id === soundFile.id
                      ? {
                          on_website: data?.on_website,
                          description: data?.description,
                        }
                      : {}),
                  })),
                );
              }}
              onDelete={() =>
                setListState(listState.filter(sf => sf.id !== soundFile.id))
              }
            />
          ))}

          {!!loadPerson.data!.sound_files.length && (
            <Box className={'formActions'}>
              <Button
                className={'alignRight'}
                sx={{ m: 2 }}
                color={'secondary'}
                variant={'contained'}
                disabled={isEqual(listState, initListState)}
                onClick={() => {
                  setListState(initListState || []);
                }}
              >
                Cancel changes
              </Button>
              <Button
                className={'align-right'}
                sx={{ m: 2 }}
                color={'primary'}
                variant={'contained'}
                disabled={isEqual(listState, initListState)}
                onClick={() => {
                  onUpdate({
                    actions,
                  });
                }}
              >
                Save Changes
              </Button>
            </Box>
          )}
        </FormBoxContainer>
      </Box>
      <Paper sx={{ my: 4 }}>
        <Box
          className={'uploadForm'}
          sx={{
            p: 4,
            pointerEvents: updateSoundFiles.loading ? 'none' : 'auto',
            opacity: updateSoundFiles.loading ? 0.5 : 1,
          }}
        >
          <SoundFileUploadForm
            loading={updateSoundFiles.loading || false}
            progress={updateSoundFiles.progress || 0}
            request={file =>
              getBase64(file).then(contents => {
                onUpdate({
                  actions: {
                    create: [
                      {
                        contents,
                        filename: file.name,
                        filesize: file.size,
                        filetype: file.type,
                      },
                    ],
                  },
                });
              })
            }
          />
        </Box>
      </Paper>
    </>
  );
});
