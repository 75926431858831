/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAuthProvider,
  selectIsLoggedIn,
  selectUserData,
} from 'app/containers/AuthProvider/slice/selectors';
import { ProtectedRoute } from '../../components/ProtectedRoute';
import { Login } from '../Login/Loadable';
import { Portal } from '../Portal';
import { ForgotPassword } from '../ForgotPassword/Loadable';
import { ResetPassword } from '../ResetPassword/Loadable';
import { EnvironmentIndicator } from '../EnvironmentIndicator';
import { useAppSlice } from './slice';
import { selectAppLoad } from './slice/selectors';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { UpdateDetailsPage } from '../UpdateDetailsPage/Loadable';
import { PreviewPage } from '../PreviewPage';
import { Box } from '@mui/material';

export function App() {
  const { actions } = useAppSlice();
  const dispatch = useDispatch();
  const load = useSelector(selectAppLoad);
  const user = useSelector(selectUserData);

  const isLoggedIn = useSelector(selectIsLoggedIn);
  const isAnonymous =
    window.location.origin === process.env.REACT_APP_PREVIEW_PAGE_ANONYMOUS_URL;
  const defaultPage = isAnonymous ? '/404' : '/portal';

  useEffect(() => {
    if (!user || isAnonymous) return;
    if (!isAnonymous) dispatch(actions.loadRequest());
  }, [user]);

  if (load.loading && !load.data)
    return <LoadingIndicator minHeight={'100vh'} />;

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <EnvironmentIndicator />
        <Helmet titleTemplate="%s - Voice Repo" defaultTitle="Voice Repo">
          <meta name="description" content="Voice repository" />
        </Helmet>
        <Routes>
          {!isAnonymous && (
            <>
              <Route
                path="/forgot-password"
                element={
                  <ProtectedRoute
                    isAccessible={!user?.id}
                    redirectToPath="/portal"
                  >
                    <ForgotPassword />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/reset-password"
                element={
                  <ProtectedRoute
                    isAccessible={!isLoggedIn}
                    redirectToPath="/portal"
                  >
                    <ResetPassword />{' '}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/login/:key?"
                element={
                  <ProtectedRoute
                    isAccessible={!user?.id}
                    redirectToPath="/portal"
                  >
                    <Login />
                  </ProtectedRoute>
                }
              />
              <Route
                path={'/portal/*'}
                element={
                  <ProtectedRoute
                    isAccessible={!!user?.id}
                    redirectToPath="/login"
                    component={Portal}
                  >
                    <Portal />
                  </ProtectedRoute>
                }
              />
            </>
          )}
          <Route path={'/preview/:key'} element={<PreviewPage />} />
          <Route
            path={'/update-details/:key'}
            element={<UpdateDetailsPage />}
          />
          <Route path={'/404'} element={<Box>Page not found</Box>} />
          <Route path={'*'} element={<Navigate to={defaultPage} />} />
        </Routes>
      </DndProvider>
    </>
  );
}
