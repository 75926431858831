import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import {
  apiCallInitialState,
  ResultMetaData,
  uploadApiCallInitialState,
} from 'types/ApiCall';
import { ResponseErrorPayload } from 'utils/request';
import { legalDocumentsSliceSaga } from './saga';
import {
  LegalDocumentsSliceState,
  ILegalDocument,
  LegalDocumentsApiCall,
} from './types';

export const initialState: LegalDocumentsSliceState = {
  legalDocuments: {
    ...apiCallInitialState,
    data: {},
  },
  updateItem: uploadApiCallInitialState,
};

interface UpdateItemsRequest {
  remove: Array<number>;
  update: Array<{
    on_website: boolean;
    order: number;
    description: string;
    [key: string]: any;
  }>;
  create: Array<{
    contents: any;
    filename: string;
  }>;
}

const slice = createSlice({
  name: 'legalDocumentsSlice',
  initialState,
  reducers: {
    loadLegalDocumentsRequest(state, action: PayloadAction<void>) {
      state.legalDocuments.loading = true;
      state.legalDocuments.error = undefined;
    },
    loadLegalDocumentsSuccess(
      state,
      action: PayloadAction<LegalDocumentsApiCall>,
    ) {
      state.legalDocuments.loading = false;
      state.legalDocuments.data = action.payload.data;
    },
    loadLegalDocumentsFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.legalDocuments.loading = false;
      state.legalDocuments.error = action.payload;
    },
    updateItemRequest(state, action: PayloadAction<UpdateItemsRequest>) {
      state.updateItem.loading = true;
      state.updateItem.error = undefined;
    },
    updateItemSuccess(state, action: PayloadAction<{ file: File }>) {
      state.updateItem.loading = false;
      state.updateItem.data = action.payload;
      state.updateItem.error = undefined;
    },
    updateItemFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.updateItem.loading = false;
      state.updateItem.error = action.payload;
    },
    updateItemReset(state, action: PayloadAction<void>) {
      state.updateItem = uploadApiCallInitialState;
    },
  },
});

export const { actions: legalDocumentsSliceActions } = slice;

export const useLegalDocumentsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: legalDocumentsSliceSaga });
  return { actions: slice.actions };
};
