/**
 *
 * VoiceServiceForm
 *
 */

import React, { useEffect } from 'react';
import { Box, Container, Paper } from '@mui/material';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { useVoiceServicesSlice } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectUpdateItems, selectVoiceServices } from './slice/selectors';
import { useSnackbar } from 'notistack';
import { Items } from './Items';
import { useHasChanged } from '../../../utils/usePrevious';
import { appActions } from '../App/slice';

export function Edit() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { actions } = useVoiceServicesSlice();
  const loadVoiceServices = useSelector(selectVoiceServices);
  const updateItems = useSelector(selectUpdateItems);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const load = () => {
    dispatch(actions.loadVoiceServicesRequest());
  };

  useEffect(() => {
    load();
    return () => {
      // unmount if needed
    };
  }, []);

  const updateLoadingHasChanged = useHasChanged(updateItems.loading);

  React.useEffect(() => {
    if (updateLoadingHasChanged && !updateItems.loading) {
      load();
      dispatch(appActions.loadRequest());
    }
  });

  return (
    <>
      <Box>
        <Container maxWidth={'xl'}>
          <Paper sx={{ my: 4 }}>
            <Items
              loadVoiceServices={loadVoiceServices}
              updateItems={updateItems}
              onUpdate={data =>
                dispatch(
                  actions.updateItemsRequest({
                    ...data,
                  }),
                )
              }
            />
          </Paper>
        </Container>
      </Box>
    </>
  );
}
