/**
 *
 * PersonForm
 *
 */

import React from 'react';
import { Field, Form, Formik } from 'formik';
import { Box, Button, Divider, Grid, Paper, Skeleton } from '@mui/material';
import { Link } from 'react-router-dom';
import { CheckboxWithLabel, TextField } from 'formik-mui';
import clsx from 'clsx';
import Yup from 'utils/yup';
import { IPerson, PersonApiCall } from './slice/types';
import { FormBoxContainer } from 'styles/theme/themes';
import { TaxonomyField } from '../../components/TaxonomyField';
import { useSelector } from 'react-redux';
import { selectAppSettings } from '../App/slice/selectors';

const schema = Yup.object().shape({
  attributes: Yup.lazy(details => {
    return Yup.object(
      Object.fromEntries(
        Object.keys(details).map(key => {
          return [
            key,
            Yup.object({
              terms: Yup.array(
                Yup.object({
                  value: Yup.string().required(),
                }),
              ),
            }),
          ];
        }),
      ),
    );
  }),
});

interface PersonFormProps {
  loadPerson: PersonApiCall;
  savePerson: PersonApiCall;
  onSave: (data: IPerson) => void;
}

export function PersonAttributesForm(props: PersonFormProps) {
  const { loadPerson, savePerson, onSave } = props;
  const settings = useSelector(selectAppSettings);

  const data: Partial<IPerson> = {
    id: loadPerson.data ? loadPerson.data.id : null,
    attributes: loadPerson.data ? loadPerson.data.metadata.attributes : {},
  };

  if (loadPerson.loading && !loadPerson.data)
    return (
      <Box sx={{ my: 4, height: 400 }}>
        <Skeleton height={400} variant={'rectangular'} />
      </Box>
    );
  return (
    <FormBoxContainer>
      <Box sx={{ my: 2, p: 2 }}>
        <Formik
          initialValues={data}
          enableReinitialize
          validationSchema={schema}
          validateOnChange
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            onSave(values as IPerson);
          }}
        >
          {({ errors, values, submitForm, isSubmitting, setSubmitting }) => {
            if (isSubmitting && !(loadPerson.loading || savePerson.loading)) {
              setSubmitting(false);
            }
            if (!isSubmitting && (loadPerson.loading || savePerson.loading)) {
              setSubmitting(true);
            }

            return (
              <Form>
                <Box className={clsx('has-actions')}>
                  <Box className={'formContent'}>
                    <Grid container spacing={2}>
                      {/*
                      <Grid item xs={12} sm={6}>
                        <Field
                          label={'Gender'}
                          component={TaxonomyField}
                          name={'attributes'}
                          vocabularyId={2}
                          vocabularies={settings.vocabularies}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>*/}
                      {!!loadPerson.data?.is_child && (
                        <>
                          <Grid item xs={12} sm={6}>
                            <Field
                              label={'Superpower(s)'}
                              component={TaxonomyField}
                              name={'attributes'}
                              vocabularyId={42}
                              vocabularies={settings.vocabularies}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        </>
                      )}
                      <Grid item xs={12} sm={6}>
                        <Field
                          label={'Ethnicity'}
                          component={TaxonomyField}
                          name={'attributes'}
                          vocabularyId={40}
                          vocabularies={settings.vocabularies}
                        />
                      </Grid>
                      <Grid item xs={6} />
                      <Grid item xs={12} sm={6}>
                        <Field
                          label={'Voice Type'}
                          component={TaxonomyField}
                          name={'attributes'}
                          vocabularyId={39}
                          vocabularies={settings.vocabularies}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          label={'Voice Range'}
                          component={TaxonomyField}
                          name={'attributes'}
                          vocabularyId={29}
                          vocabularies={settings.vocabularies}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          label={'Native Language'}
                          component={TaxonomyField}
                          name={'attributes'}
                          vocabularyId={5}
                          vocabularies={settings.vocabularies}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          label={'Other Languages'}
                          component={TaxonomyField}
                          name={'attributes'}
                          vocabularyId={32}
                          vocabularies={settings.vocabularies}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          label={'Native Accent'}
                          component={TaxonomyField}
                          name={'attributes'}
                          vocabularyId={11}
                          vocabularies={settings.vocabularies}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} />
                      <Grid item xs={12} sm={6}>
                        <Field
                          label={'Other Accents'}
                          component={TaxonomyField}
                          name={'attributes'}
                          vocabularyId={41}
                          vocabularies={settings.vocabularies}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          label={'Studio'}
                          component={TaxonomyField}
                          name={'attributes'}
                          vocabularyId={6}
                          vocabularies={settings.vocabularies}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          label={'Artist Type'}
                          component={TaxonomyField}
                          name={'attributes'}
                          vocabularyId={27}
                          vocabularies={settings.vocabularies}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className={'formActions'} sx={{ mt: 4 }}>
                    <Button
                      variant="text"
                      size="small"
                      component={Link}
                      to="/portal/people"
                    >
                      Cancel
                    </Button>
                    <Button
                      className="align-right"
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      Save {values.id ? 'Changes' : 'New Person'}
                    </Button>
                  </Box>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </FormBoxContainer>
  );
}
