import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import {
  apiCallInitialState,
  ResultMetaData,
  uploadApiCallInitialState,
} from 'types/ApiCall';
import { ResponseErrorPayload } from 'utils/request';
import { voiceServicesSliceSaga } from './saga';
import { VoiceServicesSliceState, IVoiceService } from './types';

export const initialState: VoiceServicesSliceState = {
  voiceServices: {
    loading: false,
    data: [],
  },
  updateItems: apiCallInitialState,
};

interface UpdateItemsRequest {
  remove: Array<number>;
  update: Array<{
    on_website: boolean;
    order: number;
    description: string;
    [key: string]: any;
  }>;
  create: Array<{
    contents: any;
    filename: string;
  }>;
}

const slice = createSlice({
  name: 'voiceServicesSlice',
  initialState,
  reducers: {
    loadVoiceServicesRequest(state, action: PayloadAction<void>) {
      state.voiceServices.loading = true;
      state.voiceServices.error = undefined;
    },
    loadVoiceServicesSuccess(
      state,
      action: PayloadAction<Array<IVoiceService>>,
    ) {
      state.voiceServices.loading = false;
      state.voiceServices.data = action.payload;
    },
    loadVoiceServicesFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.voiceServices.loading = false;
      state.voiceServices.error = action.payload;
    },
    updateItemsRequest(state, action: PayloadAction<UpdateItemsRequest>) {
      state.updateItems.loading = true;
      state.updateItems.error = undefined;
    },
    updateItemsSuccess(state, action: PayloadAction<{ file: File }>) {
      state.updateItems.loading = false;
      state.updateItems.data = action.payload;
      state.updateItems.error = undefined;
    },
    updateItemsFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.updateItems.loading = false;
      state.updateItems.error = action.payload;
    },
    updateItemsReset(state, action: PayloadAction<void>) {
      state.updateItems = uploadApiCallInitialState;
    },
  },
});

export const { actions: voiceServicesSliceActions } = slice;

export const useVoiceServicesSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: voiceServicesSliceSaga });
  return { actions: slice.actions };
};
