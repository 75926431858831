import {
  createTheme,
  makeStyles,
  responsiveFontSizes,
} from '@mui/material/styles';
import { red, yellow, grey } from '@mui/material/colors';
import { styled } from '@mui/system';
import { Box } from '@mui/material';

let muiTheme = createTheme({
  palette: {
    primary: {
      main: '#ab0076',
    },
    secondary: {
      main: '#697f9a',
    },
    error: {
      main: red['A700'],
    },
    success: {
      main: '#35662a',
    },
    warning: {
      main: '#df6345',
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: 'inherit',
    htmlFontSize: 16,

    h1: {
      fontSize: 32,
      fontWeight: 600,
      fontFamily: 'Montserrat, sans-serif',
    },
    h2: {
      fontSize: 32,
      fontWeight: 600,
      fontFamily: 'Montserrat, sans-serif',
    },
    h3: {
      fontSize: 28,
      fontWeight: 600,
      fontFamily: 'Montserrat, sans-serif',
    },
    h4: {
      fontSize: 24,
      fontWeight: 600,
      fontFamily: 'Montserrat, sans-serif',
    },
    h5: {
      fontSize: 16,
      fontWeight: 600,
      fontFamily: 'Montserrat, sans-serif',
    },
    h6: {
      fontSize: 14,
      fontWeight: 600,
      fontFamily: 'Montserrat, sans-serif',
    },

    button: {
      fontFamily: 'Montserrat, sans-serif',
    },
  },
});
muiTheme = responsiveFontSizes(muiTheme, {
  breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'],
  factor: 8,
  //  disableAlign: true,
});

export { muiTheme };

export const FormBoxContainer = styled(Box)(({ theme }) => {
  return {
    '& .field': {
      marginBottom: theme.spacing(2),
      width: '100%',
    },

    '& .formContainer': {
      '&.has-actions': {
        paddingBottom: theme.spacing(10),
      },
      position: 'relative',
      zIndex: 1,
    },

    '& .formActions': {
      width: '100%',
      zIndex: 2,
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#FFFFFF',
      '& .align-right': {
        marginLeft: 'auto',
      },
    },
  };
});

const lightTheme = {
  primary: muiTheme.palette.primary.main,
  text: 'rgba(58,52,51,1)',
  textSecondary: 'rgba(58,52,51,0.7)',
  background: 'rgba(255,255,255,1)',
  backgroundVariant: 'rgba(251,249,249,1)',
  border: 'rgba(58,52,51,0.12)',
  borderLight: 'rgba(58,52,51,0.05)',
};

const darkTheme: Theme = {
  primary: 'rgba(220,120,95,1)',
  text: 'rgba(241,233,231,1)',
  textSecondary: 'rgba(241,233,231,0.6)',
  background: 'rgba(0,0,0,1)',
  backgroundVariant: 'rgba(28,26,26,1)',
  border: 'rgba(241,233,231,0.15)',
  borderLight: 'rgba(241,233,231,0.05)',
};

export type Theme = typeof lightTheme;

export const themes = {
  light: lightTheme,
  dark: darkTheme,
};
