/**
 *
 * Taxonomies
 *
 */
import * as React from 'react';
import { useParams } from 'react-router';
import { Route, Routes } from 'react-router-dom';
import { List } from './List';
import { Edit } from './Edit';
import { Container } from '@mui/material';

interface Props {}

export function Taxonomies(props: Props) {
  const match = useParams();
  return (
    <Routes>
      <Route path={``} element={<List />} />
      <Route path={`:id/:subsection?`} element={<Edit />} />
    </Routes>
  );
}
