import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.peopleSlice || initialState;

export const selectPeopleSlice = createSelector([selectSlice], state => state);
export const selectPeople = createSelector(
  [selectSlice],
  state => state.people,
);

export const selectPerson = createSelector(
  [selectSlice],
  state => state.person,
);

export const selectSavePerson = createSelector(
  [selectSlice],
  state => state.save,
);

export const selectSendEditInvitation = createSelector(
  [selectSlice],
  state => state.sendEditInvitation,
);

export const selectCloseEditInvitation = createSelector(
  [selectSlice],
  state => state.closeEditInvitation,
);

export const selectUpdateSoundFiles = createSelector(
  [selectSlice],
  state => state.updateSoundFiles,
);

export const selectDeletePeople = createSelector(
  [selectSlice],
  state => state.delete,
);

export const selectChangeField = createSelector(
  [selectSlice],
  state => state.changeField,
);
