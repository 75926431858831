import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { accountSettingsSaga } from './saga';
import { AccountSettingsState } from './types';
import { apiCallInitialState } from '../../../../types/ApiCall';
import { ResponseErrorPayload } from '../../../../utils/request';

export const initialState: AccountSettingsState = {
  changePassword: apiCallInitialState,
  reset2fa: apiCallInitialState,
};

const slice = createSlice({
  name: 'accountSettings',
  initialState,
  reducers: {
    changePasswordMount(state, action: PayloadAction<void>) {
      state.changePassword.loading = false;
      state.changePassword.error = undefined;
      state.changePassword.data = undefined;
    },
    changePasswordRequest(state, action: PayloadAction<any>) {
      state.changePassword.loading = true;
      state.changePassword.error = undefined;
      state.changePassword.data = undefined;
    },
    changePasswordSuccess(state, action: PayloadAction<any>) {
      state.changePassword.loading = false;
      state.changePassword.data = action.payload;
      state.changePassword.error = undefined;
    },
    changePasswordFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.changePassword.error = action.payload;
      state.changePassword.loading = false;
      state.changePassword.data = undefined;
    },
    reset2faRequest(state, action: PayloadAction<void>) {
      state.reset2fa.loading = true;
      state.reset2fa.error = undefined;
      state.reset2fa.data = undefined;
    },
    reset2faSuccess(state, action: PayloadAction<any>) {
      state.reset2fa.loading = false;
      state.reset2fa.data = action.payload;
      state.reset2fa.error = undefined;
    },
    reset2faFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.reset2fa.error = action.payload;
      state.reset2fa.loading = false;
      state.reset2fa.data = undefined;
    },
  },
});

export const { actions: accountSettingsActions } = slice;

export const useAccountSettingsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: accountSettingsSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useAccountSettingsSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
