import axios, { AxiosResponse } from 'axios';
import { get } from 'lodash';
import { saveAs } from 'file-saver';
import storage from 'local-storage-fallback';

export function getApiUrl(path: string): string {
  return `${process.env.REACT_APP_API_URL}/api/${path}`;
}

export function getBroadcastingUrl(path: string): string {
  return `${process.env.REACT_APP_API_URL}/api/broadcasting/${path}`;
}

const instance = axios.create({});
instance.defaults.timeout = 1000 * 60 * 15;

export { instance };

export const getToken = () => storage.getItem('token');
export const setToken = (token: string | undefined) =>
  token ? storage.setItem('token', token) : storage.removeItem('token');

export class ResponseError extends Error {
  public response: AxiosResponse;
  public payload: ResponseErrorPayload;

  constructor(response: AxiosResponse) {
    super(response.data.message);
    this.response = response;
    this.payload = {
      code: response.status,
      message: response.data.message,
      errors: response.data.errors,
    };
  }
}

export interface ResponseErrorPayload {
  message: string;
  code?: number;
  errors?: object;
}

export const apiRequest = async ({
  url,
  method = 'get',
  formData,
  options,
  data,
  ...payload
}: any) => {
  const token = await storage.getItem('token');
  if (token) {
    instance.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete instance.defaults.headers.common.Authorization;
  }

  const additionalData: { [key: string]: any } = {};
  if (method === 'get') {
    additionalData.params = data;
  } else {
    additionalData.data = data;
  }

  try {
    const response = await instance({
      url: getApiUrl(url),
      method,
      formData,
      ...additionalData,
      ...options,
      ...payload,
    });
    return response.data;
  } catch (e) {
    throw new ResponseError(e.response);
  }
};

export function serveFile(filepath: string) {
  return instance({
    url: getApiUrl(filepath),
    method: 'GET',
    responseType: 'blob',
  }).then((response, ...rest) => {
    console.log(response.headers);
    const filename = get(response.headers, 'x-attachment-name', 'ca-file');
    saveAs(new Blob([response.data]), `${filename}`);
    return filename;
  });
}
