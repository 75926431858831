/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { SnackbarProvider } from 'notistack';
import * as React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { throttle } from 'lodash';
import { Provider } from 'react-redux';
import FontFaceObserver from 'fontfaceobserver';
import * as serviceWorker from 'serviceWorker';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { GlobalStyles } from './styles/theme/GlobalStyles';
import 'sanitize.css/sanitize.css';

// Initialize languages
import './locales/i18n';
import { App } from 'app/containers/App';
import { HelmetProvider } from 'react-helmet-async';
import { configureAppStore } from 'store/configureStore';
import { AuthProvider } from './app/containers/AuthProvider';
import { muiTheme } from './styles/theme/themes';
import { ConfirmProvider } from './app/components/ConfirmDialog';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';

// Observe loading of Inter (to remove 'Inter', remove the <link> tag in
// the index.html file and this observer)
const openSansObserver = new FontFaceObserver('Fira Sans', {});
const headerObserver = new FontFaceObserver('Montserrat', {});

// When Inter is loaded, add a font-family using Inter to the body
Promise.all([openSansObserver.load(), headerObserver.load()]).then(() => {
  document.body.classList.add('fontLoaded');
});

const store = configureAppStore();

// todo - might need to use this https://edvins.io/how-to-use-redux-persist-with-redux-toolkit
// instead of the loadState idea in the CustomerSelector slice that has been used
store.subscribe(
  throttle(() => {
    //  const state = store.getState();
    //  saveState({});
  }, 1500),
);

const MOUNT_NODE = document.getElementById('root') as HTMLElement;

const router = createBrowserRouter(
  createRoutesFromElements(<Route path={'*'} element={<App />} />),
);

ReactDOMClient.createRoot(MOUNT_NODE!).render(
  <Provider store={store}>
    <MuiThemeProvider theme={muiTheme}>
      {/* @ts-ignore */}
      <ConfirmProvider>
        <GlobalStyles />
        <AuthProvider>
          <SnackbarProvider>
            <HelmetProvider>
              <React.StrictMode>
                <RouterProvider router={router} />
              </React.StrictMode>
            </HelmetProvider>
          </SnackbarProvider>
        </AuthProvider>
      </ConfirmProvider>
    </MuiThemeProvider>
  </Provider>,
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
