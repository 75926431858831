/**
 *
 * Portal
 *
 */

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import PortalFooter from 'app/components/PortalFooter';
import PortalAppBar from 'app/components/PortalAppBar';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { selectUserData } from 'app/containers/AuthProvider/slice/selectors';
import { TabbedContent } from '../../components/TabbedContent';
import { Dashboard } from '../Dashboard';
import { Users } from '../Users';
import { AccountSettings } from '../AccountSettings';
import { ProtectedRoute } from '../../components/ProtectedRoute';
import { UserPermission } from '../AuthProvider/slice/types';
import { hasPermission } from '../../../utils/user';
import { People } from '../People';
import { useTheme } from '@mui/material/styles';
import { PreviewPages } from '../PreviewPages';
import { AdminSettings } from '../AdminSettings';

interface Props {}

export function Portal(props: Props) {
  const theme = useTheme();
  const match = useParams();
  const userData = useSelector(selectUserData);
  return (
    <>
      <Helmet>
        <title>Portal</title>
        <meta name="description" content="Description of Portal" />
      </Helmet>
      <PortalAppBar userData={userData!} />
      <div>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            minHeight: 'calc(100vh - 64px)',
            backgroundColor: theme.palette.grey[300],
          }}
        >
          <Box
            sx={{
              position: 'relative',
            }}
          >
            <Box width={'100%'}>
              <Routes>
                <Route path={``} element={<Dashboard />} />

                <Route
                  path={`people/*`}
                  element={
                    <ProtectedRoute
                      isAccessible={hasPermission(
                        userData,
                        UserPermission.ADMINISTER_SYSTEM,
                      )}
                    >
                      <People />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path={`preview-pages/*`}
                  element={
                    <ProtectedRoute
                      isAccessible={hasPermission(
                        userData,
                        UserPermission.ADMINISTER_SYSTEM,
                      )}
                    >
                      <PreviewPages />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path={`accounts/:section?`}
                  element={<AccountSettings />}
                />

                <Route path={`admin/:section?/*`} element={<AdminSettings />} />

                <Route element={<Navigate to={''} />} />
              </Routes>
            </Box>
          </Box>
          <Box
            sx={{
              maxHeight: 64,
              [theme.breakpoints.down('md')]: {
                display: 'none',
              },
            }}
          >
            <PortalFooter userData={userData!} />
          </Box>
        </Box>
      </div>
    </>
  );
}
