/**
 *
 * ActionsMenu
 *
 */
import * as React from 'react';
import { Box, BoxProps, Button, Menu, MenuItem } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import { HTMLAttributes, ReactNode, useEffect, useMemo, useRef } from 'react';
import { ButtonProps } from '@mui/material/Button';
import key from 'weak-key';

interface Props extends BoxProps {
  label: ReactNode;
  doAction: (action: string) => void;
  disabled: boolean;
  actions: { type: string; label: ReactNode; disabled?: boolean }[];
  buttonProps?: ButtonProps;
}

export function ActionsMenu(props: Props) {
  const {
    doAction,
    actions,
    disabled,
    label,
    buttonProps = {},
    ...rProps
  } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = action => {
    setAnchorEl(null);
    doAction(action);
  };

  const ref = useRef<HTMLSpanElement>(null);

  const width = useMemo(() => {
    if (!ref.current) return 0;

    return ref.current.offsetWidth;
  }, [ref.current]);

  return (
    <Box
      sx={{
        // py: disabled ? 0 : 1,
        textAlign: 'right',
        transition: ['all', '0.3s'],
        //  maxHeight: disabled ? 0 : 60,
        // overflowY: disabled ? 'hidden' : undefined,
      }}
      {...rProps}
    >
      <span ref={ref}>
        <Button
          size={'small'}
          disabled={disabled}
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          variant={'contained'}
          endIcon={<ArrowDropDown />}
          {...buttonProps}
        >
          {label}
        </Button>
      </span>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open && !disabled}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {actions.map(action => (
          <MenuItem
            disabled={action.disabled}
            key={key(action)}
            onClick={() => handleClose(action.type)}
            sx={{ minWidth: width }}
          >
            {action.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
