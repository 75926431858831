import { useEffect } from 'react';
import { useHasChanged } from './usePrevious';
import { isEqual } from 'lodash';

export function useEffectOnChange<T = any>(
  callback: (prop: T) => void,
  prop: T,
  toValue?: any,
) {
  const propHasChanged = useHasChanged(prop);

  useEffect(() => {
    if (propHasChanged) {
      if (toValue !== undefined && !isEqual(prop, toValue)) return;
      callback(prop);
    }
  });
}
