/**
 *
 * TaxonomyField
 *
 */
import * as React from 'react';
import {
  ITaxonomyTerm,
  ITaxonomyVocabulary,
} from '../../containers/App/slice/types';
import { FieldProps, FormikProps } from 'formik';
import { findIndex, get } from 'lodash';
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Autocomplete } from '@mui/lab';
import key from 'weak-key';
import { HTMLAttributes, ReactNode } from 'react';

interface Props
  extends Pick<HTMLAttributes<HTMLDivElement>, 'className'>,
    FieldProps {
  label?: ReactNode;
  form: FormikProps<any>;
  vocabularies: ITaxonomyVocabulary[];
  vocabularyId: number;
  itemsPerRow?: 1 | 2 | 3 | 4 | 6;
}

export function TaxonomyField(props: Props) {
  const {
    label,
    vocabularyId,
    itemsPerRow = 6,
    vocabularies,
    field,
    form,
    className,
    ...rProps
  } = props;

  const vocabKey = `vocabulary-${vocabularyId}`;
  const vocabulary = vocabularies.find(v => v.id === vocabularyId);

  const onChange = React.useCallback(
    (term: ITaxonomyTerm, value) => {
      let newValue = { ...(field.value || {}) };

      // set the key in the returned value if not set already
      if (!newValue[vocabKey]) {
        newValue[vocabKey] = {
          ...vocabulary,
          terms: [],
        } as ITaxonomyVocabulary;
      }

      const existingTermIndex = findIndex(newValue[vocabKey].terms, {
        id: term.id,
      });

      if (!!value || term.is_other) {
        const termEntry = { id: term.id, name: term.name, value: value || '' };
        // find existing item and ana amend or add new one
        if (existingTermIndex > -1) {
          newValue = {
            ...newValue,
            [vocabKey]: {
              ...newValue[vocabKey],
              terms: [
                ...newValue[vocabKey].terms.filter(t => t.id !== termEntry.id),
                termEntry,
              ],
            },
          };
        } else {
          newValue = {
            ...newValue,
            [vocabKey]: {
              ...newValue[vocabKey],
              terms: [...newValue[vocabKey].terms, termEntry],
            },
          };
        }
      } else {
        if (!term.is_other) {
          // remove existing item if present
          newValue = {
            ...newValue,
            [vocabKey]: {
              ...newValue[vocabKey],
              terms: newValue[vocabKey].terms.filter(o => o.id !== term.id),
            },
          };
        }
      }

      form.setFieldTouched(field.name);

      form.setFieldValue(field.name, newValue);
    },
    [form.setFieldValue, field.name, field.value],
  );

  const renderOtherFields = () => {
    if (!vocabulary) return null;
    const fields = vocabulary!.terms
      .map(term => {
        if (term.is_other) {
          const existingTermIndex = findIndex(field.value[vocabKey].terms, {
            id: term.id,
          });

          if (existingTermIndex !== -1) {
            const formNameKey = `${field.name}.${vocabKey}.terms[${existingTermIndex}]`;
            const hasError = !!get(form.errors, formNameKey, false);

            return (
              <Box sx={{ my: 2 }}>
                <TextField
                  key={`other-${formNameKey}`}
                  fullWidth
                  error={hasError}
                  label={`More information - ${term.name}`}
                  value={field.value[vocabKey].terms[existingTermIndex].value}
                  onChange={ev => {
                    onChange(term, ev.target.value);
                  }}
                  helperText={hasError ? 'This field is required.' : ''}
                />
              </Box>
            );
          }
        }
      })
      .filter(res => res);
    return fields;
  };

  const termsError = get(form.errors[field.name], `[${vocabKey}].terms`, null);

  if (!vocabulary) return null;

  if (vocabulary.has_values)
    return (
      <>
        <Box className={className}>
          <Typography sx={{ mb: 2 }} variant={'h6'}>
            {label || vocabulary.name}
          </Typography>
          <Grid container spacing={2}>
            {vocabulary.terms.map(term => (
              <Grid xs={12} md={12 / itemsPerRow} item key={key(term)}>
                <Box sx={{ mb: 2 }}>
                  {vocabulary.allowed_values ? (
                    <FormControl fullWidth>
                      <InputLabel id={`id-${term.slug}`}>
                        {term.name}
                      </InputLabel>
                      <Select
                        labelId={`id-${term.slug}`}
                        fullWidth
                        label={term.name}
                        value={
                          (
                            get(field.value, `[${vocabKey}].terms`, []).find(
                              t => t.id === term.id,
                            ) || {}
                          ).value || ''
                        }
                        onChange={ev => {
                          onChange(term, ev.target.value);
                        }}
                      >
                        <MenuItem value={''}>-- Not set --</MenuItem>
                        {vocabulary.allowed_values.map(allowed_value => (
                          <MenuItem
                            value={allowed_value}
                            key={key({ ...term, value: allowed_value })}
                          >
                            {allowed_value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <TextField
                      label={term.name}
                      fullWidth
                      value={
                        (
                          get(field.value, `[${vocabKey}].terms`, []).find(
                            t => t.id === term.id,
                          ) || {}
                        ).value || ''
                      }
                      onChange={ev => {
                        onChange(term, ev.target.value);
                      }}
                    />
                  )}
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </>
    );

  // Normal term without preset values
  return (
    <>
      <Box className={className}>
        {vocabulary.allow_multiple ? (
          <>
            <Autocomplete
              filterSelectedOptions
              isOptionEqualToValue={(option, value) => option.id === value.id}
              multiple
              value={get(field.value, `[${vocabKey}].terms`, []).map(term => ({
                ...term,
              }))}
              renderInput={params => (
                <TextField
                  {...params}
                  error={!!termsError}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                  label={label || vocabulary.name}
                  helperText={
                    typeof termsError === 'string'
                      ? 'This field is required.'
                      : ''
                  }
                />
              )}
              getOptionLabel={option => option.name}
              options={vocabulary.terms.map(term => ({
                ...term,
              }))}
              onChange={(ev, val) => {
                const newTerms = val
                  .map(term => {
                    const fullTerm = vocabulary.terms.find(
                      t => t.id === term.id,
                    );
                    if (!fullTerm) return;
                    if (!fullTerm.is_other) {
                      term.value = true;
                    } else {
                      if (!term.value) {
                        term.value = '';
                      }
                    }
                    return term;
                  })
                  .filter(t => t);

                form.setFieldValue(field.name, {
                  ...field.value,
                  [vocabKey]: {
                    ...field.value[vocabKey],
                    terms: newTerms,
                  },
                });
              }}
            />
          </>
        ) : (
          <>
            <Autocomplete
              filterSelectedOptions
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={get(field.value, `[${vocabKey}].terms[0]`, '')}
              renderInput={params => (
                <TextField
                  {...params}
                  error={!!termsError}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                  label={label || vocabulary.name}
                  helperText={
                    typeof termsError === 'string'
                      ? 'This field is required.'
                      : ''
                  }
                />
              )}
              getOptionLabel={option => option.name || ''}
              options={vocabulary.terms.map(term => ({
                ...term,
              }))}
              onChange={(ev, value) => {
                console.log(value);
                form.setFieldValue(field.name, {
                  ...field.value,
                  [vocabKey]: {
                    ...field.value[vocabKey],
                    terms: value ? [{ ...value, value: true }] : undefined,
                  },
                });
                return;
              }}
            />
          </>
        )}

        {renderOtherFields()}
      </Box>
    </>
  );
}
