import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) =>
  state.addToPreviewPage || initialState;

export const selectAddToPreviewPage = createSelector(
  [selectSlice],
  state => state,
);

export const selectAddToPreviewPageLoadData = createSelector(
  [selectSlice],
  state => state.load,
);

export const selectAddToPreviewPageAddPeople = createSelector(
  [selectSlice],
  state => state.add,
);
