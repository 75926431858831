import {
  take,
  call,
  put,
  select,
  takeLatest,
  cancelled,
} from 'redux-saga/effects';
import { dashboardActions as actions } from '.';
import { apiRequest } from '../../../../utils/request';

function* load(action) {
  try {
    const payload = yield call(apiRequest, {
      url: `people`,
      method: 'get',
      data: {
        ...action.payload,
        active_status: 'show_all',
        has_data_update: true,
      },
    });

    yield put(actions.loadSuccess(payload.data));
  } catch (error) {
    yield put(actions.loadFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

export function* dashboardSaga() {
  yield takeLatest(actions.loadRequest.type, load);
}
