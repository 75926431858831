/**
 *
 * MaterialUiFormikSelect
 *
 */
import * as React from 'react';
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
} from '@mui/material';
import { Field, getIn } from 'formik';
import { Select } from 'formik-mui';

import { ReactNode } from 'react';

interface Props {
  name: string;
  label: string;
  options: Array<{ value: any; label: string | ReactNode }>;
  showEmpty?: boolean;
  emptyMessage?: string | ReactNode;
  emptyValue?: '' | [];
}

export function MaterialUiFormikSelect(props: Props) {
  const labelRef = React.useRef();

  const [labelWidth, setLabelWidth] = React.useState(40);

  const formikRef = node => {
    if (node !== null) {
      setLabelWidth(node.getBoundingClientRect().width + 6);
    }
  };

  React.useEffect(() => {
    console.log(labelRef.current);
    setLabelWidth(getIn(labelRef, 'current.clientWidth', labelWidth));
  }, [props.label]);

  return (
    <FormControl variant="outlined" fullWidth>
      <Field name={props.name}>
        {sProps => (
          <InputLabel
            ref={formikRef}
            error={!!sProps.form.errors[props.name]}
            htmlFor={props.name}
          >
            {props.label}
          </InputLabel>
        )}
      </Field>

      <Field
        component={sProps => (
          <Select
            error={!!sProps.form.errors[props.name]}
            inputLabel={{ id: props.name }}
            {...sProps}
          />
        )}
        name={props.name}
      >
        {!!props.showEmpty && (
          <MenuItem
            value={props.emptyValue !== undefined ? props.emptyValue : ''}
          >
            {props.emptyMessage || '--- Select ---'}
          </MenuItem>
        )}

        {props.options.map(option => (
          <MenuItem value={option.value}>{option.label}</MenuItem>
        ))}
      </Field>
      <Field name={props.name}>
        {({ field, form }) => {
          const error = getIn(form.errors, field.name);
          const showError = !!error && form.touched[field.name];
          return showError
            ? !!error && <FormHelperText error>{error}</FormHelperText>
            : null;
        }}
      </Field>
    </FormControl>
  );
}
