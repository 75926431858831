/**
 *
 * TaxonomyForm
 *
 */

import React from 'react';
import { Box, Button, Container, Paper, Tab, Tabs } from '@mui/material';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { useTaxonomiesSlice } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectSaveTaxonomy,
  selectTaxonomy,
  selectUpdateTerms,
} from './slice/selectors';
import { useSnackbar } from 'notistack';
import { TaxonomyForm } from './Form';
import { Terms } from './Terms';
import { ChevronLeft } from '@mui/icons-material';
import { appActions } from '../App/slice';
import { useHasChanged } from '../../../utils/usePrevious';
import { grey } from '@mui/material/colors';

export function Edit() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { actions } = useTaxonomiesSlice();
  const loadTaxonomy = useSelector(selectTaxonomy);
  const saveTaxonomy = useSelector(selectSaveTaxonomy);
  const updateTerms = useSelector(selectUpdateTerms);
  const [value, setValue] = React.useState(0);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const params = useParams<{
    id: string;
    subsection?: string;
  }>();

  React.useEffect(() => {
    load();
    return () => {
      dispatch(actions.loadTaxonomyUnmount());
    };
  }, []);

  React.useEffect(() => {
    if (loadTaxonomy.error) {
      navigate('/portal/admin/taxonomies');
    }
  }, [loadTaxonomy]);

  React.useEffect(() => {
    console.log(saveTaxonomy);
    if (saveTaxonomy.loading) return;
    if (saveTaxonomy.error) {
      enqueueSnackbar(saveTaxonomy.error.message, {
        variant: 'error',
      });
    }
    if (saveTaxonomy.data) {
      enqueueSnackbar('Taxonomy saved successfully', {
        variant: 'success',
      });
      if (params.id === 'new') {
        navigate(`/portal/admin/taxonomies/${saveTaxonomy.data.id}`, {
          replace: true,
        });
      } else {
        load();
      }
    }
  }, [saveTaxonomy.loading]);

  React.useEffect(() => {
    if (params.id !== 'new') load();
  }, [params.id]);

  const updateLoadingHasChanged = useHasChanged(updateTerms.loading);

  React.useEffect(() => {
    if (updateLoadingHasChanged && !updateTerms.loading) {
      load();
      dispatch(appActions.loadRequest());
    }
  });

  const load = () => {
    const id = params.id === 'new' ? 'new' : parseInt(params.id || '');
    dispatch(actions.loadTaxonomyRequest({ id }));
  };

  return (
    <>
      <Box>
        <Container maxWidth={'xl'}>
          <Paper sx={{ my: 4 }}>
            <Box
              sx={{
                px: 1,
                backgroundColor: grey['200'],
              }}
            >
              <Tabs value={params.subsection || ''}>
                <Tab
                  value={''}
                  component={Link}
                  to={`/portal/admin/taxonomies/${params.id}`}
                  label={'General'}
                />
                <Tab
                  value={'terms'}
                  disabled={params.id === 'new'}
                  component={Link}
                  to={`/portal/admin/taxonomies/${params.id}/terms`}
                  label={'Terms'}
                />
                <Tab
                  icon={<ChevronLeft />}
                  iconPosition={'start'}
                  sx={{ marginLeft: 'auto' }}
                  component={Link}
                  to="/portal/admin/taxonomies"
                  label={'Back to taxonomies'}
                />
              </Tabs>
            </Box>

            {!params.subsection && (
              <TaxonomyForm
                loadTaxonomy={loadTaxonomy}
                saveTaxonomy={saveTaxonomy}
                onSave={data => dispatch(actions.saveTaxonomyRequest(data))}
              />
            )}
            {params.subsection === 'terms' && (
              <Terms
                updateTerms={updateTerms}
                loadTaxonomy={loadTaxonomy}
                onUpdate={data =>
                  dispatch(
                    actions.updateTermsRequest({
                      ...data,
                      id: loadTaxonomy.data?.id,
                    }),
                  )
                }
              />
            )}
          </Paper>
        </Container>
      </Box>
    </>
  );
}
